export const paymentLinkEnglish = {
  ORDERS_PAYMENT_LINK: "Payment Link",
  ORDERS_PAYMENT_LINK_ALL: "All",
  ORDERS_PAYMENT_LINK_ORDER_NO: "Order No.",
  ORDERS_PAYMENT_LINK_ORDER_LINK: "Order Link",
  ORDERS_PAYMENT_LINK_PAYMENT_STATUS: "Payment Status",
  ORDERS_PAYMENT_LINK_CREATED_ON: "Created On",
  ORDERS_PAYMENT_LINK_PAYMENT_RELEASE_DATE: "Payment Release Date",
  ORDERS_PAYMENT_LINK_PAID_ON: "Paid On",
  ORDERS_PAYMENT_LINK_ACTION: "Action",
  ORDERS_PAYMENT_LINK_GENERATE_PAYMENT_LINK: "Generate Payment Link",
  ORDERS_PAYMENT_LINK_CREATE_PAYOUT_REQUEST: "Create Payout Request",
  ORDERS_PAYMENT_LINK_PAYMNT_SEHEDULE_DATE: "Payment Schedule Date",
  ORDERS_PAYMENT_LINK_PAYMNT_Amount: "Amount",
};
export const paymentLinkArabic = {
  ORDERS_PAYMENT_LINK: "رابط الدفع",
  ORDERS_PAYMENT_LINK_ALL: "الكل",
  ORDERS_PAYMENT_LINK_ORDER_NO: "رقم الطلب",
  ORDERS_PAYMENT_LINK_ORDER_LINK: "رابط الطلب",
  ORDERS_PAYMENT_LINK_PAYMENT_STATUS: "حالة الدفع",
  ORDERS_PAYMENT_LINK_CREATED_ON: "تم الإنشاء في",
  ORDERS_PAYMENT_LINK_PAYMENT_RELEASE_DATE: "تاريخ إصدار الدفع",
  ORDERS_PAYMENT_LINK_PAID_ON: "تم الدفع في",
  ORDERS_PAYMENT_LINK_ACTION: "الإجراء",
  ORDERS_PAYMENT_LINK_GENERATE_PAYMENT_LINK: "إنشاء رابط الدفع",
  ORDERS_PAYMENT_LINK_CREATE_PAYOUT_REQUEST: "إنشاء طلب دفع",
  ORDERS_PAYMENT_LINK_PAYMNT_SEHEDULE_DATE: "تاريخ جدول الدفع",
  ORDERS_PAYMENT_LINK_PAYMNT_Amount: "المبلغ",
};
export const paymentLinkChinese = {
  ORDERS_PAYMENT_LINK: "支付链接",
  ORDERS_PAYMENT_LINK_ALL: "所有",
  ORDERS_PAYMENT_LINK_ORDER_NO: "订单号",
  ORDERS_PAYMENT_LINK_ORDER_LINK: "订单链接",
  ORDERS_PAYMENT_LINK_PAYMENT_STATUS: "支付状态",
  ORDERS_PAYMENT_LINK_CREATED_ON: "创建于",
  ORDERS_PAYMENT_LINK_PAYMENT_RELEASE_DATE: "支付释放日期",
  ORDERS_PAYMENT_LINK_PAID_ON: "付款日期",
  ORDERS_PAYMENT_LINK_ACTION: "操作",
  ORDERS_PAYMENT_LINK_GENERATE_PAYMENT_LINK: "生成支付链接",
  ORDERS_PAYMENT_LINK_CREATE_PAYOUT_REQUEST: "创建支付请求",
  ORDERS_PAYMENT_LINK_PAYMNT_SEHEDULE_DATE: "付款计划日期",
  ORDERS_PAYMENT_LINK_PAYMNT_Amount: "金额",
};
export const paymentLinkUrdu = {
  ORDERS_PAYMENT_LINK: "ادائیگی کا لنک",
  ORDERS_PAYMENT_LINK_ALL: "تمام",
  ORDERS_PAYMENT_LINK_ORDER_NO: "آرڈر نمبر",
  ORDERS_PAYMENT_LINK_ORDER_LINK: "آرڈر لنک",
  ORDERS_PAYMENT_LINK_PAYMENT_STATUS: "ادائیگی کی حیثیت",
  ORDERS_PAYMENT_LINK_CREATED_ON: "تاریخ تخلیق",
  ORDERS_PAYMENT_LINK_PAYMENT_RELEASE_DATE: "ادائیگی جاری کرنے کی تاریخ",
  ORDERS_PAYMENT_LINK_PAID_ON: "ادائیگی کی تاریخ",
  ORDERS_PAYMENT_LINK_ACTION: "عمل",
  ORDERS_PAYMENT_LINK_GENERATE_PAYMENT_LINK: "ادائیگی کا لنک تخلیق کریں",
  ORDERS_PAYMENT_LINK_CREATE_PAYOUT_REQUEST: "پے آؤٹ کی درخواست تخلیق کریں",
  ORDERS_PAYMENT_LINK_PAYMNT_SEHEDULE_DATE: "ادائیگی کی شیڈول تاریخ",
  ORDERS_PAYMENT_LINK_PAYMNT_Amount: "رقم",
};
