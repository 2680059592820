export const codPendingEnglish = {
  MY_CARRIER_COD_PENDING_TEXT: "COD Pending",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "Payment Method",
  MY_CARRIER_COD_PENDING_CUSTOMER: "Customer",
  MY_CARRIER_COD_PENDING_RETURNS: "Returns",
  MY_CARRIER_COD_PENDING_START_DATE: "Start Date",
  MY_CARRIER_COD_PENDING_END_DATE: "End Date",
  MY_CARRIER_COD_PENDING_STORE: "Store",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "Order Type",
  MY_CARRIER_COD_PENDING_ORDER_NO: "Order No.",
  MY_CARRIER_COD_PENDING_ORDER_NOS: "Order Nos",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "Carrier Name",
  MY_CARRIER_COD_PENDING_STORE_REF: "Store Ref",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "Tracking Status",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "Payment Method",
  MY_CARRIER_COD_PENDING_PAYMENT: "Payment",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "Order Date",
  MY_CARRIER_COD_PENDING_AMOUNT: "Amount",
  MY_CARRIER_COD_PENDING_ACTION: "Action",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "Payment date",
  MY_CARRIER_COD_PENDING_PAYMENT_REF: "Payment Ref",
  MY_CARRIER_COD_PENDING_AUTO_GENERATE_REF: "Auto Generate Ref",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "Update Order Amount",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "New COD Amount",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "Update Amount",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "Create Settlement",
  MY_CARRIER_COD_PENDING_ORDER_NOT_FOUND: "Order not found",
  MY_CARRIER_COD_PENDING_UNABLE_TO_DOWNLOAD: "Unable to download",
  MY_CARRIER_COD_PENDING_NOTE: "Note: COD Amount when create order is",
  MY_CARRIER_COD_PENDING_SETTLEMENT_CREATED_SUCCESSFULLY:
    "Settlement created successfully",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_DATE:
    "Please choose payment date",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_REFERENCE:
    "Please choose payment reference",
};
export const codPendingArabic = {
  MY_CARRIER_COD_PENDING_TEXT: "الدفع عند الاستلام معلق",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "طريقة الدفع",
  MY_CARRIER_COD_PENDING_CUSTOMER: "العميل",
  MY_CARRIER_COD_PENDING_RETURNS: "المرتجعات",
  MY_CARRIER_COD_PENDING_START_DATE: "تاريخ البداية",
  MY_CARRIER_COD_PENDING_END_DATE: "تاريخ النهاية",
  MY_CARRIER_COD_PENDING_STORE: "المتجر",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "نوع الطلب",
  MY_CARRIER_COD_PENDING_ORDER_NO: "رقم الطلب",
  MY_CARRIER_COD_PENDING_ORDER_NOS: "رقم الطلب",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "اسم الناقل",
  MY_CARRIER_COD_PENDING_STORE_REF: "رقم المتجر",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "حالة التتبع",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "طريقة الدفع",
  MY_CARRIER_COD_PENDING_PAYMENT: "الدفع",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "تاريخ الطلب",
  MY_CARRIER_COD_PENDING_AMOUNT: "المبلغ",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "تاريخ الدفع",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "تاريخ الدفع",
  MY_CARRIER_COD_PENDING_PAYMENT_REF: "مرجع الدفع",
  MY_CARRIER_COD_PENDING_AUTO_GENERATE_REF: "توليد مرجع تلقائي",
  MY_CARRIER_COD_PENDING_ACTION: "الإجراء",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "تحديث مبلغ الطلب",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "مبلغ الدفع الجديد",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "تحديث المبلغ",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "إنشاء تسوية",
  MY_CARRIER_COD_PENDING_ORDER_NOT_FOUND: "الطلب غير موجود",
  MY_CARRIER_COD_PENDING_UNABLE_TO_DOWNLOAD: "غير قادر على التنزيل",
  MY_CARRIER_COD_PENDING_SETTLEMENT_CREATED_SUCCESSFULLY:
    "تم إنشاء التسوية بنجاح",
  MY_CARRIER_COD_PENDING_NOTE:
    "ملاحظة: مبلغ الدفع عند الاستلام عند إنشاء الطلب هو",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_DATE: "يرجى اختيار تاريخ الدفع",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_REFERENCE:
    "يرجى اختيار مرجع الدفع",
};
export const codPendingChinese = {
  MY_CARRIER_COD_PENDING_TEXT: "货到付款待处理",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "付款方式",
  MY_CARRIER_COD_PENDING_CUSTOMER: "客户",
  MY_CARRIER_COD_PENDING_RETURNS: "退货",
  MY_CARRIER_COD_PENDING_START_DATE: "开始日期",
  MY_CARRIER_COD_PENDING_END_DATE: "结束日期",
  MY_CARRIER_COD_PENDING_STORE: "店铺",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "订单类型",
  MY_CARRIER_COD_PENDING_ORDER_NO: "订单号",
  MY_CARRIER_COD_PENDING_ORDER_NOS: "订单号",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "承运人名称",
  MY_CARRIER_COD_PENDING_STORE_REF: "店铺参考",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "跟踪状态",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "付款方式",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "付款日期",
  MY_CARRIER_COD_PENDING_AUTO_GENERATE_REF: "自动生成参考",
  MY_CARRIER_COD_PENDING_PAYMENT: "付款",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "订单日期",
  MY_CARRIER_COD_PENDING_AMOUNT: "金额",
  MY_CARRIER_COD_PENDING_ACTION: "操作",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "更新订单金额",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "新货到付款金额",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "更新金额",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "创建结算",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "付款日期",
  MY_CARRIER_COD_PENDING_PAYMENT_REF: "付款参考",
  MY_CARRIER_COD_PENDING_ORDER_NOT_FOUND: "未找到订单",
  MY_CARRIER_COD_PENDING_UNABLE_TO_DOWNLOAD: "无法下载",
  MY_CARRIER_COD_PENDING_NOTE: "注意：创建订单时的货到付款金额为",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_DATE: "请选择付款日期",
  MY_CARRIER_COD_PENDING_SETTLEMENT_CREATED_SUCCESSFULLY: "结算创建成功",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_REFERENCE: "请选择付款参考",
};
export const codPendingUrdu = {
  MY_CARRIER_COD_PENDING_TEXT: "سی او ڈی زیر التواء",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "ادائیگی کا طریقہ",
  MY_CARRIER_COD_PENDING_CUSTOMER: "صارف",
  MY_CARRIER_COD_PENDING_RETURNS: "واپسی",
  MY_CARRIER_COD_PENDING_START_DATE: "آغاز کی تاریخ",
  MY_CARRIER_COD_PENDING_END_DATE: "اختتامی تاریخ",
  MY_CARRIER_COD_PENDING_STORE: "اسٹور",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "آرڈر کی قسم",
  MY_CARRIER_COD_PENDING_ORDER_NO: "آرڈر نمبر",
  MY_CARRIER_COD_PENDING_ORDER_NOS: "آرڈر نمبرز",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "کیریئر کا نام",
  MY_CARRIER_COD_PENDING_STORE_REF: "اسٹور حوالہ",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "ٹریکنگ کی حیثیت",
  MY_CARRIER_COD_PENDING_PAYMENT: "ادائیگی",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "آرڈر کی تاریخ",
  MY_CARRIER_COD_PENDING_AMOUNT: "رقم",
  MY_CARRIER_COD_PENDING_ACTION: "عمل",
  MY_CARRIER_COD_PENDING_PAYMENT_DATE: "ادائیگی کی تاریخ",
  MY_CARRIER_COD_PENDING_PAYMENT_REF: "ادائیگی کا حوالہ",
  MY_CARRIER_COD_PENDING_AUTO_GENERATE_REF: "خودکار حوالہ تخلیق کریں",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "آرڈر کی رقم کو اپ ڈیٹ کریں",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "نیا سی او ڈی رقم",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "رقم کو اپ ڈیٹ کریں",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "تصفیہ بنائیں",
  MY_CARRIER_COD_PENDING_ORDER_NOT_FOUND: "آرڈر نہیں ملا",
  MY_CARRIER_COD_PENDING_UNABLE_TO_DOWNLOAD: "ڈاؤن لوڈ کرنے سے قاصر",
  MY_CARRIER_COD_PENDING_NOTE: "نوٹ: آرڈر بناتے وقت سی او ڈی رقم",
  MY_CARRIER_COD_PENDING_SETTLEMENT_CREATED_SUCCESSFULLY:
    "تصفیہ کامیابی کے ساتھ تخلیق کیا گیا",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_DATE:
    "براہ کرم ادائیگی کی تاریخ منتخب کریں",
  MY_CARRIER_COD_PENDING_PLEASE_CHOOSE_PAYMENT_REFERENCE:
    "براہ کرم ادائیگی کا حوالہ منتخب کریں",
};
