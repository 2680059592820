export const payoutBankEnglish = {
  SETTINGS_PAYOUT_BANK: "Payout Bank",
  SETTINGS_PAYOUT_BANK_ALL: "All",
  SETTINGS_PAYOUT_BANK_ACCOUNT_TITLE: "Account Title",
  SETTINGS_PAYOUT_BANK_BANK_NAME: "Bank Name",
  SETTINGS_PAYOUT_BANK_BRANCH_NAME: "Branch Name",
  SETTINGS_PAYOUT_BANK_IBAN: "IBAN",
  SETTINGS_PAYOUT_BANK_SWIFT_CODE: "SwiftCode",
  SETTINGS_PAYOUT_BANK_ACTION: "Action",
  SETTINGS_PAYOUT_BANK_CREATE_BANK: "Create Bank",
};
export const payoutBankArabic = {
  SETTINGS_PAYOUT_BANK: "بنك الدفع",
  SETTINGS_PAYOUT_BANK_ALL: "الكل",
  SETTINGS_PAYOUT_BANK_ACCOUNT_TITLE: "عنوان الحساب",
  SETTINGS_PAYOUT_BANK_BANK_NAME: "اسم البنك",
  SETTINGS_PAYOUT_BANK_BRANCH_NAME: "اسم الفرع",
  SETTINGS_PAYOUT_BANK_IBAN: "رقم الحساب البنكي الدولي (IBAN)",
  SETTINGS_PAYOUT_BANK_SWIFT_CODE: "رمز السويفت",
  SETTINGS_PAYOUT_BANK_ACTION: "إجراء",
  SETTINGS_PAYOUT_BANK_CREATE_BANK: "إنشاء بنك",
};
export const payoutBankChinese = {
  SETTINGS_PAYOUT_BANK: "付款银行",
  SETTINGS_PAYOUT_BANK_ALL: "全部",
  SETTINGS_PAYOUT_BANK_ACCOUNT_TITLE: "账户名称",
  SETTINGS_PAYOUT_BANK_BANK_NAME: "银行名称",
  SETTINGS_PAYOUT_BANK_BRANCH_NAME: "分行名称",
  SETTINGS_PAYOUT_BANK_IBAN: "国际银行账户号码 (IBAN)",
  SETTINGS_PAYOUT_BANK_SWIFT_CODE: "SWIFT代码",
  SETTINGS_PAYOUT_BANK_ACTION: "操作",
  SETTINGS_PAYOUT_BANK_CREATE_BANK: "创建银行",
};
export const payoutBankUrdu = {
  SETTINGS_PAYOUT_BANK: "پے آؤٹ بینک",
  SETTINGS_PAYOUT_BANK_ALL: "تمام",
  SETTINGS_PAYOUT_BANK_ACCOUNT_TITLE: "اکاؤنٹ کا عنوان",
  SETTINGS_PAYOUT_BANK_BANK_NAME: "بینک کا نام",
  SETTINGS_PAYOUT_BANK_BRANCH_NAME: "برانچ کا نام",
  SETTINGS_PAYOUT_BANK_IBAN: "آئی بی اے این",
  SETTINGS_PAYOUT_BANK_SWIFT_CODE: "سویفٹ کوڈ",
  SETTINGS_PAYOUT_BANK_ACTION: "عمل",
  SETTINGS_PAYOUT_BANK_CREATE_BANK: "بینک بنائیں",
};
