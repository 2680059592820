import { makeStyles } from "@material-ui/core";
import { Add, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { red } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import CustomReactDatePickerInput from "../../../.reUseableComponents/TextField/CustomReactDatePickerInput";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  CompleteDeliveryNote,
  CreateDeliveryNoteDetailPendingForReturnStatus,
  CreateExpenseCategory,
  GetAllExpenseCategoryLookup,
  GetDeliveryNoteDetailForDebrief,
  RevertDeliveryNoteDetailByOrderId,
  UpdateDeliveryNoteInOperation,
  UpdateOrderStatusOnDebrief,
} from "../../../api/AxiosInterceptors";
import "../../../assets/styles/datePickerCustomStyles.css";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  EnumCarrierTrackingStatus,
  EnumDeliveryNoteDetailStatusLookup,
  EnumOptions,
} from "../../../utilities/enum";
import Colors from "../../../utilities/helpers/Colors";
import {
  ActionButtonCustom,
  CodeBox,
  DescriptionBoxWithChild,
  DialerBox,
  amountFormat,
  purple,
  rightColumn,
  usePagination,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import StatusBadge from "../../shared/statudBadge";

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

//#endregion
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function DebriefNotesModal(props) {
  let {
    open,
    setOpen,
    selectedRowData,
    setSelectedRowData,
    getAllDeliveryNote,
  } = props;
  const classes = useStyles();

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [inOpsloadingState, setInOpsloadingState] = React.useState({});
  const [revertloadingState, setRevertloadingState] = React.useState({});
  const [pfrloadingState, setPfrloadingState] = React.useState({});
  const [markCompleteState, setMarkCompleteState] = React.useState({});
  const [isCompleteLoading, setIsCompleteLoading] = React.useState(false);
  const [isOpenAddExpense, setIsOpenAddExpense] = React.useState(false);
  const [isDeliveryNotCompleted, setIDeliveryNotCompleted] =
    React.useState(true);
  const [allExpenseCategory, setAllExpenseCategory] = useState([]);

  const [allDeliveryNoteDetailForDebrief, setAllDeliveryNoteDetailForDebrief] =
    useState([]);
  const [isAllListLoading, setIsAllListLoading] = useState(false);
  useEffect(() => {
    if (Object.keys(selectedRowData).length > 0) {
      let isCompleted = selectedRowData.IsCompleted
        ? selectedRowData.IsCompleted
        : false;
      setIDeliveryNotCompleted(isCompleted);
      getDeliveryNoteDetailForDebrief();
    }
  }, [selectedRowData]);

  let getDeliveryNoteDetailForDebrief = async () => {
    let params = {
      deliveryNoteId: selectedRowData.DeliveryNoteId,
    };
    setIsAllListLoading(true);
    let res = await GetDeliveryNoteDetailForDebrief(params);
    if (res.data.result !== null) {
      setAllDeliveryNoteDetailForDebrief(res.data.result);
    }
    setIsAllListLoading(false);
  };

  const handleRemoveKey = (key) => {
    const newObjData = { ...inOpsloadingState };
    delete newObjData[key];
    setInOpsloadingState(newObjData);
  };
  const handleRevertOpsRemoveKey = (key) => {
    const newObjData = { ...revertloadingState };
    delete newObjData[key];
    setRevertloadingState(newObjData);
  };
  const handleInOpsButtonClick = async (OrderNo) => {
    const currentRow = allDeliveryNoteDetailForDebrief.list.find(
      (x) => x.OrderNo == OrderNo
    );
    if (currentRow) {
      setInOpsloadingState((prevState) => ({ ...prevState, [OrderNo]: true }));
      let params = {
        DeliveryNoteDetailId: currentRow.DeliveryNoteDetailId,
      };
      let res = await UpdateDeliveryNoteInOperation(params);
      if (res.data.isSuccess) {
        getDeliveryNoteDetailForDebrief();
        //reset revert state
        setInOpsloadingState({});
      } else {
        errorNotification("Something went wront");
      }
    }
  };
  const handleRevertButtonClick = async (buttonId) => {
    const currentRow = allDeliveryNoteDetailForDebrief.list.find(
      (x) => x.OrderNo == buttonId
    );
    if (currentRow) {
      let params = {
        orderId: currentRow.OrderId,
      };
      let res = await RevertDeliveryNoteDetailByOrderId(params);
      if (res.data.isSuccess) {
        selectedRowData.TotalPendingCount =
          selectedRowData.TotalPendingCount + 1;

        getDeliveryNoteDetailForDebrief();
        //reset revert state
        setRevertloadingState({});
      } else {
        errorNotification("Something went wront");
      }
    }
  };
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const handlePendingForReturnButtonClick = async (buttonId) => {
    const currentRow = allDeliveryNoteDetailForDebrief.list.find(
      (x) => x.OrderNo == buttonId
    );
    if (currentRow) {
      setPfrloadingState((prevState) => ({ ...prevState, [buttonId]: true }));

      let params = {
        orderId: currentRow.OrderId,
      };
      let res = await CreateDeliveryNoteDetailPendingForReturnStatus(params);
      if (res.data.isSuccess) {
        getDeliveryNoteDetailForDebrief();
        //reset revert state
        setPfrloadingState({});
      } else {
        errorNotification("Something went wront");
      }
    }
  };
  const handleMarkCompleteButtonClick = async (buttonId) => {
    const currentRow = allDeliveryNoteDetailForDebrief.list.find(
      (x) => x.OrderNo == buttonId
    );
    if (currentRow) {
      setMarkCompleteState((prevState) => ({ ...prevState, [buttonId]: true }));
      let params = {
        DeliveryNoteDetailId: currentRow.DeliveryNoteDetailId,
      };
      let res = await UpdateOrderStatusOnDebrief(params);
      if (res.data.isSuccess) {
        getDeliveryNoteDetailForDebrief();
        //reset revert state
        setMarkCompleteState({});
      } else {
        errorNotification("Something went wront");
      }
    }
  };
  const handleClose = () => {
    setInOpsloadingState({});
    setOpen(false);
  };
  const getAllExpenseCategoryLookup = async () => {
    let res = await GetAllExpenseCategoryLookup();
    if (res.data.result !== null) {
      setAllExpenseCategory(res.data.result);
    }
  };
  useEffect(() => {
    getAllExpenseCategoryLookup();
  }, []);
  const handleComplete = async () => {
    if (
      allDeliveryNoteDetailForDebrief?.list.filter(
        (x) =>
          x.DeliveryNoteDetailStatusId ==
          EnumDeliveryNoteDetailStatusLookup.Pending
      ).length == 0
    ) {
      var modifiedExpense = expenseRows?.map((row) => {
        return {
          amount: row.amount,
          expenseDate: UtilityClass.getFormatedDateWithoutTime(row.expenseDate),
          detail: row.detail,
          expenseCategoryId: row.expenseCategoryId?.id,
        };
      });
      let params = {
        deliveryNoteId: selectedRowData?.DeliveryNoteId,
        expenseList: modifiedExpense || [],
      };
      setIsCompleteLoading(true);
      let res = await CompleteDeliveryNote(params);
      setIsCompleteLoading(false);
      if (res.data.isSuccess) {
        setInOpsloadingState({});
        handleClose();
        getAllDeliveryNote();
      } else {
        errorNotification(
          LanguageReducer?.languageType
            ?.MY_CARRIER_DELIVERY_NOTES_SOMETHING_WENT_WRONG
        );
      }
      // setIsCompleteLoading(true);
      // try {
      //   // Simulate an asynchronous operation (e.g., API request) with setTimeout
      //   await new Promise((resolve) => setTimeout(resolve, 2000));
      //   // Once the operation is complete, update the loading state
      //   setIsCompleteLoading(false);
      // } catch (error) {
      //   console.error("Error:", error);
      //   setIsCompleteLoading(false);
      // }
    } else {
      errorNotification(
        LanguageReducer?.languageType
          ?.MY_CARRIER_DELIVERY_NOTES_PLEASE_MAKE_SURE_ALL_ORDERS_ARE_MARKED_TO_COMPLETE_THIS_NOTE
      );
    }
  };
  //#region  table col
  const columns = [
    {
      field: "OrderNo",
      align: "center",
      headerAlign: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_NOTES_ORDER_NO}
        </Box>
      ),
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center", cursor: "pointer" }}
            disableRipple
          >
            <>
              <Box sx={{ fontWeight: "bold" }}>
                <CodeBox title={params.row.OrderNo} hasColor={false} />
              </Box>
              <Box>{params.row.TrackingNo}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "DropOfAddress",
      // headerAlign: "center",
      // align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_CUSTOMER_INFO
          }
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexDirection={"column"} disableRipple>
            <>
              <Box sx={{ fontWeight: "bold" }}>
                {params.row.Customer}
                <DescriptionBoxWithChild>
                  <TableContainer>
                    <Table sx={{ minWidth: 275 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "110px",
                            }}
                            align="left"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                            }}
                          >
                            Mobile
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={params.row.Customer}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                            }}
                            align="left"
                          >
                            {params.row.Customer}
                          </TableCell>
                          <TableCell sx={{ padding: "7px", fontSize: "11px" }}>
                            <DialerBox phone={params.row.Phone} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DescriptionBoxWithChild>
              </Box>
            </>
          </Box>
        );
      },
    },

    {
      field: "CarrierTrackingStatus",
      align: "center",
      headerAlign: "center",
      minWidth: 130,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_TRACKING_STATUS
          }
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return <Box>{params?.row.CarrierTrackingStatus}</Box>;
      },
    },
    {
      field: "DriverLastUpdatedStatus",
      align: "center",
      headerAlign: "center",
      minWidth: 130,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_UPDATE_BY_DRIVER
          }
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          params?.row.DriverLastUpdatedStatus && (
            <Box>
              <StatusBadge
                title={params?.row.DriverLastUpdatedStatus}
                maxWidth="88px"
                borderColor="rgba(0, 186, 119, 0.2)"
                color="#1E1E1E;"
                bgColor="#EAEAEA"
              />
            </Box>
          )
        );
      },
    },
    {
      field: "DeliveryNoteDetailStatus",
      align: "center",
      headerAlign: "center",
      minWidth: 130,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_NOTE_DETAIL_STATUS
          }
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        let bgColor = "rgb(86 58 213)";
        if (
          params?.row.DeliveryNoteDetailStatusId ===
          EnumDeliveryNoteDetailStatusLookup.Completed
        ) {
          bgColor = Colors.succes;
        } else if (
          params?.row.DeliveryNoteDetailStatusId ===
          EnumDeliveryNoteDetailStatusLookup.Pending
        ) {
          bgColor = Colors.warning;
        }

        return (
          <Box>
            <StatusBadge
              title={params?.row.DeliveryNoteDetailStatus}
              maxWidth="88px"
              borderColor="rgba(0, 186, 119, 0.2)"
              color={"#ffff"}
              bgColor={bgColor}
            />
          </Box>
        );
      },
    },

    {
      field: "Remarks",
      align: "center",
      headerAlign: "center",
      minWidth: 130,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_NOTES_REMARKS}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.Remarks && (
            <StatusBadge
              title={params.row.Remarks}
              color="#1E1E1E;"
              bgColor="#EAEAEA"
            />
          )
        );
      },
    },
    {
      ...rightColumn,
      field: "Amount",
      // align: "center",
      // headerAlign: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_NOTES_AMOUNT}
        </Box>
      ),
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return <>{amountFormat(params.row.Amount)}</>;
      },
    },
    {
      field: "Action",
      align: "center",
      headerAlign: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_NOTES_ACTION}
        </Box>
      ),
      minWidth: 330,
      flex: 1,
      hide: isDeliveryNotCompleted,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.CarrierTrackingStatusId ==
              EnumCarrierTrackingStatus.OutForDelivery &&
            params.row.DeliveryNoteDetailStatusId ==
              EnumDeliveryNoteDetailStatusLookup.Pending ? (
              <Stack direction={"row"} gap={1}>
                <Button
                  size="small"
                  sx={{
                    background: "#6DC2FF !important",
                    textTransform: "capitalize !important",
                    padding: "0px 5px",
                  }}
                  onClick={() => handleInOpsButtonClick(params.row.OrderNo)}
                  variant="contained"
                >
                  {inOpsloadingState[params.row.OrderNo]
                    ? LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_NOTES_PENDING
                    : LanguageReducer?.languageType
                        ?.MY_CARRIER_DELIVERY_NOTES_IN_OPERATION}
                </Button>
                <LoadingButton
                  disabled={
                    params?.row.CarrierTrackingStatusId ==
                    EnumCarrierTrackingStatus.PendingForReturn
                  }
                  loading={pfrloadingState[params.row.OrderNo]}
                  size="small"
                  color="warning"
                  sx={{
                    textTransform: "capitalize !important",
                    padding: "0px 5px",
                  }}
                  onClick={() =>
                    handlePendingForReturnButtonClick(params.row.OrderNo)
                  }
                  variant="contained"
                >
                  {
                    LanguageReducer?.languageType
                      ?.MY_CARRIER_DELIVERY_NOTES_PENDING_FOR_RETURN
                  }
                </LoadingButton>
                <LoadingButton
                  loading={markCompleteState[params.row.OrderNo]}
                  size="small"
                  color="success"
                  sx={{
                    // background: "#dc3545 !important",
                    textTransform: "capitalize !important",
                    padding: "0px 5px",
                  }}
                  onClick={() =>
                    handleMarkCompleteButtonClick(params.row.OrderNo)
                  }
                  variant="contained"
                >
                  {
                    LanguageReducer?.languageType
                      ?.MY_CARRIER_DELIVERY_NOTES_COMPLETE
                  }
                </LoadingButton>
              </Stack>
            ) : (
              <LoadingButton
                loading={revertloadingState[params.row.OrderNo]}
                size="small"
                color="error"
                sx={{
                  textTransform: "capitalize !important",
                  padding: "0px 5px",
                  width: "85px",
                }}
                onClick={() => handleRevertButtonClick(params.row.OrderNo)}
                variant="contained"
              >
                {revertloadingState[params.row.OrderNo]
                  ? LanguageReducer?.languageType
                      ?.MY_CARRIER_DELIVERY_NOTES_PENDING
                  : LanguageReducer?.languageType
                      ?.MY_CARRIER_DELIVERY_NOTES_REVERT}
              </LoadingButton>
            )}
          </>
        );
      },
    },
  ];
  //#endregion

  //#region dynamic expense row handles
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  const [isSubmiting, setIsSubmiting] = useState("");

  const createCategoey = async () => {
    const body = {
      expenseName: selectedCategoryValue,
    };
    console.log("body::", body);
    setIsSubmiting(true);

    CreateExpenseCategory(body)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res.data.errors);
        } else {
          successNotification(
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_CATEGORY_CREATED_SUCCESSFULLY
          );
          getAllExpenseCategoryLookup();
          setSelectedCategoryValue("");
        }
      })
      .catch((e) => {
        console.log("e", e);
        if (!e?.response?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(
            e?.response?.data?.errors
          );
        } else {
          errorNotification(
            LanguageReducer?.languageType
              ?.MY_CARRIER_DELIVERY_NOTES_SOMETHING_WENT_WRONG
          );
        }
      })
      .finally((e) => {
        setIsSubmiting(false);
      });
  };

  const defaultExpenseListItem = {
    id: 1,
    expenseCategoryId: 0,
    amount: 0,
    expenseDate: null,
    detail: "",
  };
  const [expenseRows, setExpenseRows] = useState([]);
  const handleAddRow = () => {
    const newRow = {
      id: expenseRows.length + 1,
      expenseCategoryId:
        allExpenseCategory.length > 0
          ? allExpenseCategory[0]
          : { id: 0, text: "Please select" },
      amount: 0,
      expenseDate: new Date(),
      detail: "",
    };
    let validate = expenseRows?.filter(
      (x) => x.expenseCategoryId?.id == 0 || !x.expenseCategoryId?.id
    );
    let validateDate = expenseRows?.filter((x) => x.expenseDate == null);
    if (validate?.length > 0) {
      errorNotification(
        LanguageReducer?.languageType
          ?.MY_CARRIER_DELIVERY_NOTES_PLEASE_CHOOSE_CATEGORY
      );
    } else if (validateDate?.length > 0) {
      errorNotification(
        LanguageReducer?.languageType
          ?.MY_CARRIER_DELIVERY_NOTES_PLEASE_SELECT_DATE
      );
    } else {
      setExpenseRows([...expenseRows, newRow]);
    }
  };

  const handleDeleteRow = (id) => {
    const updatedRows = expenseRows.filter((row) => row.id !== id);
    setExpenseRows(updatedRows);
  };
  const handleCategorySelectChange = (id, newValue) => {
    const updatedRows = expenseRows.map((row) =>
      row.id === id ? { ...row, expenseCategoryId: newValue } : row
    );
    setExpenseRows(updatedRows);
  };

  const handleInputChange = (id, field, value) => {
    const updatedRows = expenseRows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setExpenseRows(updatedRows);
  };
  //#endregion
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);
  return (
    <>
      <ModalComponent
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        title={LanguageReducer?.languageType?.DEBRIEF_TEXT}
        actionBtn={
          !isDeliveryNotCompleted && (
            <ModalButtonComponent
              title={
                LanguageReducer?.languageType?.COMPLETE_TEXT + " " + "Note"
              }
              loading={isCompleteLoading}
              bg={purple}
              onClick={handleComplete}
            />
          )
        }
      >
        {/* <Grid mx={3} mb={2}>
            <Stack direction={"column"}>
              <Stack direction={"row"}>
                <Box sx={{ fontWeight: "bold" }}> Total: 10</Box>
              </Stack>
            </Stack>
          </Grid> */}
        {!isDeliveryNotCompleted && (
          <>
            {expenseRows.map((row) => (
              <Grid
                // sx={{
                //   "& > :not(style)": { m: 1, width: "25ch" },
                // }}
                px={2}
                container
                my={1}
                noValidate
                autoComplete="off"
                direction="row"
                key={row.id}
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid direction={"row"}>
                    <SelectComponent
                      name="category"
                      options={allExpenseCategory}
                      value={row.expenseCategoryId}
                      optionLabel={EnumOptions.EXPENSE_TYPE.LABEL}
                      optionValue={EnumOptions.EXPENSE_TYPE.VALUE}
                      onChange={(e, val) => {
                        handleCategorySelectChange(row.id, val);
                      }}
                      label={
                        LanguageReducer?.languageType
                          ?.MY_CARRIER_DELIVERY_NOTES_CATEGORY
                      }
                      onInputChange={(e, val) => {
                        setSelectedCategoryValue(val);
                      }}
                      noOptionsText={
                        <LoadingButton
                          disabled={selectedCategoryValue?.trim() == ""}
                          loading={isSubmiting}
                          color="warning"
                          variant="contained"
                          startIcon={<Add />}
                          disableRipple
                          disableElevation
                          disableFocusRipple
                          onMouseDown={(e) => {
                            createCategoey();
                          }}
                        >
                          No results! Add
                        </LoadingButton>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    id="amount"
                    size="small"
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    value={row.amount}
                    onChange={(e) =>
                      handleInputChange(row.id, "amount", e.target.value)
                    }
                    sx={{ style: { width: "120px" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box>
                    <CustomReactDatePickerInput
                      value={row.expenseDate}
                      onClick={(date) =>
                        handleInputChange(row.id, "expenseDate", date)
                      }
                      size="small"
                      label="Expense Date"
                      inputProps={{ style: { width: "200px" } }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Stack direction={"row"}>
                    <TextField
                      id="outlined-basidetail"
                      size="small"
                      label="Detail"
                      variant="outlined"
                      fullWidth
                      value={row.detail}
                      onChange={(e) =>
                        handleInputChange(row.id, "detail", e.target.value)
                      }
                      inputProps={{ style: { width: "120px" } }}
                    />
                    {/* <ActionButtonDelete onClick={() => handleDeleteRow(row.id)} /> */}
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleDeleteRow(row.id)}
                    >
                      <Delete fontSize="small" sx={{ color: red[500] }} />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            ))}
            <Stack
              gap={1}
              mx={2}
              mb={2}
              direction={"row"}
              justifyContent={"right"}
            >
              <ActionButtonCustom
                label="Add Expense"
                onClick={(e) => handleAddRow()}
              />
              {/* <Button
                  variant="outlined"
                  size="small"
                  onClick={(e) => handleAddRow()}
                >
                  <Add fontSize="small" />
                  Add Expense
                </Button> */}
            </Stack>
          </>
        )}

        <DataGrid
          loading={isAllListLoading}
          rowHeight={40}
          headerHeight={40}
          autoHeight
          sx={{
            fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
            fontSize: "12px",
            fontWeight: "500",
          }}
          rows={
            allDeliveryNoteDetailForDebrief.list
              ? allDeliveryNoteDetailForDebrief.list
              : []
          }
          getRowId={(row) => row.OrderNo}
          columns={columns}
          disableSelectionOnClick
          pagination
          page={currentPage}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 25]}
          paginationMode="client"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          // checkboxSelection
        />
      </ModalComponent>
      {/* {isOpenAddExpense && (
        <AddDriverExpenseModal
          open={isOpenAddExpense}
          setOpen={setIsOpenAddExpense}
          getAllExpenseCategoryLookup={getAllExpenseCategoryLookup}
        />
      )} */}
    </>
  );
}
export default DebriefNotesModal;
