import { purple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import TextFieldComponent from "../../../.reUseableComponents/TextField/TextFieldComponent";
import TextFieldLableComponent from "../../../.reUseableComponents/TextField/TextFieldLableComponent";
import {
  CreateReturnCommand,
  GetAllReturnStatusForSelection,
  UpdateReturnStatus,
} from "../../../api/AxiosInterceptors";
import {
  fetchMethod,
  GridContainer,
  GridItem,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { EnumOptions } from "../../../utilities/enum";
const initialSmsFieldsData = {
  comment: "",
};
const ReturnOrderModal = (props) => {
  const { open, onClose, returnID, getAllOrderReturn } = props;
  const [returnComment, setReturnComment] = useState(initialSmsFieldsData);
  const [loading, setLoading] = useState(false);
  const [allReturnStatus, setAllReturnStatus] = useState([]);
  const [selectedReturnStatus, setSelectedReturnStatus] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReturnComment((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const getAllReturnStatusForSelection = async () => {
    try {
      const { response } = await fetchMethod(GetAllReturnStatusForSelection);
      if (response?.result) {
        setAllReturnStatus(response?.result);
      } else {
        console.log("No data found");
      }
    } catch (error) {
      console.error("Error fetching order returns:", error);
    }
  };
  const handleUpdateStatus = async () => {
    if (
      !selectedReturnStatus ||
      (typeof selectedReturnStatus === "object" &&
        Object.keys(selectedReturnStatus).length === 0) ||
      selectedReturnStatus?.returnStatusLookupId === 0
    ) {
      errorNotification("Please Select Status");
      return;
    }
    setLoading(true);
    await UpdateReturnStatus(
      returnID,
      selectedReturnStatus?.returnStatusLookupId,
      returnComment.comment
    )
      .then((res) => {
        if (!res?.data?.isSuccess) {
          errorNotification("Failed to Update return Status.");
          getAllOrderReturn();
        } else {
          successNotification("Status Update created successfully.");
        }
      })
      .catch((e) => {
        errorNotification(
          "An error occurred while updating the return status."
        );
      })
      .finally(() => {
        setReturnComment(initialSmsFieldsData);
        setSelectedReturnStatus();
        setLoading(false);
        onClose();
      });
  };
  useEffect(() => {
    getAllReturnStatusForSelection();
  }, []);
  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={"Update Status"}
        actionBtn={
          <ModalButtonComponent
            title={"Update Status"}
            bg={purple}
            loading={loading}
            onClick={(e) => handleUpdateStatus()}
          />
        }
      >
        <GridContainer spacing={1}>
          <GridItem sm={12} md={12} lg={12} xs={12}>
            <TextFieldLableComponent title={"Return Status"} />
            <SelectComponent
              name="returnStatus"
              options={allReturnStatus}
              value={selectedReturnStatus}
              optionLabel={EnumOptions.RETURN_STATUS.LABEL}
              optionValue={EnumOptions.RETURN_STATUS.VALUE}
              onChange={(e, val) => {
                setSelectedReturnStatus(val);
              }}
            />
          </GridItem>
          <GridItem sm={12} md={12} lg={12} xs={12}>
            <TextFieldLableComponent required={false} title={"Comment"} />
            <TextFieldComponent
              sx={{
                fontsize: "14px",
                marginTop: "4px",
                "& .css-setb27-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "8px!important",
                },
              }}
              type="text"
              placeholder={"Enter Comment"}
              value={returnComment.comment}
              name="comment"
              onChange={handleChange}
            />
          </GridItem>
        </GridContainer>
      </ModalComponent>
    </>
  );
};

export default ReturnOrderModal;
