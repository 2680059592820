export const walletEnglish = {
  ORDERS_WALLET: "Wallet",
  ORDERS_WALLET_ALL: "All",
  ORDERS_WALLET_AVAILABLE_BALANCE: "Available Balance",
  ORDERS_WALLET_CURRENT_BALANCE: "Current Balance",
  ORDERS_WALLET_PAYOUT_COMPLETED: "Payout Completed",
  ORDERS_WALLET_PAYOUT_REQUEST: "Payout Request",
  ORDERS_WALLET_PAYOUT_REF: "Payout Ref",
  ORDERS_WALLET_TRANSACTION_REF: "Transaction ref",
  ORDERS_WALLET_CREATED_ON: "Created On",
  ORDERS_WALLET_ACCOUNT_TITLE: "Account Title",
  ORDERS_WALLET_BANK_NAME: "Bank Name",
  ORDERS_WALLET_PAYMENT_STATUS: "Payment Status",
  ORDERS_WALLET_CURRENCY: "Currency",
  ORDERS_WALLET_AMOUNT: "Amont",
  ORDERS_WALLET_SERVICE_CHARGES: "Service Charges",
  ORDERS_WALLET_TRANSCTION_CHARGES: "Transaction Charges",
  ORDERS_WALLET_OUTSTANDING: "Outstanding",
  ORDERS_WALLET_ACTION: "Action",
  ORDERS_WALLET_TRANSCTION_HISTORY: "Transaction History",
  ORDERS_WALLET_TRANSCTION_TYPE: "Transaction Type",
  ORDERS_WALLET_TRANSCTION: "Transaction",
  ORDERS_WALLET_TRANSCTION_NAME: "Transaction Name",
  ORDERS_WALLET_TRANSCTION_NO: "Transaction No.",
  ORDERS_WALLET_DESCRIPTION: "Discription",
  ORDERS_WALLET_CREDIT: "Credit",
  ORDERS_WALLET_DEBIT: "Debit",
  ORDERS_WALLET_PAYOUT_FILE: "Payout File",
  ORDERS_WALLET_History: "History",
  ORDERS_WALLET_EXCEL_DOWNLOAD: "Excel Dowmload",
};
export const walletArabic = {
  ORDERS_WALLET: "محفظة",
  ORDERS_WALLET_ALL: "الكل",
  ORDERS_WALLET_AVAILABLE_BALANCE: "الرصيد المتاح",
  ORDERS_WALLET_CURRENT_BALANCE: "الرصيد الحالي",
  ORDERS_WALLET_PAYOUT_COMPLETED: "تم إتمام الدفع",
  ORDERS_WALLET_PAYOUT_REQUEST: "طلب دفع",
  ORDERS_WALLET_PAYOUT_REF: "مرجع الدفع",
  ORDERS_WALLET_TRANSACTION_REF: "مرجع المعاملة",
  ORDERS_WALLET_CREATED_ON: "تم الإنشاء في",
  ORDERS_WALLET_ACCOUNT_TITLE: "عنوان الحساب",
  ORDERS_WALLET_BANK_NAME: "اسم البنك",
  ORDERS_WALLET_PAYMENT_STATUS: "حالة الدفع",
  ORDERS_WALLET_CURRENCY: "العملة",
  ORDERS_WALLET_AMOUNT: "المبلغ",
  ORDERS_WALLET_SERVICE_CHARGES: "رسوم الخدمة",
  ORDERS_WALLET_TRANSCTION: "المعاملة",
  ORDERS_WALLET_TRANSCTION_NAME: "اسم المعاملة",
  ORDERS_WALLET_TRANSCTION_NO: "رقم المعاملة",
  ORDERS_WALLET_DESCRIPTION: "الوصف",
  ORDERS_WALLET_CREDIT: "ائتمان",
  ORDERS_WALLET_DEBIT: "خصم",
  ORDERS_WALLET_TRANSCTION_CHARGES: "رسوم المعاملة",
  ORDERS_WALLET_OUTSTANDING: "المستحق",
  ORDERS_WALLET_ACTION: "الإجراء",
  ORDERS_WALLET_TRANSCTION_HISTORY: "تاريخ المعاملة",
  ORDERS_WALLET_TRANSCTION_TYPE: "نوع المعاملة",
  ORDERS_WALLET_PAYOUT_FILE: "ملف الدفع",
  ORDERS_WALLET_History: "التاريخ",
  ORDERS_WALLET_EXCEL_DOWNLOAD: "تحميل Excel",
};
export const walletChinese = {
  ORDERS_WALLET: "钱包",
  ORDERS_WALLET_ALL: "所有",
  ORDERS_WALLET_AVAILABLE_BALANCE: "可用余额",
  ORDERS_WALLET_CURRENT_BALANCE: "当前余额",
  ORDERS_WALLET_PAYOUT_COMPLETED: "支付完成",
  ORDERS_WALLET_PAYOUT_REQUEST: "支付请求",
  ORDERS_WALLET_PAYOUT_REF: "支付参考",
  ORDERS_WALLET_TRANSACTION_REF: "交易参考",
  ORDERS_WALLET_CREATED_ON: "创建于",
  ORDERS_WALLET_ACCOUNT_TITLE: "账户标题",
  ORDERS_WALLET_BANK_NAME: "银行名称",
  ORDERS_WALLET_PAYMENT_STATUS: "支付状态",
  ORDERS_WALLET_CURRENCY: "货币",
  ORDERS_WALLET_AMOUNT: "金额",
  ORDERS_WALLET_SERVICE_CHARGES: "服务费用",
  ORDERS_WALLET_TRANSCTION: "交易",
  ORDERS_WALLET_TRANSCTION_NAME: "交易名称",
  ORDERS_WALLET_TRANSCTION_NO: "交易编号",
  ORDERS_WALLET_DESCRIPTION: "描述",
  ORDERS_WALLET_CREDIT: "信用",
  ORDERS_WALLET_DEBIT: "借记",
  ORDERS_WALLET_TRANSCTION_CHARGES: "交易费用",
  ORDERS_WALLET_OUTSTANDING: "未结清",
  ORDERS_WALLET_ACTION: "操作",
  ORDERS_WALLET_TRANSCTION_HISTORY: "交易历史",
  ORDERS_WALLET_TRANSCTION_TYPE: "交易类型",
  ORDERS_WALLET_PAYOUT_FILE: "支付文件",
  ORDERS_WALLET_History: "历史",
  ORDERS_WALLET_EXCEL_DOWNLOAD: "下载 Excel",
};

export const walletUrdu = {
  ORDERS_WALLET: "والیٹ",
  ORDERS_WALLET_ALL: "تمام",
  ORDERS_WALLET_AVAILABLE_BALANCE: "دستیاب بیلنس",
  ORDERS_WALLET_CURRENT_BALANCE: "موجودہ بیلنس",
  ORDERS_WALLET_PAYOUT_COMPLETED: "پے آؤٹ مکمل",
  ORDERS_WALLET_PAYOUT_REQUEST: "پے آؤٹ کی درخواست",
  ORDERS_WALLET_PAYOUT_REF: "پے آؤٹ حوالہ",
  ORDERS_WALLET_TRANSACTION_REF: "ٹرانزیکشن حوالہ",
  ORDERS_WALLET_CREATED_ON: "تخلیق کی تاریخ",
  ORDERS_WALLET_ACCOUNT_TITLE: "اکاؤنٹ کا عنوان",
  ORDERS_WALLET_BANK_NAME: "بینک کا نام",
  ORDERS_WALLET_PAYMENT_STATUS: "ادائیگی کی حیثیت",
  ORDERS_WALLET_CURRENCY: "کرنسی",
  ORDERS_WALLET_AMOUNT: "رقم",
  ORDERS_WALLET_SERVICE_CHARGES: "سروس چارجز",
  ORDERS_WALLET_TRANSCTION_CHARGES: "ٹرانزیکشن چارجز",
  ORDERS_WALLET_OUTSTANDING: "زیر ادائیگی",
  ORDERS_WALLET_ACTION: "عمل",
  ORDERS_WALLET_TRANSCTION_HISTORY: "ٹرانزیکشن کی تاریخ",
  ORDERS_WALLET_TRANSCTION_TYPE: "ٹرانزیکشن کی قسم",
  ORDERS_WALLET_TRANSCTION: "ٹرانزیکشن",
  ORDERS_WALLET_TRANSCTION_NAME: "ٹرانزیکشن کا نام",
  ORDERS_WALLET_TRANSCTION_NO: "ٹرانزیکشن نمبر",
  ORDERS_WALLET_DESCRIPTION: "تفصیل",
  ORDERS_WALLET_CREDIT: "کریڈٹ",
  ORDERS_WALLET_DEBIT: "ڈیبٹ",
  ORDERS_WALLET_PAYOUT_FILE: "پے آؤٹ فائل",
  ORDERS_WALLET_History: "تاریخ",
  ORDERS_WALLET_EXCEL_DOWNLOAD: "ایکسلی ڈاؤن لوڈ",
};
