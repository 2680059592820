import React, { useEffect, useState } from "react";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import { purple } from "@mui/material/colors";
import {
  CustomColorLabelledOutline,
  GridContainer,
  GridItem,
  placeholders,
} from "../../../utilities/helpers/Helpers";
import { useSelector } from "react-redux";
import { Box, FormLabel, Grid, InputLabel, TextField } from "@mui/material";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import DragDropFile from "../../dragAndDrop/featureImage";
import { styleSheet } from "../../../assets/styles/style";
import { useForm, useWatch } from "react-hook-form";
import {
  GetAllProductCategoryLookup,
  GetStoresForSelection,
} from "../../../api/AxiosInterceptors";
import { EnumOptions } from "../../../utilities/enum";

const UploadProductModal = (props) => {
  const { open, onClose, rowData } = props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
    control,
    unregister,
  } = useForm({
    defaultValues: {
      productName: rowData.title || "Default Product Name",
      description: rowData.description || "Default Description",
      weight: rowData.weight || "",
      price: rowData.price || "",
      purchasePrice: rowData.purchasePrice || "",
    },
  });
  useWatch({ name: "store", control });
  useWatch({ name: "productCategoryId", control });
  console.log(rowData);
  const handleFocus = (event) => event.target.select();
  const [storeList, setStoreList] = useState([]);
  const [productImageFile, setProductImageFile] = useState();
  const [isLowerPrice, setIsLowerPrice] = useState(false);
  const [productCategories, setProductCategories] = useState([]);

  const getStoresForSelection = async () => {
    const res = await GetStoresForSelection();
    if (res.data.result != null) {
      setStoreList(res?.data?.result);
    }
  };

  const getAllProductCategoryLookup = async () => {
    const res = await GetAllProductCategoryLookup();
    if (res.data.result !== null) {
      setProductCategories(res.data.result);
    }
  };

  const handleUploadProduct = () => {};

  useEffect(() => {
    let sp = getValues("price");
    let pp = getValues("purchasePrice");
    if (parseInt(sp) && parseInt(pp)) {
      if (parseInt(sp) < parseInt(pp)) {
        setIsLowerPrice(true);
      } else {
        setIsLowerPrice(false);
      }
    } else {
      setIsLowerPrice(false);
    }
  }, [watch("purchasePrice"), watch("price")]);

  useEffect(() => {
    const selectedStore = storeList.find(
      (store) => store.storeId === rowData.storeId
    );
    setValue("store", selectedStore || null);
  }, [rowData, storeList]);
  useEffect(() => {
    const selectedCategory = productCategories.find(
      (category) => category?.productCategoryId === rowData?.productCategoryId
    );
    setValue("productCategoryId", selectedCategory || null);
  }, [rowData, productCategories]);
  useEffect(() => {
    setProductImageFile(rowData?.featureImage);
  }, [rowData]);
  useEffect(() => {
    getStoresForSelection();
    getAllProductCategoryLookup();
  }, []);

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      maxWidth="lg"
      title={"Upload Product"}
      actionBtn={
        <ModalButtonComponent
          title={"Upload Product"}
          bg={purple}
          type="submit"
          //   loading={isLoading}
          onClick={handleSubmit(handleUploadProduct)}
        />
      }
    >
      <form onSubmit={handleSubmit(handleUploadProduct)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomColorLabelledOutline
              label={LanguageReducer?.languageType?.PRODUCT_DETAIL_TEXT}
            >
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.PRODUCTS_STORE}
                  </InputLabel>
                  <SelectComponent
                    name="store"
                    optionProperty={"storeName"}
                    setValue={setValue}
                    control={control}
                    options={storeList}
                    isRHF={true}
                    required={true}
                    optionLabel={EnumOptions.STORE.LABEL}
                    optionValue={EnumOptions.STORE.VALUE}
                    {...register("store", {
                      required: {
                        value: true,
                      },
                    })}
                    value={getValues("store")}
                    onChange={(event, newValue) => {
                      const resolvedId = newValue ? newValue : null;
                      setValue("store", resolvedId);
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.TITLE_TEXT}
                  </InputLabel>
                  <TextField
                    type="text"
                    size="small"
                    id="productName"
                    name="productName"
                    onFocus={handleFocus}
                    fullWidth
                    variant="outlined"
                    {...register("productName", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          LanguageReducer?.languageType
                            ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                      },
                    })}
                    error={Boolean(errors.productName)} // set error prop
                    helperText={errors.productName?.message}
                    placeholder={placeholders.category_name}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.DESCRIPTION_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder="Description"
                    onFocus={handleFocus}
                    size="small"
                    multiline
                    fullWidth
                    rows={3}
                    variant="outlined"
                    id="description"
                    name="description"
                    {...register("description", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          LanguageReducer?.languageType
                            ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                      },
                    })}
                    error={Boolean(errors.description)} // set error prop
                    helperText={errors.description?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.CATEGORY_TEXT}
                  </InputLabel>
                  <Box className={"flex_between"}>
                    <Box flexBasis={"100%"}>
                      <SelectComponent
                        name="productCategoryId"
                        control={control}
                        options={productCategories}
                        getOptionLabel={(option) => option?.categoryName}
                        isRHF={true}
                        required={true}
                        optionLabel={EnumOptions.CATAGORY.LABEL}
                        optionValue={EnumOptions.CATAGORY.VALUE}
                        {...register("productCategoryId", {
                          required: {
                            value: true,
                          },
                        })}
                        value={getValues("productCategoryId")}
                        onChange={(event, newValue) => {
                          const _productCategoryId = newValue ? newValue : null;
                          setValue("productCategoryId", _productCategoryId);
                        }}
                        errors={errors}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.WEIGHT_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.weight}
                    onFocus={handleFocus}
                    type="number"
                    size="small"
                    id="weight"
                    name="weight"
                    inputProps={{
                      step: "any",
                    }}
                    fullWidth
                    variant="outlined"
                    {...register("weight", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      // pattern: {
                      //   value: /^[0-9]\d*$/,
                      //   message:
                      //     LanguageReducer?.languageType
                      //       ?.WEIGHT_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      // },
                    })}
                    error={Boolean(errors.weight)} // set error prop
                    helperText={errors.weight?.message}
                  ></TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.SALE_PRICE_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.price}
                    type="number"
                    onFocus={handleFocus}
                    size="small"
                    id="price"
                    inputProps={{
                      step: "any",
                    }}
                    name="price"
                    fullWidth
                    variant="outlined"
                    {...register("price", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        // value: /^[1-9]\d*$/,
                        message:
                          LanguageReducer?.languageType
                            ?.PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      },
                    })}
                    error={Boolean(errors.price)} // set error prop
                    helperText={errors.price?.message}
                  ></TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                    {LanguageReducer?.languageType?.PURCHASE_PRICE_TEXT}
                  </InputLabel>
                  <TextField
                    placeholder={placeholders.price}
                    type="number"
                    size="small"
                    onFocus={handleFocus}
                    id="purchasePrice"
                    name="purchasePrice"
                    inputProps={{
                      step: "any",
                    }}
                    fullWidth
                    variant="outlined"
                    {...register("purchasePrice", {
                      required: {
                        value: true,
                        message:
                          LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                      },
                      pattern: {
                        // value: /^[1-9]\d*$/,
                        message:
                          LanguageReducer?.languageType
                            ?.PURCHASE_PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                      },
                    })}
                    min={0}
                    error={Boolean(errors.purchasePrice)} // set error prop
                    helperText={errors.purchasePrice?.message}
                  ></TextField>
                  {isLowerPrice && (
                    <FormLabel sx={{ fontSize: "10px" }} error>
                      *Your Purchase price is greater than sale price
                    </FormLabel>
                  )}
                </Grid>
              </GridContainer>
            </CustomColorLabelledOutline>
          </GridItem>
          {/* feature img box */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomColorLabelledOutline label={"Feature Image"} height={"100%"}>
              <DragDropFile setProductFile={productImageFile} />
            </CustomColorLabelledOutline>
          </GridItem>
        </GridContainer>
      </form>
    </ModalComponent>
  );
};

export default UploadProductModal;
