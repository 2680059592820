import {
  accountArabic,
  accountChinese,
  accountEnglish,
  accountUrdu,
} from "../localization/accounts";
import {
  analyticsArabic,
  analyticsChinese,
  analyticsEnglish,
  analyticsUrdu,
} from "../localization/analytics/analytics";
import {
  CarrierReturnArabic,
  CarrierReturnChinese,
  CarrierReturnEnglish,
  CarrierReturnUrdu,
} from "../localization/carrierReturn/carrierReturn";
import {
  commonKeyArabic,
  commonKeyChinese,
  commonKeyEnglish,
  commonKeyUrdu,
} from "../localization/commonKeys";
import {
  integrationArabic,
  integrationChinese,
  integrationEnglish,
  integrationUrdu,
} from "../localization/integrations";
import {
  myCarrierArabic,
  myCarrierChinese,
  myCarrierEnglish,
  myCarrierUrdu,
} from "../localization/myCarriers";
import {
  ordersArabic,
  ordersChinese,
  ordersEnglish,
  ordersUrdu,
} from "../localization/orders";
import {
  productsArabic,
  productsChinese,
  productsEnglish,
  productsUrdu,
} from "../localization/products";
import {
  settingArabic,
  settingChinese,
  settingEnglish,
  settingUrdu,
} from "../localization/settings";
import {
  shipmentArabic,
  shipmentChinese,
  shipmentEnglish,
  shipmentUrdu,
} from "../localization/shipments/shipment";
import {
  storeArabic,
  storeChinese,
  storeEnglish,
  storeUrdu,
} from "../localization/store/store";

export const MainTextEnglish = {
  ...analyticsEnglish,
  ...storeEnglish,
  ...productsEnglish,
  ...ordersEnglish,
  ...shipmentEnglish,
  ...CarrierReturnEnglish,
  ...myCarrierEnglish,
  ...accountEnglish,
  ...integrationEnglish,
  ...settingEnglish,
  ...commonKeyEnglish,
  AED_TEXT: "AED",
  VAT_TEXT: "Vat",
  EDIT_TEXT: "Edit",
  VIEW_TEXT: "View",
  PRINT_TEXT: "Print",
  DEBERIF_TEXT: "Deberif",
  BACK_TO_APP_TEXT: "Back to App",
  BATCH_OUTSCAN_TEXT: "Batch OutScan",
  BATCH_UPDATE_TEXT: "Batch Update",
  UPDATE_TEXT: "Update",
  UPDATE_ORDER_AMOUNT_TEXT: "Update Order Amount",
  SUBMIT_TEXT: "Submit",
  COMMENTS_TEXT: "Comments",
  TAGS_TEXT: "Tags",
  TYPE_HERE: "Type here",
  SELECT_DRIVER_TEXT: "Select Driver",
  SELECT_CARRIER_TEXT: "Select Carrier",
  DELIVERY_DATE_TEXT: "Delivery Date",
  IN_PROGRESS_TEXT: "In Progress",
  COMPLETED_TEXT: "Completed",
  NOTE_NO_TEXT: "Note no",
  TO_BE_DELIVERED: "To Be Delivered",
  TO_BE_PICKED_UP: "To Be Picked Up",
  DELIVERED_TEXT: "Delivered",
  LOST_AND_DAMAGE_TEXT: "Lost & Damages",
  TO_BE_RETURNED_TEXT: "To Be Returned",
  RTOS_TEXT: "Rtos",
  CANCELED_TEXT: "Canceled",
  CARRIER_STATUS_TEXT: "Carrier Status",
  CARRIER_NAME_TEXT: "Carrier Name",
  CARRIER_WEBSITE_TEXT: "Carrier Website",
  PAYMENT_REFERENCE_TEXT: "Payment Reference",
  RECEIVED_AMOUNT_TEXT: "Received Amount",
  PAYMENT_DATE_TEXT: "Payment Date",
  PAYMENT_STATUS_TEXT: "Payment Status",
  PLAN_TEXT: "Plan",
  BILLING_TEXT: "Billing",
  ROLE_TEXT: "Role",
  PASSWORD_TEXT: "Password",
  CHANGE_PASSWORD_TEXT: "Change Password",
  RESET_PASSWORD_TEXT: "Reset Password",
  API_ACCESS: "Api Access",
  CLIENT_PUBLIC_KEY: "Client Public Key",
  CLIENT_SECRET_KEY: "Client Secret Key",
  CLIENT_ENCRYPTED_KEY: "Client Encrypted Key",
  ROTATE_BTN: "Rotate",
  LANGUAGE_TEXT: "Language",
  SAVE_TEXT: "Save",
  ACCOUNT_OWNER_TEXT: "Account Owner",
  CARD_NUMBER_TEXT: "Card Number",
  APPLY_TEXT: "Apply",
  DRIVER_CODE_TEXT: "Driver Code",
  DRIVER_EMAIL_TEXT: "Driver Email",
  DRIVER_PHONE_TEXT: "Driver Phone",
  USER_CODE_TEXT: "User Code",
  GENDER_NAME_TEXT: "Gender",
  DELETE_TEXT: "Delete",
  CREATE_TEXT: "Create",
  EMPLOYEE_CODE_TEXT: "Employee Code",
  FULL_NAME: "Full Name",
  USER_NAME_TEXT: "Username",
  ADD_DRIVER_TEXT: "Add Driver",
  ADD_EMPLOYEE_TEXT: "Add Employee",
  USERNAME_PREFIX_TEXT: "This username will use for login",
  LOGIN_TEXT: "Log In",
  LOGIN_INSTEAD_TEXT: "Login Instead",
  DEBRIEF_TEXT: "Debrief",
  COMPLETE_TEXT: "Complete",
  IN_OPERATION_TEXT: "In operation",
  PAYMENTS_TEXT: "Payment",
  SIGNUP_TEXT: "SignUp",
  SIGN_IN_HISTORY_TEXT: "Sign In History",
  SIGN_IN_TEXT: "Sign In",
  SIGN_UP_TEXT: "Sign Up",
  DONT_HAVE_AN_ACCOUNT_TEXT: "Don't have an account?",
  FORGOT_PASSWORD_TEXT: "Forgot Password",
  ALREADY_HAVE_AN_ACCOUNT_TEXT: "Already have an account?",
  NAME_ALREADY_EXIST_TEXT: "Name Already Exist",
  CANNOT_ADD_MORE_OPTIONS_TEXT: "Cannot add more options",
  CREATE_ACCOUNT_TEXT: "Let's get started",
  FIRST_NAME_TEXT: "First Name",
  LAST_NAME_TEXT: "Last Name",
  CURRENT_PASSWORD_TEXT: "Current Password",
  OLD_PASSWORD_TEXT: "Old Password",
  NEW_PASSWORD_TEXT: "New Password",
  CONFIRM_NEW_PASSWORD_TEXT: "Confirm New Password",
  CONFIRM_PASSWORD_TEXT: "Confirm Password",
  ADD_PRODUCTS_TEXT: "Add Products",
  STATION_TEXT: "Station",
  TRANSFER_TEXT: "Transfer",
  INVENTORY_STOCKS_TEXT: "Inventory Stocks",
  INVENTORY_STOCK_S_TEXT: "Inventory Stock(s)",
  INVENTORY_TEXT: "Inventory",
  INVENTORY_SALES_TEXT: "Inventory Sales",
  TITLE_TEXT: "Title",
  SKU_TEXT: "SKU",
  CATEGORY_NAME_TEXT: "Category Name",
  SHIPPER_NAME_TEXT: "Shipper Name",
  VARIANT_COUNT_TEXT: "Variant Count",
  IMAGE_TEXT: "Image",
  FEATURE_IMAGE_TEXT: "Feature Image",
  DRAG_AND_DROP_TEXT: "Drag & Drop your files in the form of png, jpg, pdf.",
  ENTER_TITLE_TEXT: "Enter Title",
  ENTER_DESCRIPTION_TEXT: "Description",
  DESCRIPTION_TEXT: "Description",
  ENTER_REMARKS_TEXT: "Remarks",
  SELECT_CATEGORY_TEXT: "Select Category",
  TOTAL_WEIGHT_TEXT: "Total Weight",
  NUMBER_OF_PIECES_TEXT: "Number of Pieces",
  DESCRIPTION_OF_PIECE_TEXT: "Description of Piece",
  PRICE_TEXT: "Price",
  SALE_PRICE_TEXT: "Sale Price",
  ENTER_SALE_PRICE_TEXT: "Enter Sale Price",
  PURCHASE_PRICE_TEXT: "Purchase Price",
  ENTER_PURCHASE_PRICE_TEXT: "Enter Purchase Price",
  LOGOUT_TEXT: "Logout",
  CLIENT_NAME_TEXT: "Client Name",
  COMPANY_NAME_TEXT: "Company Name ",
  PHONE_NUMBER_TEXT: "Phone No. ",
  MOBILE_NUMBER_TEXT: "Mobile No.",
  CONTACT_US_TEXT: "Contact Us",
  CONTACT_NUMBER_TEXT: "Contact Number",
  LICENCE_NUMBER_TEXT: "License Number",
  STREET_ADDRESS_TEXT: "Street Address",
  SELECT_COUNTRY_TEXT: "Country",
  SELECT_REGION_TEXT: "Region/City",
  SELECT_REGIONTimeZone_TEXT: "Region Time zone",
  SELECT_CITY_TEXT: "Area/Neighbourhood",
  SELECT_REASON_TEXT: "Reason",
  SELECT_STATION_TEXT: "Station",
  SELECT_ORDER_TYPE_TEXT: " Order Type",
  OPTIONAL_TEXT: "Optional",
  VERIFY_OTP_TEXT: "Verify OTP",
  DID_NOT_RECEIVE_CODE_TEXT: "Didn't receive Code?",
  RESEND_OTP_TEXT: "Resend OTP",
  TIME_REMAINING_TEXT: "Time Remaining:",
  SELECT_STORE_TEXT: " Store",
  PRODUCT_DETAIL_TEXT: "Product Detail",
  VARIANTS_TEXT: "Variants",
  ENTER_SKU_TEXT: "Enter SKU",
  AVAILABLE_INVENTORY_AT_TEXT: "Available inventory at: ",
  ALL_LOCATION_TEXT: "All Location ",
  THIS_PRODUCT_HAS_OPTIONS_TEXT: "This product has options.",
  ADD_OPTION_TEXT: "Add Option",
  OPTION_NAME_TEXT: "Option Name",
  SHOWING_TEXT: "Showing",
  EDIT_PRICES_TEXT: "Edit Prices",
  EDIT_SKU_TEXT: "Edit SKU",
  EDIT_QUNATITIES_TEXT: "Update Quantity",
  QTY_COMMITED_TEXT: "QTY Committed",
  QTY_INCOMING_TEXT: "QTY Incoming",
  QTY_AVAILABLE_TEXT: "QTY Available",
  QTY_DAMAGE_TEXT: "QTY Damage",
  CATEGORY_TEXT: "Category",
  ADD_CATEGORY_TEXT: "Add Category",
  SELECTED_TEXT: "Selected",
  CREATE_PRODUCT_TEXT: "Create Product",
  UPDATE_PRODUCT_TEXT: "Update Product",
  TRACK_INVENTORY_TEXT: "Track Inventory",
  QUANTITY_AVAILABLE_TEXT: "Quantity Available",
  ADD_STORE_TEXT: "Add Store",
  CREATE_ORDER_TEXT: "Create Order",
  CREATE_SETTLEMENT_FROM_FILE_TEXT: "Create Settlement From File",
  CUSTOMER_NAME_TEXT: "Customer Name",
  CURRENT_TEXT: "Current",
  LOCATION_TEXT: "Location",
  CONFIRM_TEXT: "Confirm",
  FIELD_REQUIRED_TEXT: "Field Required",
  INCOMING_TEXT: "Incoming",
  SCHEDULED_TEXT: "Scheduled",
  AVAILABLE_TEXT: "Available",
  CREATE_VARIANT_TEXT: "Create Variant",
  UPDATE_QUANTITY_TEXT: "Update Quantity",
  EXPORT_HISTORY_TEXT: "Export History",
  STOCK_HISTORY_TEXT: "Stock History",
  CREATED_FROM_DATE_TEXT: "From Date",
  EXPORT_SUMMARY_TEXT: "Export Summary",
  LOAD_TEXT: "Load",
  SELECTED_STORE_TEXT: "Selected Store",
  CREATED_TO_DATE_TEXT: "To Date",
  QUANTITY_TEXT: "Qty",
  NEW_QUANTITY_TEXT: "NewQuantity",
  REASON_TEXT: "Reason",
  COMMENT_TEXT: "Comment",
  DAMAGE_TEXT: "Damage",
  COMMITTED_TEXT: "Committed",
  PREVIOUS_QUANTITY_TEXT: "PreviousQuantity",
  WEIGHT_TEXT: "Weight",
  ENTER_WEIGHT_TEXT: "Enter Weight",
  ON_ORDER_TEXT: "On Order",
  LONGITUDE_TEXT: "Longitude",
  LATITUDE_TEXT: "Latitude",
  CREATED_ON_TEXT: "CreatedOn",
  SHIPPING_ADDRESS_TEXT: "Shipping Address",
  FROM_MAP_TEXT: "Choose from Map",
  FROM_LINK_TEXT: "From Link",
  ORDER_DETAILS_TEXT: "Order Details",
  LOCATION_FROM_LINK_TEXT: "Location from Link",
  ORDER_DASHBOARD_TEXT: "Order Dashboard",
  UPDATE_FULFILLABLE_ORDER_TEXT: "Update Fulfillable Order",
  CREATE_FULFILLABLE_ORDER_TEXT: "Create Fulfillable Order",
  UPDATE_REGULAR_ORDER_TEXT: "Update Regular Order",
  CREATE_REGULAR_ORDER_TEXT: "Create Regular Order",
  UPLOAD_ORDERS: "Upload Orders",
  PLEASE_SELECT_STORE_TEXT: "Please Select Store",
  PLEASE_SELECT_STATION_TEXT: "Please Select Station",
  APPARTMENT_SUITS_ETC_TEXT: "Apartment/ suite/ etc",
  IMPORT_TEXT: "Import",
  CLEAR_FILTER_TEXT: "Clear Filter",

  DOWNLOAD_SAMPLE_TEXT: "Download Sample",
  SAMPLE_REGULAR_TEXT: "Sample Regular",
  SAMPLE_FULFILLABLE_TEXT: "Sample Fulfillable",
  PLACE_ORDER_FROM_EXCEL_FILE_TEXT: "Place Order From Excel File",

  INPUT_SHOULD_NOT_BE_ONLY_SPACES: "Input should not be only space",
  INPUT_SHOULD_NOT_CONTAIN_SPACES_OR_UPPERCASE:
    "Input should not contain Spaces or Uppercase",
  PERSONAL_INFORMATION_TEXT: "Personal Information",
  PLEASE_ENTER_A_VALID_PHONE_NUMBER: "Please enter a valid phone number",
  PLEASE_ENTER_A_VALID_CONTACT_NUMBER: "Please enter a valid contact number",
  PLEASE_ENTER_A_VALID_MOBILE_NUMBER: "Please enter a valid mobile number",
  PLEASE_ENTER_CLIENT_NAME_TOAST: "Please Enter Client Name.",
  PLEASE_ENTER_USER_NAME_TOAST: "Please Enter User Name.",
  PLEASE_ENTER_EMAIL_TOAST: "Please Enter Email.",
  PLEASE_ENTER_PHONE_NUMBER_TOAST: "Please Enter Phone Number.",
  PLEASE_ENTER_CONTACT_NUMBER_TOAST: "Please Enter Contact Number.",
  PLEASE_SELECT_COUNTRY_FIRST_TOAST: "Please Select Country First.",
  PLEASE_SELECT_REGION_FIRST_TOAST: "Please Select Region First.",
  PLEASE_SELECT_CITY_FIRST_TOAST: "Please Select City First.",
  PLEASE_ENTER_STREET_ADDRESS_TOAST: "Please Enter Street Address.",
  PLEASE_ENTER_PASSWORD_TOAST: "Please Enter Password.",
  PLEASE_ENTER_CONFIRM_PASSWORD_TOAST: "Please Enter Confirm Password.",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH_TOAST:
    "Password and Counfirm Password Must Match.",
  INVALID_PHONE_NUMBER_TOAST: "Invalid Phone Number.",
  INVALID_CONTACT_NUMBER_TOAST: "Invalid Contact Number.",
  INVALID_EMAIL_TOAST: "Invalid Email",
  PASSWORD_MUST_CONTAIN_MINIMUM_8_CHARACTERS_1_UPPERCASE_1_LOWERCASE_1_NUMBER_1_SPECIAL_CHARACTER:
    "Password Must Contain Minimum 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character.",
  USERNAME_NOT_AVAILABLE_TOAST: "Username Not Available.",
  UNABLE_TO_CREATE_USER_TOAST: "Unable to Create User.",
  USER_CREATED_SUCCESSFULLY_TOAST: "User Created Successfully.",
  UNABLE_TO_SEND_OTP_USER_TOAST: "Unable to Send OTP.",
  OTP_SENT_SUCCESSFULLY_TOAST: "OTP Sent Successfully.",
  PLEASE_ENTER_OTP_TOAST: "Please Enter OTP.",
  USER_VERIFIED_SUCCESSFULLY_TOAST: "User Verified Successfully.",
  UNABLE_TO_VERIFY_USER_TOAST: "Unable to Verify User.",
  USER_LOGGED_IN_SUCCESSFULLY_TOAST: "User Logged in Successfully.",
  INVALID_USER_NAME_PASSWORD_TOAST: "Invalid User Name or Password.",
  USER_LOGGED_OUT_SUCCESSFULLY_TOAST: "User Logged Out Successfully.",
  UNABLE_TO_ADD_VARIANT_TOAST: "Unable to add variant",
  UNABLE_TO_LOG_OUT_TOAST: "Unable to Logout.",
  PLEASE_ENTER_STORE_CODE_TOAST: "Please Enter Store Code.",
  PLEASE_ENTER_STORE_NAME_TOAST: "Please Enter Store Name.",
  PLEASE_ENTER_COMPANY_NAME_TOAST: "Please Enter Company Name.",
  PLEASE_ENTER_LICENSE_NUMBER_TOAST: "Please Enter License Number.",
  PLEASE_ENTER_CUSTOMER_SERVICE_NUMBER_TOAST:
    "Please Enter Customer Service Number.",
  PLEASE_ENTER_ZIP_CODE_TOAST: "Please Enter Zip Code.",
  PLEASE_ENTER_URL_TOAST: "Please Enter URL.",
  STORE_CREATED_SUCCESSFULLY_TOAST: "Store Created Successfully.",
  UNABLE_TO_CREATE_STORE_TOAST: "Unable to create Store.",
  STORE_DELETED_SUCCESSFULLY_TOAST: "Store Deleted Successfully.",
  UNABLE_TO_DELETE_STORE_TOAST: "Unable to delete Store.",
  CONFIRMATION_STORE_DELETE_TOAST:
    "Are you sure you want to delete this store Item?",
  CONFIRMATION_DELETE_TOAST: "Are you sure you want to delete this Item?",
  PRODUCT_UPDATED_SUCCESSFULLY_TOAST: "Product Updated Successfully",
  UNABLE_TO_UPDATE_THE_PRODUCT_TOAST: "Unable to update the product",
  UNABLE_TO_GET_INPUT_FIELDS_TOAST: "Unable to get input fields",
  SUPPORT_CONFIRMATION_STORE_DELETE_TOAST:
    "This item will be deleted immediately. You can't undo this action.",
  UNABLE_TO_CREATE_CATEGORY_TOAST: "Unable to Create Category.",
  CATEGORY_CREATED_SUCCESSFULLY_TOAST: "Category Created Successfully.",
  CATEGORY_NAME_CANNOT_BE_EMPTY_TOAST: "Category name cannot be empty.",
  SKU_CANNOT_BE_EMPTY_TOAST: "SKU cannot be empty.",
  PRODUCT_NAME_CANNOT_BE_EMPTY_TOAST: "Product name cannot be empty.",
  PRICE_CANNOT_BE_EMPTY_TOAST: "Price Cannot be Empty.",
  PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST: "Price Must be Greater Than Zero.",
  PURCHASE_PRICE_CANNOT_BE_EMPTY_TOAST: "Purchase Price Cannot be Empty.",
  PURCHASE_PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST:
    "Purchase Price Must be Greater Than Zero.",
  DESCRIPTION_CANNOT_BE_EMPTY_TOAST: "Description cannot be empty.",
  PLEASE_SELECT_PRODUCT_CATEGORY_TOAST: "Please Select Product Category.",
  PLEASE_UPLOAD_FEATURE_IMAGE_TOAST: "Please Upload Featured Image.",
  WEIGHT_CANNOT_BE_EMPTY_TOAST: "Weight Cannot be Empty.",
  WEIGHT_MUST_BE_GREATER_THAN_ZERO_TOAST: "Weight Must be Greater Than Zero.",
  VARIANT_ADDED_SUCCESSFULLY_TOAST: "Variant Added Successfully.",
  PLEASE_SELECT_STORE_TOAST: "Please Select Store.",
  SKU_NOT_AVAILBE_EMPTY_TOAST: "SKU is not Available.",
  UNABLE_TO_CREATE_PRODUCT_TOAST: "Unable to Create Product.",
  PRODUCT_CREATED_SUCCESSFULLY_TOAST: "Product Created Successfully.",
  ENTER_QUANTITY_GREATER_THAN_DAMAGE_REPLACE_QUANTITY_TOAST:
    "Enter quantity greater than damage replace quantity",
  ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_TOAST:
    "Enter quantity greater than available quantity",
  UNABLE_TO_CONNECT_TOAST: "Unable to connect",
  SUCCESSFULLY_CONNECT_TOAST: "Successfully Connected",
  ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_OR_DAMAGE_REPLACE_QUANTITY_TOAST:
    "Enter quantity greater than available or damage replace quantity",
  QUANTITY_SUCCESSFULLY_UPDATED_TOAST: "Quantity successfully updated",
  LATITUDE_AND_LONGITUDE_NOT_FOUND_IN_THE_URL_TOAST:
    "Latitude and longitude not found in the URL.",
  PLEASE_SELECT_TO_DATE_TOAST: "Please Select To Date.",
  PLEASE_SELECT_FROM_DATE_TOAST: "Please Select From Date.",
  PLEASE_SELECT_REASON_TOAST: "Please Select Reason.",
  INVALID_FILE_TYPE_TOAST: "Invalid File Type.",
  FILE_REQURED_TOAST: "File Required.",
  SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST:
    "Something Went Wrong Please Try Again.",
  CARRIER_PAYMENT_SETTLEMENT_CREATED_SUCCESSFULLY_TOAST:
    "Carrier Payment Settlement Created Successfully.",
  MUST_SELECT_SINGLE_ROW_WARNING_TOAST:
    "Please must be select a single record to proceed.",
  MAXMIUM_NUMBER_REACHED: "Maximum Search limit is 200 tags",

  PRODUCT_S_TEXT: "Product(s)",
  ORDER_S_TEXT: "Order(s)",
  SHIPMENT_S_TEXT: "Shipment(s)",
  CARRIER_RETURN_S: "Carrier Return(s)",
  DELIVERY_TASK_S: "Delivery Task(s)",
  DELIVERY_NOTE_S: "Delivery Note(s)",
  EXPENSE_S: "Expense(s)",
  DRIVER_S: "Driver(s)",
  RETURN_S: "Return(s)",
  CARRIER_S_TEXT: "Carrier(s)",
  UPLOAD_ORDER_S: "Upload Order(s)",
  SHIPMENT_TAB_TEXT: "Shipment Tab",
  MIX_SETTINGS: "Document Settings",
};

export const MainTextArabic = {
  ...analyticsArabic,
  ...storeArabic,
  ...productsArabic,
  ...ordersArabic,
  ...shipmentArabic,
  ...CarrierReturnArabic,
  ...myCarrierArabic,
  ...accountArabic,
  ...integrationArabic,
  ...settingArabic,
  ...commonKeyArabic,
  MAXMIUM_NUMBER_REACHED: "",
  USER_S_TEXT: "المستخدمين",
  UPLOAD_ORDER_S: "تحميل الطلبات",
  COMMENT_TEXT: "تعليق",
  REASON_TEXT: "سبب",
  WEIGHT_TEXT: "وزن",
  COD_COLLECTION_PENDING: "مجموعة COD معلقة",
  COD_COLLECTION: "مجموعات COD",
  DRIVER_RECIEVEABLE: "سائق المستحق",
  EXPENSES: "نفقات",
  DRIVER_EXPENSES: "مصاريف السائق",
  DRIVERS: "السائقين",
  DRIVER_STATUSES_TEXT: "حالات السائق",
  DRIVER_STATUSE_S__TEXT: "حالات السائق",
  EXPORT_SUMMARY_TEXT: "ملخص التصدير",
  PREVIOUS_QUANTITY_TEXT: "السابق",
  COD_PENDING: "COD معلق",
  LOAD_TEXT: "حمولة",
  EXPENSE: "مصروف",
  ACCOUNT: "الحساب",
  CANNOT_ADD_MORE_OPTIONS_TEXT: "لا يمكن إضافة المزيد من الخيارات",
  USERS: "المستخدمون",
  API: "API",
  SELECTED_STORE_TEXT: "المتجر المحدد",
  EXPORT_HISTORY_TEXT: "تاريخ التصدير",
  CREATED_ON_TEXT: "تم إنشاؤها على",
  QTY_COMMITED_TEXT: "الكمية الملتزمة",
  QTY_INCOMING_TEXT: "الكمية الواردة",
  QTY_AVAILABLE_TEXT: "الكمية المتاحة",
  QTY_DAMAGE_TEXT: "الضرر الكمية",
  NEW_QUANTITY_TEXT: "جديد",
  STOCK_HISTORY_TEXT: "تاريخ الأسهم",
  SELECT_REASON_TEXT: "حدد السبب",
  CREATED_FROM_DATE_TEXT: "تاريخ الإنشاء",
  CREATED_TO_DATE_TEXT: "تم إنشاؤه حتى الآن",
  QUANTITY_TEXT: "كمية",
  AED_TEXT: "درهم إماراتي",
  VAT_TEXT: "ضريبة القيمة المضافة",
  VIEW_TEXT: "عرض التفاصيل",
  PRINT_TEXT: "مطبعة",
  DEBERIF_TEXT: "دبيريف",
  BACK_TO_APP_TEXT: "العودة إلى التطبيق",
  BATCH_OUTSCAN_TEXT: "مسح دفعة",
  BATCH_UPDATE_TEXT: "تحديث دفعة",
  UPDATE_TEXT: "تحديث",
  UPDATE_ORDER_AMOUNT_TEXT: "تحديث مبلغ الطلب",
  SUBMIT_TEXT: "يُقدِّم",
  COMMENTS_TEXT: "تعليقات",
  TAGS_TEXT: "العلامات",
  TYPE_HERE: "أكتب هنا",
  SELECT_DRIVER_TEXT: "حدد السائق",
  SELECT_CARRIER_TEXT: "حدد الناقل",
  DELIVERY_DATE_TEXT: "تاريخ التسليم او الوصول",
  IN_PROGRESS_TEXT: "في تَقَدم",
  COMPLETED_TEXT: "مكتمل",
  NOTE_NO_TEXT: "لاحظ لا",
  TO_BE_DELIVERED: "ليتم تسليمها",
  TO_BE_PICKED_UP: "ليتم انتقاؤها",
  DELIVERED_TEXT: "تم التوصيل",
  LOST_AND_DAMAGE_TEXT: "الخسائر والأضرار",
  TO_BE_RETURNED_TEXT: "يجب إعادته",
  RTOS_TEXT: "رتوس",
  CANCELED_TEXT: "ألغيت",
  CARRIER_STATUS_TEXT: "حالة الناقل",
  CARRIER_NAME_TEXT: "اسم الناقل",
  CARRIER_WEBSITE_TEXT: "موقع شركة الجوال",
  PAYMENT_REFERENCE_TEXT: "إشارة دفع",
  RECEIVED_AMOUNT_TEXT: "المبلغ الذي تسلمه",
  PAYMENT_DATE_TEXT: "تاريخ الدفع",
  PAYMENT_STATUS_TEXT: "حالة السداد",
  PLAN_TEXT: "يخطط",
  BILLING_TEXT: "الفواتير",
  SECURITY_TEXT: "حماية",
  ROLE_TEXT: "دور",
  PASSWORD_TEXT: "كلمة المرور",
  CHANGE_PASSWORD_TEXT: "غير كلمة السر",
  RESET_PASSWORD_TEXT: "إعادة تعيين كلمة المرور",
  LANGUAGE_TEXT: "لغة",
  SAVE_TEXT: "يحفظ",
  ACCOUNT_OWNER_TEXT: "مالك حساب",
  CARD_NUMBER_TEXT: "رقم البطاقة",
  APPLY_TEXT: "يتقدم",
  DRIVER_CODE_TEXT: "كود السائق",
  DRIVER_EMAIL_TEXT: "البريد الإلكتروني للسائق",
  DRIVER_PHONE_TEXT: "هاتف السائق",
  GENDER_NAME_TEXT: "جنس",
  DELETE_TEXT: "حذف",
  CREATE_TEXT: "خلق",
  EMPLOYEE_CODE_TEXT: "رمز الموظف",
  FULL_NAME: "الاسم الكامل",
  USERS_TEXT: "المستخدمين",
  USER_TEXT: "مستخدم",
  USER_NAME_TEXT: "اسم االمستخدم",
  USER_CODE_TEXT: "الرقم السري للمستخدم",
  ADD_DRIVER_TEXT: "أضف سائق",
  ADD_EMPLOYEE_TEXT: "اضافة موظف",
  USERNAME_PREFIX_TEXT: "This username will use for login",
  LOGIN_TEXT: "تسجيل الدخول",
  LOGIN_INSTEAD_TEXT: "تسجيل الدخول بدلا من ذلك",
  DEBRIEF_TEXT: "استخلاص المعلومات",
  COMPLETE_TEXT: "مكتمل",
  IN_OPERATION_TEXT: "في عملية",
  PAYMENTS_TEXT: "دفع",
  SIGNUP_TEXT: "اشتراك",
  SIGN_IN_HISTORY_TEXT: "تسجيل الدخول في التاريخ",
  SIGN_IN_TEXT: "تسجيل الدخول",
  SIGN_UP_TEXT: "اشتراك",
  DONT_HAVE_AN_ACCOUNT_TEXT: "ليس لديك حساب؟",
  FORGOT_PASSWORD_TEXT: "هل نسيت كلمة السر",
  ALREADY_HAVE_AN_ACCOUNT_TEXT: "هل لديك حساب؟",
  NAME_ALREADY_EXIST_TEXT: "الاسم موجود بالفعل",
  CREATE_ACCOUNT_TEXT: "إنشاء حساب",
  FIRST_NAME_TEXT: "الاسم الأول",
  LAST_NAME_TEXT: "اسم العائلة",
  CURRENT_PASSWORD_TEXT: "كلمة السر الحالية",
  OLD_PASSWORD_TEXT: "كلمة المرور القديمة",
  NEW_PASSWORD_TEXT: "كلمة المرور الجديدة",
  CONFIRM_NEW_PASSWORD_TEXT: "تأكيد كلمة المرور الجديدة",
  CONFIRM_PASSWORD_TEXT: "تأكيد كلمة المرور",
  STATION_TEXT: "محطة",
  TRANSFER_TEXT: "تحويل",
  INVENTORY_STOCKS_TEXT: "مخزون المخزون",
  INVENTORY_STOCK_S_TEXT: "مخزون المخزون",
  INVENTORY_TEXT: "جرد",
  INVENTORY_SALES_TEXT: "مبيعات المخزون",
  TITLE_TEXT: "عنوان",
  SKU_TEXT: "وحدة حفظ الأوراق المالية",
  CATEGORY_NAME_TEXT: "اسم التصنيف",
  SHIPPER_NAME_TEXT: "أسم المورد",
  VARIANT_COUNT_TEXT: "عدد المتغير",
  IMAGE_TEXT: "صورة",
  FEATURE_IMAGE_TEXT: "الصورة المميزة",
  DRAG_AND_DROP_TEXT: "اسحب وأفلت ملفاتك بصيغة png ، jpg ، pdf.",
  ENTER_TITLE_TEXT: "أدخل العنوان",
  ENTER_DESCRIPTION_TEXT: " الوصف",
  DESCRIPTION_TEXT: "وصف",
  ENTER_REMARKS_TEXT: "الملاحظات",
  SELECT_CATEGORY_TEXT: "اختر الفئة",
  TOTAL_WEIGHT_TEXT: "أدخل الوزن الإجمالي",
  ENTER_NUMBER_OF_PIECES_TEXT: "أدخل عدد القطع",
  DESCRIPTION_OF_PIECE_TEXT: "وصف القطعة",
  PRICE_TEXT: "سعر البيع",
  SALE_PRICE_TEXT: "أدخل سعر البيع",
  ENTER_SALE_PRICE_TEXT: "أدخل سعر البيع",
  PURCHASE_PRICE_TEXT: "سعر الشراء  ",
  ENTER_PURCHASE_PRICE_TEXT: "أدخل سعر الشراء",
  LOGOUT_TEXT: "تسجيل خروج",
  CLIENT_NAME_TEXT: "اسم العميل",
  COMPANY_NAME_TEXT: "اسم الشركة",
  PHONE_NUMBER_TEXT: "رقم التليفون",
  MOBILE_NUMBER_TEXT: "رقم الهاتف المحمول",
  CONTACT_US_TEXT: "اتصل بنا",
  CONTACT_NUMBER_TEXT: "رقم الاتصال",
  LICENCE_NUMBER_TEXT: "رقم الرخصة",
  STREET_ADDRESS_TEXT: "عنوان الشارع",
  SELECT_COUNTRY_TEXT: "حدد الدولة",
  SELECT_REGION_TEXT: "اختر المنطقة",
  SELECT_REGIONTimeZone_TEXT: "المنطقة الزمنية",
  SELECT_CITY_TEXT: "اختر مدينة",
  SELECT_STATION_TEXT: "حدد المحطة",
  SELECT_ORDER_TYPE_TEXT: "حدد نوع الأمر",
  OPTIONAL_TEXT: "خياري",
  VERIFY_OTP_TEXT: "تحقق من OTP",
  DID_NOT_RECEIVE_CODE_TEXT: "لم تتلق رمز؟",
  RESEND_OTP_TEXT: "إعادة إرسال OTP",
  TIME_REMAINING_TEXT: "الوقت المتبقي:",
  SELECT_STORE_TEXT: "حدد المتجر",
  PRODUCT_DETAIL_TEXT: "تفاصيل المنتج",
  VARIANTS_TEXT: "المتغيرات",
  ENTER_SKU_TEXT: "أدخل SKU",
  AVAILABLE_INVENTORY_AT_TEXT: "المخزون المتاح في: ",
  ALL_LOCATION_TEXT: "كل موقع",
  CONFIRM_TEXT: "يتأكد",
  THIS_PRODUCT_HAS_OPTIONS_TEXT: "هذا المنتج لديه خيارات.",
  ADD_OPTION_TEXT: "إضافة خيار",
  OPTION_NAME_TEXT: "اسم الخيار",
  SHOWING_TEXT: "عرض",
  EDIT_PRICES_TEXT: "تحرير الأسعار",
  EDIT_SKU_TEXT: "تحرير SKU",
  EDIT_QUNATITIES_TEXT: "تحرير الكميات",
  CATEGORY_TEXT: "فئة",
  ADD_CATEGORY_TEXT: "إضافة فئة",
  SELECTED_TEXT: "المحدد",
  UPDATE_PRODUCT_TEXT: "تحديث المنتج",
  CREATE_VARIANT_TEXT: "إنشاء متغير",
  UPDATE_QUANTITY_TEXT: "تحديث الكمية",
  INPUT_SHOULD_NOT_BE_ONLY_SPACES: "يجب ألا يكون الإدخال عبارة عن مسافة فقط",
  INPUT_SHOULD_NOT_CONTAIN_SPACES_OR_UPPERCASE:
    "يجب ألا يحتوي الإدخال على مسافات أو أحرف كبيرة",
  PERSONAL_INFORMATION_TEXT: "معلومات شخصية",
  PLEASE_ENTER_A_VALID_PHONE_NUMBER: "يرجى إدخال رقم هاتف صالح",
  PLEASE_ENTER_A_VALID_CONTACT_NUMBER: "الرجاء إدخال رقم اتصال صحيح",
  PLEASE_ENTER_A_VALID_MOBILE_NUMBER: "الرجاء إدخال رقم جوال صحيح",
  TRACK_INVENTORY_TEXT: "حساب المخزون",
  QUANTITY_AVAILABLE_TEXT: "الكمية متاحة",
  ADD_STORE_TEXT: "أضف المتجر",
  CREATE_ORDER_TEXT: "إنشاء النظام",
  CREATE_SETTLEMENT_FROM_FILE_TEXT: "إنشاء تسوية من ملف",
  CUSTOMER_NAME_TEXT: "اسم الزبون",
  CURRENT_TEXT: "حاضِر",
  LOCATION_TEXT: "موقع",
  FIELD_REQUIRED_TEXT: "حقل مطلوب",
  INCOMING_TEXT: "واردة",
  SCHEDULED_TEXT: "المقرر",
  AVAILABLE_TEXT: "متاح",
  DAMAGE_TEXT: "ضرر",
  COMMITTED_TEXT: "ملتزم",
  ON_ORDER_TEXT: "على الطلب",
  LONGITUDE_TEXT: "خط الطول",
  LATITUDE_TEXT: "خط العرض",
  SHIPPING_ADDRESS_TEXT: "عنوان الشحن",
  FROM_LINK_TEXT: "من الرابط",
  ORDER_DETAILS_TEXT: "تفاصيل الطلب",
  LOCATION_FROM_LINK_TEXT: "الموقع من الرابط",
  ORDER_DASHBOARD_TEXT: "طلب لوحة القيادة",
  UPDATE_FULFILLABLE_ORDER_TEXT: "تحديث الطلب القابل للتنفيذ",
  CREATE_FULFILLABLE_ORDER_TEXT: "إنشاء أمر قابل للوفاء",
  UPDATE_REGULAR_ORDER_TEXT: "تحديث الطلب العادي",
  CREATE_REGULAR_ORDER_TEXT: "إنشاء أمر منتظم",
  UPLOAD_ORDERS: "تحميل الطلبات",
  PLEASE_SELECT_STORE_TEXT: "الرجاء تحديد المتجر",
  PLEASE_SELECT_STATION_TEXT: "الرجاء تحديد المحطة",
  APPARTMENT_SUITS_ETC_TEXT: "شقة ، جناح ، إلخ",
  IMPORT_TEXT: "يستورد",
  CLEAR_FILTER_TEXT: "مرشح واضح",
  DOWNLOAD_SAMPLE_TEXT: "تحميل نموذج",
  SAMPLE_REGULAR_TEXT: "عينة عادية",
  SAMPLE_FULFILLABLE_TEXT: "عينة قابلة للوفاء",
  PLACE_ORDER_FROM_EXCEL_FILE_TEXT: "ضع الطلب من ملف Excel",
  FROM_MAP_TEXT: "من الخريطة",
  UNABLE_TO_UPDATE_THE_PRODUCT_TOAST: "غير قادر على تحديث المنتج",
  UNABLE_TO_GET_INPUT_FIELDS_TOAST: "تعذر الحصول على حقول الإدخال",
  PRODUCT_UPDATED_SUCCESSFULLY_TOAST: "تم تحديث المنتج بنجاح",
  PLEASE_ENTER_CLIENT_NAME_TOAST: "الرجاء إدخال اسم العميل.",
  PLEASE_ENTER_USER_NAME_TOAST: "الرجاء إدخال اسم المستخدم.",
  PLEASE_ENTER_EMAIL_TOAST: "الرجاء إدخال البريد الإلكتروني.",
  PLEASE_ENTER_PHONE_NUMBER_TOAST: "الرجاء إدخال رقم الهاتف.",
  PLEASE_ENTER_CONTACT_NUMBER_TOAST: "الرجاء إدخال رقم الاتصال.",
  PLEASE_SELECT_COUNTRY_FIRST_TOAST: "يرجى تحديد الدولة أولاً.",
  PLEASE_SELECT_REGION_FIRST_TOAST: "يرجى تحديد المنطقة أولاً.",
  PLEASE_SELECT_CITY_FIRST_TOAST: "يرجى تحديد المدينة أولاً.",
  PLEASE_ENTER_STREET_ADDRESS_TOAST: "الرجاء إدخال عنوان الشارع.",
  VARIANT_ADDED_SUCCESSFULLY_TOAST: "تمت إضافة المتغير بنجاح.",
  PLEASE_ENTER_PASSWORD_TOAST: "الرجاء إدخال كلمة المرور.",
  PLEASE_ENTER_CONFIRM_PASSWORD_TOAST: "الرجاء إدخال تأكيد كلمة المرور.",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH_TOAST:
    "يجب أن تتطابق كلمة المرور وتأكيد كلمة المرور.",
  SUCCESSFULLY_CONNECT_TOAST: "الاتصال بنجاح",
  INVALID_PHONE_NUMBER_TOAST: "رقم الهاتف غير صحيح.",
  INVALID_CONTACT_NUMBER_TOAST: "رقم الاتصال غير صحيح.",
  INVALID_EMAIL_TOAST: "بريد إلكتروني خاطئ",
  PASSWORD_MUST_CONTAIN_MINIMUM_8_CHARACTERS_1_UPPERCASE_1_LOWERCASE_1_NUMBER_1_SPECIAL_CHARACTER:
    "يجب أن تحتوي كلمة المرور على 8 أحرف كحد أدنى ، وحرف كبير واحد ، وحرف صغير ، ورقم واحد ، وحرف خاص واحد.",
  USERNAME_NOT_AVAILABLE_TOAST: "الإسم المستخدم غير موجود.",
  UNABLE_TO_CREATE_USER_TOAST: "تعذر إنشاء مستخدم.",
  UNABLE_TO_ADD_VARIANT_TOAST: "غير قادر على إضافة متغير",
  USER_CREATED_SUCCESSFULLY_TOAST: "تم إنشاء المستخدم بنجاح.",
  UNABLE_TO_SEND_OTP_USER_TOAST: "تعذر إرسال OTP.",
  OTP_SENT_SUCCESSFULLY_TOAST: "تم إرسال OTP بنجاح.",
  PLEASE_ENTER_OTP_TOAST: "الرجاء إدخال OTP.",
  USER_VERIFIED_SUCCESSFULLY_TOAST: "تم التحقق من المستخدم بنجاح",
  UNABLE_TO_VERIFY_USER_TOAST: "تعذر التحقق من المستخدم.",
  USER_LOGGED_IN_SUCCESSFULLY_TOAST: "تم تسجيل دخول المستخدم بنجاح.",
  INVALID_USER_NAME_PASSWORD_TOAST: "خطأ في اسم المستخدم أو كلمة مرور.",
  USER_LOGGED_OUT_SUCCESSFULLY_TOAST: "تم تسجيل خروج المستخدم بنجاح.",
  UNABLE_TO_LOG_OUT_TOAST: "تعذر تسجيل الخروج",
  PLEASE_ENTER_STORE_CODE_TOAST: "الرجاء إدخال رمز المتجر.",
  PLEASE_ENTER_STORE_NAME_TOAST: "الرجاء إدخال اسم المتجر.",
  PLEASE_ENTER_COMPANY_NAME_TOAST: "الرجاء إدخال اسم الشركة.",
  PLEASE_ENTER_LICENSE_NUMBER_TOAST: "الرجاء إدخال رقم الترخيص.",
  PLEASE_ENTER_CUSTOMER_SERVICE_NUMBER_TOAST: "الرجاء إدخال رقم خدمة العملاء.",
  PLEASE_ENTER_ZIP_CODE_TOAST: "الرجاء إدخال الرمز البريدي.",
  PLEASE_ENTER_URL_TOAST: "الرجاء إدخال URL.",
  STORE_CREATED_SUCCESSFULLY_TOAST: "تم إنشاء المتجر بنجاح.",
  UNABLE_TO_CREATE_STORE_TOAST: "تعذر إنشاء المتجر.",
  STORE_DELETED_SUCCESSFULLY_TOAST: "تم حذف المتجر بنجاح.",
  UNABLE_TO_DELETE_STORE_TOAST: "تعذر حذف المتجر.",
  CONFIRMATION_STORE_DELETE_TOAST: "هل أنت متأكد أنك تريد حذف عنصر المتجر هذا؟",
  SUPPORT_CONFIRMATION_STORE_DELETE_TOAST:
    "سيتم حذف هذا العنصر على الفور. لا يمكنك التراجع عن هذا الإجراء.",
  UNABLE_TO_CREATE_CATEGORY_TOAST: "تعذر إنشاء الفئة.",
  CATEGORY_CREATED_SUCCESSFULLY_TOAST: "تم إنشاء الفئة بنجاح.",
  CATEGORY_NAME_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون اسم الفئة فارغًا.",
  PRODUCT_NAME_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون اسم المنتج فارغًا",
  SKU_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون SKU فارغًا.",
  PRICE_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون السعر فارغًا",
  PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST: "يجب أن يكون السعر أكبر من الصفر",
  PURCHASE_PRICE_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون سعر الشراء فارغًا",
  PURCHASE_PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST:
    "يجب أن يكون سعر الشراء أكبر من الصفر",
  DESCRIPTION_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون الوصف فارغًا.",
  PLEASE_SELECT_PRODUCT_CATEGORY_TOAST: "الرجاء تحديد فئة المنتج.",
  PLEASE_UPLOAD_FEATURE_IMAGE_TOAST: "الرجاء تحميل الصورة المميزة",
  WEIGHT_CANNOT_BE_EMPTY_TOAST: "لا يمكن أن يكون الوزن فارغًا.",
  WEIGHT_MUST_BE_GREATER_THAN_ZERO_TOAST: "يجب أن يكون الوزن أكبر من الصفر.",
  PLEASE_SELECT_STORE_TOAST: "الرجاء تحديد المتجر.",
  SKU_NOT_AVAILBE_EMPTY_TOAST: "SKU غير متوفر.",
  UNABLE_TO_CREATE_PRODUCT_TOAST: "تعذر إنشاء المنتج.",
  PRODUCT_CREATED_SUCCESSFULLY_TOAST: "تم إنشاء المنتج بنجاح.",
  ENTER_QUANTITY_GREATER_THAN_DAMAGE_REPLACE_QUANTITY_TOAST:
    "أدخل كمية أكبر من الضرر استبدل الكمية",
  ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_TOAST:
    "أدخل كمية أكبر من الكمية المتاحة",
  ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_OR_DAMAGE_REPLACE_QUANTITY_TOAST:
    "أدخل كمية أكبر من الكمية المتاحة أو قم باستبدال الكمية التالفة",
  QUANTITY_SUCCESSFULLY_UPDATED_TOAST: "تم تحديث الكمية بنجاح",
  LATITUDE_AND_LONGITUDE_NOT_FOUND_IN_THE_URL_TOAST:
    "لم يتم العثور على خط الطول وخط العرض في عنوان URL.",
  PLEASE_SELECT_TO_DATE_TOAST: "الرجاء التحديد حتى الآن.",
  PLEASE_SELECT_FROM_DATE_TOAST: "يرجى تحديد من التاريخ.",
  UNABLE_TO_CONNECT_TOAST: "غير قادر على الاتصال",
  PLEASE_SELECT_REASON_TOAST: "الرجاء تحديد السبب",
  INVALID_FILE_TYPE_TOAST: "نوع الملف غير صالح.",
  FILE_REQURED_TOAST: "الملف مطلوب.",
  SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST:
    "حدث خطأ ما. أعد المحاولة من فضلك.",
  CARRIER_PAYMENT_SETTLEMENT_CREATED_SUCCESSFULLY_TOAST:
    "تم إنشاء تسوية مدفوعات مشغّل شبكة الجوّال بنجاح.",
  MUST_SELECT_SINGLE_ROW_WARNING_TOAST: "يرجى تحديد سجل واحد للمتابعة.",
  SHIPMENT_TAB_TEXT: "علامة تبويب الشحن",
  TAX: "إدارة الضرائب",
};
export const MainTextChinses = {
  ...accountChinese,
  ...analyticsChinese,
  ...CarrierReturnChinese,
  ...integrationChinese,
  ...storeChinese,
  ...productsChinese,
  ...ordersChinese,
  ...shipmentChinese,
  ...myCarrierChinese,
  ...settingChinese,
  ...commonKeyChinese,
};
export const MainTextUrdu = {
  ...analyticsUrdu,
  ...storeUrdu,
  ...productsUrdu,
  ...ordersUrdu,
  ...shipmentUrdu,
  ...CarrierReturnUrdu,
  ...myCarrierUrdu,
  ...accountUrdu,
  ...integrationUrdu,
  ...settingUrdu,
  ...commonKeyUrdu,
};
export const languages = Object.freeze({
  English: MainTextEnglish,
  Arabic: MainTextArabic,
  Chinese: MainTextChinses,
  Urdu: MainTextUrdu,
});
