export const returnOrderEnglish = {
  ORDER_RETURN_ORDER: "Return Order",
  ORDER_ORDER_NUMBER: "Order No.",
  ORDER_RETURN_ORDER_NUMBER: "Return Order No.",
  ORDER_RETURN_REASON: "Return Reason",
  ORDER_RETURN_STATUS: "Return Status",
  ORDER_UPDATE_STATUS: "Update Status",
  ORDER_COMMENT: "Comment",
  ORDER_RETURN_ORDER_NEW: "NEW",
  ORDER_RETURN_ORDER_APPROVED: "Approved",
  ORDER_RETURN_ORDER_RECEIVED: "Received",
  ORDER_RETURN_ORDER_REJECTED: "Rejected",
  ORDER_RETURN_ORDER_REFUND: "ReFund",
  ORDER_RETURN_ORDER_YES: "Yes",
  ORDER_RETURN_ORDER_ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THIS_RETURN_STATUS:
    "Are you sure you want to Change this Return Status",
  ORDER_RETURN_ORDER_SELECTED_ORDERS_STATUS_WILL_BE_CHANGE_IMMEDIATELY_YOU_CAN_UNDO_THIS_ACTION:
    "Selected orders Status Will be Change immediately. You can undo this action.",
};
export const returnOrderArabic = {
  ORDER_RETURN_ORDER: "إرجاع الطلب",
  ORDER_ORDER_NUMBER: "رقم الطلب",
  ORDER_RETURN_ORDER_NUMBER: "رقم الطلب المرتجع",
  ORDER_RETURN_REASON: "سبب الإرجاع",
  ORDER_RETURN_STATUS: "حالة الإرجاع",
  ORDER_UPDATE_STATUS: "تحديث الحالة",
  ORDER_COMMENT: "تعليق",
  ORDER_RETURN_REASON: "سبب الإرجاع",
  ORDER_RETURN_ORDER: "طلب الإرجاع",
  ORDER_RETURN_ORDER_NEW: "جديد",
  ORDER_RETURN_ORDER_APPROVED: "موافق عليه",
  ORDER_RETURN_ORDER_RECEIVED: "تم الاستلام",
  ORDER_RETURN_ORDER_REJECTED: "مرفوض",
  ORDER_RETURN_ORDER_REFUND: "استرداد",
  ORDER_RETURN_ORDER_YES: "نعم",
  ORDER_RETURN_ORDER_ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THIS_RETURN_STATUS:
    "هل أنت متأكد أنك تريد تغيير حالة الإرجاع هذه",
  ORDER_RETURN_ORDER_SELECTED_ORDERS_STATUS_WILL_BE_CHANGE_IMMEDIATELY_YOU_CAN_UNDO_THIS_ACTION:
    "سيتم تغيير حالة الطلبات المحددة فورًا. يمكنك التراجع عن هذا الإجراء",
};
export const retuenOrderChines = {
  ORDER_RETURN_ORDER: "退货订单",
  ORDER_ORDER_NUMBER: "订单号",
  ORDER_RETURN_ORDER_NUMBER: "退货单号",
  ORDER_RETURN_REASON: "退货原因",
  ORDER_RETURN_STATUS: "退货状态",
  ORDER_UPDATE_STATUS: "更新状态",
  ORDER_COMMENT: "评论",
  ORDER_RETURN_REASON: "创建原因",
  ORDER_RETURN_ORDER: "退货订单",
  ORDER_RETURN_ORDER_NEW: "新订单",
  ORDER_RETURN_ORDER_APPROVED: "已批准",
  ORDER_RETURN_ORDER_RECEIVED: "已收到",
  ORDER_RETURN_ORDER_REJECTED: "已拒绝",
  ORDER_RETURN_ORDER_REFUND: "退款",
  ORDER_RETURN_ORDER_YES: "是",
  ORDER_RETURN_ORDER_ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THIS_RETURN_STATUS:
    "您确定要更改此退货状态吗",
  ORDER_RETURN_ORDER_SELECTED_ORDERS_STATUS_WILL_BE_CHANGE_IMMEDIATELY_YOU_CAN_UNDO_THIS_ACTION:
    "所选订单状态将立即更改。您可以撤销此操作",
};
export const returnOrderUrdu = {
  ORDER_RETURN_ORDER: "واپسی آرڈر",
  ORDER_ORDER_NUMBER: "آرڈر نمبر",
  ORDER_RETURN_ORDER_NUMBER: "واپسی آرڈر نمبر",
  ORDER_RETURN_REASON: "واپسی کی وجہ",
  ORDER_RETURN_STATUS: "واپسی کی حالت",
  ORDER_UPDATE_STATUS: "حالت کو اپ ڈیٹ کریں",
  ORDER_COMMENT: "تبصرہ",
  ORDER_RETURN_ORDER_NEW: "نیا",
  ORDER_RETURN_ORDER_APPROVED: "منظور شدہ",
  ORDER_RETURN_ORDER_RECEIVED: "موصول شدہ",
  ORDER_RETURN_ORDER_REJECTED: "مسترد شدہ",
  ORDER_RETURN_ORDER_REFUND: "رقم کی واپسی",
  ORDER_RETURN_ORDER_YES: "ہاں",
  ORDER_RETURN_ORDER_ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THIS_RETURN_STATUS:
    "کیا آپ کو یقین ہے کہ آپ اس واپسی کی حالت کو تبدیل کرنا چاہتے ہیں؟",
  ORDER_RETURN_ORDER_SELECTED_ORDERS_STATUS_WILL_BE_CHANGE_IMMEDIATELY_YOU_CAN_UNDO_THIS_ACTION:
    "منتخب آرڈرز کی حالت فوراً تبدیل ہو جائے گی۔ آپ اس عمل کو پلٹ سکتے ہیں۔",
};
