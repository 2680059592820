export const shipmentTabEnglish = {
  SETTING_SHIPMENT_TAB_TEXT: "Shipment Tab",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "Add New Tab",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "Update Shipment Tab",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "Select Status",
  SETTING_SHIPMENT_TAB_ADD_TAB: "Add Tab",
  SETTING_SHIPMENT_TAB_TAB_NAME: "Tab Name",
};
export const shipmentTabArabic = {
  SETTING_SHIPMENT_TAB_TEXT: " علامة التبويب الشحن",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "إضافة علامة تبويب جديدة",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "تحديث علامة التبويب شحنة",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "اختيار الحالة",
  SETTING_SHIPMENT_TAB_ADD_TAB: "إضافة تبويب",
  SETTING_SHIPMENT_TAB_TAB_NAME: "اسم التبويب",
};
export const shipmentTabChines = {
  SETTING_SHIPMENT_TAB_TEXT: "发货标签",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "添加新标签",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "更新发货选项",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "选择状态",
  SETTING_SHIPMENT_TAB_ADD_TAB: "添加标签",
  SETTING_SHIPMENT_TAB_TAB_NAME: "标签名称",
};
export const shipmentTabUrdu = {
  SETTING_SHIPMENT_TAB_TEXT: "شپمنٹ ٹیب",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "نیا ٹیب شامل کریں",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "شپمنٹ ٹیب کو اپ ڈیٹ کریں",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "حیثیت منتخب کریں",
  SETTING_SHIPMENT_TAB_ADD_TAB: "ٹیب شامل کریں",
  SETTING_SHIPMENT_TAB_TAB_NAME: "ٹیب کا نام",
};
