export const storeEnglish = {
  STORES_TEXT: "Stores",
  STORE_S_TEXT: "Store(s)",
  STORE_ALL: "All",
  STORE_ACTIVE: "Active",
  STORE_INACTIVE: "Inactive",
  STORE_ADD: "Add Store",
  STORE_CODE: "Code",
  STORE_NAME: "Name",
  STORE_COMPANY: "Company",
  STORE_SERVICE_NO: "Service No.",
  STORE_EMAIL: "Email",
  STORE_URL: "URL",
  STORE_SC_COUNT: "SC Count",
  STORE_STATUS: "Status",
  STORE_STORE_NAME: "Store Name",
  STORE_COMPANY_NAME: "Company Name",
  STORE_LICENSE_NUMBER: "License Number",
  STORE_CUSTOMER_SERVICE_NO: "Customer Service No.",
  STORE_PHONE_NO: "Phone No",
  STORE_EMAIL: "Email",
  STORE_URL: "Url",
  STORE_COUNTRY: "Country",
  STORE_REGION_CITY: "Region/City",
  STORE_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  STORE_ZIP: "Zip",
  STORE_AREA: "Area",
  STORE_STREET_ADDRESS: "Street Address",
  STORE_LATITUDE: "Latitude",
  STORE_LONGITUDE: "Longitude",
  STORE_FORM_MAP: "Form Map",
  STORE_START_DATE: "Start Date",
  STORE_END_DATE: "End Date",
  STORE_CLEAR_FILTER: "Clear Filter",
  STORE_FILTER: "Filter",
  STORE_LATITUDE_AND_LONGITUDE: "Latitude And Longitude",
  STORE_GET_ADDRESS: "Get Address",
  STORE_FORM_MAP: "Form Map",
  STORE_UPDATE_STORE: "Update Store",
  STORE_COUNTRY: "Country",
};

export const storeArabic = {
  STORES_TEXT: "المتاجر",
  STORE_S_TEXT: "المتاجر",
  STORE_ALL: "الكل",
  STORE_ACTIVE: "نشط",
  STORE_INACTIVE: "غير نشط",
  STORE_ADD: "إضافة متجر",
  STORE_CODE: "رمز",
  STORE_NAME: "الاسم",
  STORE_COMPANY: "شركة",
  STORE_SERVICE_NO: "رقم الخدمة",
  STORE_EMAIL: "البريد الإلكتروني",
  STORE_URL: "الرابط",
  STORE_COUNTRY: "البلد",
  STORE_SC_COUNT: "عدد SC",
  STORE_STATUS: "الحالة",
  STORE_STORE_NAME: "اسم المتجر",
  STORE_COMPANY_NAME: "اسم الشركة",
  STORE_LICENSE_NUMBER: "رقم الرخصة",
  STORE_CUSTOMER_SERVICE_NO: "رقم خدمة العملاء",
  STORE_PHONE_NO: "رقم الهاتف",
  STORE_EMAIL: "البريد الإلكتروني",
  STORE_URL: "الرابط",
  STORE_COUNTRY: "الدولة",
  STORE_REGION_CITY: "المنطقة/المدينة",
  STORE_AREA_NEIGHBOURHOOD: "المنطقة/الحي",
  STORE_ZIP: "الرمز البريدي",
  STORE_AREA: "المنطقة",
  STORE_STREET_ADDRESS: "عنوان الشارع",
  STORE_LATITUDE: "خط العرض",
  STORE_LONGITUDE: "خط الطول",
  STORE_FORM_MAP: "خريطة النموذج",
  STORE_START_DATE: "تاريخ البدء",
  STORE_END_DATE: "تاريخ الانتهاء",
  STORE_CLEAR_FILTER: "مسح الفلتر",
  STORE_FILTER: "منقي",
  STORE_LATITUDE_AND_LONGITUDE: "خطوط العرض والطول",
  STORE_GET_ADDRESS: "احصل على العنوان",
  STORE_FORM_MAP: "خريطة النموذج",
  STORE_UPDATE_STORE: "تحديث المتجر",
};
export const storeChinese = {
  STORES_TEXT: "商店",
  STORE_S_TEXT: "商店",
  STORE_ALL: "所有",
  STORE_ACTIVE: "活跃的",
  STORE_INACTIVE: "不活跃的",
  STORE_ADD: "添加店铺",
  STORE_CODE: "代码",
  STORE_NAME: "名称",
  STORE_COUNTRY: "国家",
  STORE_COMPANY: "公司",
  STORE_SERVICE_NO: "服务号码",
  STORE_EMAIL: "电子邮件",
  STORE_URL: "网址",
  STORE_SC_COUNT: "SC数量",
  STORE_STATUS: "状态",
  STORE_STORE_NAME: "店铺名称",
  STORE_COMPANY_NAME: "公司名称",
  STORE_LICENSE_NUMBER: "许可证号码",
  STORE_CUSTOMER_SERVICE_NO: "客户服务号码",
  STORE_PHONE_NO: "电话号码",
  STORE_EMAIL: "电子邮件",
  STORE_URL: "网址",
  STORE_COUNTRY: "国家",
  STORE_REGION_CITY: "地区/城市",
  STORE_AREA_NEIGHBOURHOOD: "地区/社区",
  STORE_ZIP: "邮政编码",
  STORE_AREA: "区域",
  STORE_STREET_ADDRESS: "街道地址",
  STORE_LATITUDE: "纬度",
  STORE_LONGITUDE: "经度",
  STORE_FORM_MAP: "地图形式",
  STORE_START_DATE: "开始日期",
  STORE_END_DATE: "结束日期",
  STORE_CLEAR_FILTER: "清除筛选",
  STORE_FILTER: "筛选",
  STORE_LATITUDE_AND_LONGITUDE: "緯度和經度",
  STORE_GET_ADDRESS: "取得地址",
  STORE_FORM_MAP: "地图表单",
  STORE_UPDATE_STORE: "更新存储",
};
export const storeUrdu = {
  STORES_TEXT: "اسٹورز",
  STORE_S_TEXT: "اسٹور(ز)",
  STORE_ALL: "تمام",
  STORE_ACTIVE: "فعال",
  STORE_INACTIVE: "غیر فعال",
  STORE_ADD: "اسٹور شامل کریں",
  STORE_CODE: "کوڈ",
  STORE_NAME: "نام",
  STORE_COUNTRY: "ملک",
  STORE_COMPANY: "کمپنی",
  STORE_SERVICE_NO: "سروس نمبر",
  STORE_EMAIL: "ای میل",
  STORE_URL: "یو آر ایل",
  STORE_SC_COUNT: "ایس سی گنتی",
  STORE_STATUS: "اسٹیٹس",
  STORE_STORE_NAME: "اسٹور کا نام",
  STORE_COMPANY_NAME: "کمپنی کا نام",
  STORE_LICENSE_NUMBER: "لائسنس نمبر",
  STORE_CUSTOMER_SERVICE_NO: "کسٹمر سروس نمبر",
  STORE_PHONE_NO: "فون نمبر",
  STORE_EMAIL: "ای میل",
  STORE_URL: "یو آر ایل",
  STORE_COUNTRY: "ملک",
  STORE_REGION_CITY: "علاقہ/شہر",
  STORE_AREA_NEIGHBOURHOOD: "علاقہ/محلہ",
  STORE_ZIP: "زپ",
  STORE_AREA: "علاقہ",
  STORE_STREET_ADDRESS: "گلی کا پتہ",
  STORE_LATITUDE: "عرض بلد",
  STORE_LONGITUDE: "طول بلد",
  STORE_FORM_MAP: "فارم نقشہ",
  STORE_START_DATE: "شروع ہونے کی تاریخ",
  STORE_END_DATE: "ختم ہونے کی تاریخ",
  STORE_CLEAR_FILTER: "فلٹر صاف کریں",
  STORE_FILTER: "فلٹر",
  STORE_LATITUDE_AND_LONGITUDE: "عرض بلد اور طول بلد",
  STORE_GET_ADDRESS: "پتہ حاصل کریں",
  STORE_FORM_MAP: "فارم نقشہ",
  STORE_UPDATE_STORE: "اسٹور اپ ڈیٹ کریں",
};
