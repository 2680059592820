export const DriverStatusesEnglish = {
  SETTING_DRIVER_STATUSES_TEXT: "Driver Statuses",
  SETTING_DRIVER_STATUSES_TEXT_S: "Driver Statuse(s)",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "Add Driver Status",
  SETTING_DRIVER_STATUSES_CHOOSE_STATUS: "Choose Status",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "Status Name",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "Status Name Ar",
  SETTING_DRIVER_STATUSES_ACTION: "Action",
};
export const DriverStatusesArabic = {
  SETTING_DRIVER_STATUSES_TEXT: "Driver Statuses",
  SETTING_DRIVER_STATUSES_TEXT_S: "Driver Statuse(s)",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "إضافة حالة السائق",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "اسم الحالة",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "اسم الحالة بالعربية",
  SETTING_DRIVER_STATUSES_ACTION: "إجراء",
  SETTING_DRIVER_STATUSES_CHOOSE_STATUS: "اختر الحالة",
};
export const DriverStatusesChines = {
  SETTING_DRIVER_STATUSES_TEXT: "添加驾驶员状态",
  SETTING_DRIVER_STATUSES_TEXT_S: "添加驾驶员状态",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "状态名称",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "状态名称",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "状态名称 Ar",
  SETTING_DRIVER_STATUSES_ACTION: "操作",
  SETTING_DRIVER_STATUSES_CHOOSE_STATUS: "选择状态",
};
export const DriverStatusesUrdu = {
  SETTING_DRIVER_STATUSES_TEXT: "ڈرائیور کی حیثیتیں",
  SETTING_DRIVER_STATUSES_TEXT_S: "ڈرائیور کی حیثیتیں",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "ڈرائیور کی حیثیت شامل کریں",
  SETTING_DRIVER_STATUSES_CHOOSE_STATUS: "حیثیت منتخب کریں",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "حیثیت کا نام",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "حیثیت کا نام عربی",
  SETTING_DRIVER_STATUSES_ACTION: "عمل",
};
