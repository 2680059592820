import React, { useRef, useState } from "react";
import { styleSheet } from "../../../../assets/styles/style";
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DataGridHeader from "../../../../.reUseableComponents/DataGridHeader/DataGridHeader";
import {
  ActionButtonCustom,
  ActionButtonEdit,
  amountFormat,
  centerColumn,
  ClipboardIcon,
  CodeBox,
  DescriptionBox,
  DescriptionBoxWithChild,
  rightColumn,
  StyledTooltip,
  useGetWindowHeight,
} from "../../../../utilities/helpers/Helpers";
import DataGridComponent from "../../../../.reUseableComponents/DataGrid/DataGridComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ImportProductModal from "../../../../components/modals/productModals/ImportProductModal";
import ButtonComponent from "../../../../.reUseableComponents/Buttons/ButtonComponent";
import UploadProductModal from "../../../../components/modals/productModals/UploadProductModal";

const UploadProduct = () => {
  const navigate = useNavigate();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { height: windowHeight } = useGetWindowHeight();
  const [selectionModel, setSelectionModel] = useState();
  const [errorsList, setErrorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const resetRowRef = useRef(false);
  const [openImportProductModal, setOpenImportProductModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [openUploadProductModal, setOpenUploadProductModal] = useState(false);
  const [rowData, setrowData] = useState({});

  const getRowClassName = (params) => {
    for (let i = 0; i < errorsList.length; i++) {
      if (
        params.row.index + 1 == errorsList[i].Row &&
        errorsList[i].IsSuccessed === false
      )
        return "active-row"; // CSS class name for active rows
    }
    return "";
  };

  const handleSelectedRow = (oNos) => {
    setSelectionModel(oNos);
  };

  const handleUploadProduct = () => {};
  const handleEditProduct = (data) => {
    setrowData(data);
    setOpenUploadProductModal(true);
  };

  const columns = [
    {
      field: "ProductName",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_TITLE}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Avatar
                src={params.row.featureImage}
                sx={{ width: 30, height: 30, fontSize: "13px" }}
              >
                {params.row?.ProductName}
              </Avatar>
              <Box sx={{ display: "flex" }}>
                <CodeBox title={params.row.title} />
                <DescriptionBox description={params.row.description} />
              </Box>
            </Stack>
          </Box>
        );
      },
    },
    {
      field: "SKU",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_SKU}
        </Box>
      ),
      // ...centerColumn,
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <CodeBox title={row?.sku} />
            {<ClipboardIcon text={row.sku} />}
          </>
        );
      },
    },
    {
      field: "QuantityAvailable",
      ...centerColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_QTY_AVAILABLE}
        </Box>
      ),
      width: 160,
      renderCell: ({ row }) => {
        return (
          <>
            <Box sx={{ fontWeight: "bold" }} mr={1}>
              {row.QuantityAvailable}{" "}
            </Box>
            {`in ${row.VarientCount} varient(s)`}
            <DescriptionBoxWithChild>
              <TableContainer>
                <Table sx={{ minWidth: 275 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          width: "110px",
                        }}
                        align="left"
                      >
                        SKU
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          width: "110px",
                        }}
                        align="left"
                      >
                        Station
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                        align="right"
                      >
                        Price
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                        align="center"
                      >
                        Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.ProductStocks?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ height: "10px" }}
                      >
                        <TableCell
                          sx={{
                            padding: "7px",
                            fontSize: "11px",
                            width: "110px",
                          }}
                          align="left"
                        >
                          {/* <CodeBox title={row?.SKU} /> */}
                          <Box>
                            <Stack direction={"column"}>
                              <Box>{row?.SKU}</Box>
                              <Box>{row?.VarientOption}</Box>
                            </Stack>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "7px",
                            fontSize: "11px",
                            width: "90px",
                          }}
                          align="left"
                        >
                          {row?.StationName}
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "7px",
                            width: "50px",
                            fontSize: "11px",
                          }}
                          align="right"
                        >
                          {amountFormat(row?.Price)}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "7px",
                            width: "50px",
                            fontSize: "11px",
                          }}
                          align="center"
                        >
                          <CodeBox title={row?.quantityAvailable || "0"} />
                        </TableCell>
                      </TableRow>
                    )).slice(0, 5)}
                  </TableBody>
                </Table>
              </TableContainer>
              {row?.ProductStocks?.length > 5 && (
                <ButtonComponent
                  title="Show More"
                  sx={{ display: "flex", margin: "5px auto" }}
                  onClick={() => {
                    navigate("/inventory");
                  }}
                />
              )}
            </DescriptionBoxWithChild>
          </>
        );
      },
    },
    {
      field: "Weight",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.PRODUCTS_WEIGHT}
        </Box>
      ),
      minWidth: 60,
      flex: 1,
      ...centerColumn,
      renderCell: (params) => {
        return <Box>{params.row.weight}</Box>;
      },
    },
    {
      field: "PurchasePrice",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_PURCHASE_PRICE}
        </Box>
      ),
      minWidth: 100,
      flex: 1,
      ...rightColumn,
      renderCell: ({ row }) => {
        return <Box>{amountFormat(row.purchasePrice)} </Box>;
      },
    },
    {
      field: "Sale Price",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_SALE_PRICE}
        </Box>
      ),
      minWidth: 80,
      flex: 1,
      ...rightColumn,
      renderCell: ({ row }) => {
        return <Box>{amountFormat(row.price)} </Box>;
      },
    },
    {
      ...centerColumn,
      field: "Action",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCTS_ACTION}
        </Box>
      ),
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        // const index = errorsList.findIndex((error) => {
        //   return error.Row == params.row.index && error.IsSuccessed == false;
        // });
        return (
          <Box>
            <ActionButtonEdit
              onClick={(e) => {
                handleEditProduct(params.row);
              }}
            />
            {/* {index != -1 && (
              <StyledTooltip title={errorsList[index]?.Msg}></StyledTooltip>
            )} */}
          </Box>
        );
      },
    },
  ];

  const calculatedHeight = windowHeight - 129;

  return (
    <Box sx={styleSheet.pageRoot}>
      <Box sx={{ padding: "10px" }}>
        <DataGridHeader tabs={false}>
          <Box className={"flex_center"} gap={1}>
            {selectionModel?.length > 0 && (
              <ActionButtonCustom
                onClick={handleUploadProduct}
                label={"Upload Product"}
                loading={isLoading}
                width="120px"
              />
            )}
            <ActionButtonCustom
              onClick={(event) => {
                setOpenImportProductModal(true);
              }}
              label={"Import"}
            />
          </Box>
        </DataGridHeader>
        <DataGridComponent
          columns={columns}
          rows={productData || []}
          getRowId={(rows) => rows.rowNum}
          resetRowRef={resetRowRef}
          getRowHeight={() => "auto"}
          checkboxSelection={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(oNo) => handleSelectedRow(oNo)}
          getRowClassName={getRowClassName}
          height={calculatedHeight}
        />
      </Box>
      {openImportProductModal && (
        <ImportProductModal
          open={openImportProductModal}
          onClose={() => setOpenImportProductModal(false)}
          setProductData={setProductData}
          setErrorsList={setErrorsList}
        />
      )}
      {openUploadProductModal && (
        <UploadProductModal
          open={openUploadProductModal}
          onClose={() => setOpenUploadProductModal(false)}
          rowData={rowData}
        />
      )}
    </Box>
  );
};

export default UploadProduct;
