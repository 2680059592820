import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import { Route, Routes } from "react-router-dom";
import { styleSheet } from "../../../assets/styles/style";
import ReturnOrderList from "./list";
import { fetchMethod } from "../../../utilities/helpers/Helpers";
import { GetAllOrderReturn } from "../../../api/AxiosInterceptors";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import UtilityClass from "../../../utilities/UtilityClass";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";

const EnumOrderTabFilter = Object.freeze({
  NEW: "/return-order",
  Approved: "/return-order/approved",
  Received: "/return-order/received",
  Rejected: "/return-order/rejected",
  ReFund: "/return-order/refund",
});
const ReturnOrderPage = () => {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isfilterClear, setIsfilterClear] = useState(false);
  const [allOrderLoading, setAllOrderLoading] = useState(true);
  const [allOrderReturnData, setAllOrderReturnData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
    loadingConfirmation,
    setLoadingConfirmation,
  } = useDateRangeHook();

  let defaultValues = {
    startDate: null,
    endDate: null,
  };

  const resetFilter = () => {
    setStartDate(defaultValues.startDate);
    setEndDate(defaultValues.endDate);
  };

  const handleFilterClear = async () => {
    setIsfilterClear(true);
  };

  const handleTabChange = async (event, filterValue) => {
    setIsFilterOpen(false);
    if (filterValue === EnumOrderTabFilter.NEW) {
    } else if (filterValue === EnumOrderTabFilter.paid) {
    }
    if (filterValue !== EnumOrderTabFilter.NEW) {
      setIsShowFilter(false);
    }
  };

  const getAllOrderReturn = async () => {
    setIsLoading(true);
    try {
      const { response } = await fetchMethod(GetAllOrderReturn, setIsLoading);
      if (response?.result?.list) {
        setAllOrderReturnData(response.result.list);
      } else {
        console.log("No data found");
      }
    } catch (error) {
      console.error("Error fetching order returns:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllOrderReturn();
  }, []);

  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        <DataGridTabs
          handleTabChange={handleTabChange}
          tabData={[
            {
              label: LanguageReducer?.languageType?.ORDER_RETURN_ORDER_NEW,
              route: "/return-order",
            },
            {
              label: LanguageReducer?.languageType?.ORDER_RETURN_ORDER_APPROVED,
              route: "/return-order/approved",
            },
            {
              label: LanguageReducer?.languageType?.ORDER_RETURN_ORDER_RECEIVED,
              route: "/return-order/received",
            },
            {
              label: LanguageReducer?.languageType?.ORDER_RETURN_ORDER_REJECTED,
              route: "/return-order/rejected",
            },
            {
              label: LanguageReducer?.languageType?.ORDER_RETURN_ORDER_REFUND,
              route: "/return-order/refund",
            },
          ]}
          handleFilterBtnOnClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
        />
        {isFilterOpen ? (
          <Table
            sx={{ ...styleSheet.generalFilterArea }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <Grid container spacing={1} sx={{ p: "15px" }}>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <InputLabel
                      sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                    >
                      {LanguageReducer?.languageType?.ORDER_START_DATE}
                    </InputLabel>

                    <CustomReactDatePickerInputFilter
                      value={startDate}
                      onClick={(date) => setStartDate(date)}
                      size="small"
                      isClearable
                      maxDate={UtilityClass.todayDate()}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <InputLabel
                      sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                    >
                      {LanguageReducer?.languageType?.ORDER_END_DATE}
                    </InputLabel>
                    <CustomReactDatePickerInputFilter
                      value={endDate}
                      onClick={(date) => setEndDate(date)}
                      size="small"
                      minDate={startDate}
                      disabled={!startDate}
                      isClearable
                      maxDate={UtilityClass.todayDate()}
                    />
                  </Grid>

                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Stack
                      alignItems="flex-end"
                      direction="row"
                      spacing={1}
                      sx={{
                        ...styleSheet.filterButtonMargin,
                        height: "100%",
                      }}
                    >
                      <Button
                        sx={{
                          ...styleSheet.filterIcon,
                          minWidth: "80px",
                        }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          handleFilterClear();
                        }}
                      >
                        {LanguageReducer?.languageType?.ORDER_CLEAR_FILTER}
                      </Button>
                      <Button
                        sx={{
                          ...styleSheet.filterIcon,
                          minWidth: "80px",
                        }}
                        variant="contained"
                      >
                        {LanguageReducer?.languageType?.ORDERS_FILTER}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
        <Routes>
          <Route path="/" />
          <Route path="/approved" />
          <Route path="/received" />
          <Route path="/rejected" />
          <Route path="/refund" />
        </Routes>
        <ReturnOrderList
          getOrdersRef={getOrdersRef}
          resetRowRef={resetRowRef}
          isLoading={isLoading}
          getAllOrderReturn={getAllOrderReturn}
          allOrderReturnData={allOrderReturnData}
          isFilterOpen={isFilterOpen}
        />
      </div>
    </Box>
  );
};

export default ReturnOrderPage;
