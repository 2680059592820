import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import { inputTypesEnum } from "../../../.reUseableComponents/Modal/ConfigSettingModal";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import CustomRHFPhoneInput from "../../../.reUseableComponents/TextField/CustomRHFPhoneInput";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  CreateStore,
  GetAddressFromLatAndLong,
  UploadStoreImage,
} from "../../../api/AxiosInterceptors";
import { getAllCountryFunc } from "../../../apiCallingFunction";
import uploadIcon from "../../../assets/images/uploadIcon.png";
import { styleSheet } from "../../../assets/styles/style";
import UtilityClass from "../../../utilities/UtilityClass";
import { EnumOptions } from "../../../utilities/enum";
import {
  ActionButtonCustom,
  GridContainer,
  HeightBox,
  fetchMethod,
  fetchMethodResponse,
  getLowerCase,
  placeholders,
  purple,
  useMapAutocompleteSetter,
} from "../../../utilities/helpers/Helpers";
import {
  SchemaTextField,
  addressSchemaEnum,
  useGetAddressSchema,
} from "../../../utilities/helpers/addressSchema";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import FromLinkLocationModal from "../orderModals/FromLinkLocationModal";
import GoogleMapWithSearch from "../resuseAbleModals/GoogleMapWithSearch";
import CustomLatLongTextField from "../../../.reUseableComponents/TextField/CustomLatLongTextField ";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AddStoreModal(props) {
  let { open, setOpen, getAllStores } = props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openFromLinkLocationModal, setOpenFromLinkLocationModal] =
    useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [file, setFile] = useState();
  const [imageURL, setImageURL] = useState();
  const [values, setValues] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [allRegions, setAllRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [autocomplete, setAutocomplete] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
    unregister,
  } = useForm();

  const {
    showMoreInfoBtn,
    selectedAddressSchema,
    addressSchemaSelectData,
    addressSchemaInputData,
    handleSetSchema,
    handleAddRemoveMoreInfoFields,
    handleChangeInputAddressSchema,
    handleChangeSelectAddressSchemaAndGetOptions,
    handleSetSchemaValueForUpdate,
  } = useGetAddressSchema(setValue, true);

  const schemaFieldsLength = [
    ...addressSchemaSelectData,
    ...addressSchemaInputData,
  ].length;

  useWatch({
    name: "country",
    control,
  });
  useWatch({
    name: "region",
    control,
  });
  useWatch({
    name: "city",
    control,
  });
  useWatch({
    name: "lat&long",
    control,
  });
  const handleClose = () => {
    reset();
    setFile();
    setOpen(false);
  };

  let uploadStoreImage = (e) => {
    console.log("e", e.target.files[0]);
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("File", e.target.files[0]);
    UploadStoreImage(formData)
      .then((res) => {
        // console.log("res", res);
        setImageURL(res.data.result.url);
        // successNotification(res.data.result.message);
      })
      .catch((e) => console.log("e", e));
  };
  let getAllCountry = async () => {
    let data = await getAllCountryFunc();
    if (data?.length > 0) setAllCountries(data);
  };
  useEffect(() => {
    getAllCountry();
  }, []);

  const createStore = async (data) => {
    const body = {
      storeName: data.storeName,
      storeCompany: data.companyName,
      customerServiceNo: UtilityClass.getFormatedNumber(data.customerServiceNo),
      phone: UtilityClass.getFormatedNumber(data.phone),
      email: data.email,
      urls: data.Url,
      licenseNo: data.licenseNo,
      storeImage: imageURL,
      storeAddress: {
        countryId: selectedAddressSchema.country,
        cityId: selectedAddressSchema.city,
        areaId: selectedAddressSchema.area,
        streetAddress: selectedAddressSchema.streetAddress,
        streetAddress2: selectedAddressSchema.streetAddress2,
        houseNo: selectedAddressSchema.houseNo,
        buildingName: selectedAddressSchema.buildingName,
        landmark: selectedAddressSchema.landmark,
        provinceId: selectedAddressSchema.province,
        pinCodeId: selectedAddressSchema.pinCode,
        stateId: selectedAddressSchema.state,
        zip: data.zip,
        addressTypeId: 0,
        latitude: getValues("lat&long").split(",")[0] || null,
        longitude: getValues("lat&long").split(",")[1] || null,
      },
    };
    setIsLoading(true);
    CreateStore(body)
      .then((res) => {
        console.log("res:", res);
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
        } else {
          successNotification(
            LanguageReducer?.languageType?.STORE_CREATED_SUCCESSFULLY_TOAST
          );
          setImageURL();
          getAllStores();
          handleClose();
        }
      })
      .catch((e) => {
        errorNotification(
          LanguageReducer?.languageType?.UNABLE_TO_CREATE_STORE_TOAST
        );
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };
  const handleFocus = (event) => event.target.select();
  useMapAutocompleteSetter(
    autocomplete,
    allCountries,
    allRegions,
    allCities,
    setValue
  );

  const splitLatAndLong = async () => {
    const lat = getValues("lat&long").split(",")[0],
      long = getValues("lat&long").split(",")[1];
    setValue("latitude", lat);
    setValue("longitude", long);

    const body = {
      LATITUDE: lat,
      LONGITUDE: long,
    };
    const { response } = await fetchMethod(() =>
      GetAddressFromLatAndLong(body)
    );
    fetchMethodResponse(response, "Address Updated", () =>
      handleSetSchemaValueForUpdate(response.result, setValue)
    );
  };

  return (
    <Box borderRadius={"10px"}>
      <ModalComponent
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        title={""}
        actionBtn={
          <ModalButtonComponent
            title={LanguageReducer?.languageType?.STORE_ADD}
            loading={isLoading}
            bg={purple}
            type="submit"
            onClick={handleSubmit(createStore)}
          />
        }
        component={"form"}
      >
        <Box sx={styleSheet.uploadStoreIconArea}>
          <Button
            sx={{ mt: "8px", outlineColor: "white", mb: "7px" }}
            component="label"
          >
            <img
              style={{
                borderRadius: "20px",
                width: "100px",
                height: "100px",
              }}
              src={file ? file && URL.createObjectURL(file) : uploadIcon}
              alt="uploadIcon"
            />
            <input
              onChange={uploadStoreImage}
              hidden
              accept=".jpg,.png,.jpeg"
              multiple
              type="file"
            />
          </Button>
        </Box>
        {/* <Box>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.STORE_CODE_TEXT}
              </InputLabel>
              <TextField
                placeholder="SH 001"
                size="small"
                id="storeCode"
                name="storeCode"
                fullWidth
                variant="outlined"
                {...register("storeCode", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message:
                      LanguageReducer?.languageType
                        ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                  },
                })}
                error={Boolean(errors.storeCode)} // set error prop
                helperText={errors.storeCode?.message}
              />
            </Box> */}
        <Grid container spacing={2} sx={{ mt: "5px" }}>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_STORE_NAME}
            </InputLabel>
            <TextField
              placeholder={placeholders.store_name}
              size="small"
              id="storeName"
              name="storeName"
              fullWidth
              variant="outlined"
              {...register("storeName", {
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                pattern: {
                  value: /^(?!\s*$).+/,
                  message:
                    LanguageReducer?.languageType
                      ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                },
              })}
              error={Boolean(errors.storeName)} // set error prop
              helperText={errors.storeName?.message}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_COMPANY_NAME}
            </InputLabel>
            <TextField
              placeholder={placeholders.company_name}
              size="small"
              id="companyName"
              name="companyName"
              fullWidth
              variant="outlined"
              {...register("companyName", {
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                pattern: {
                  value: /^(?!\s*$).+/,
                  message:
                    LanguageReducer?.languageType
                      ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                },
              })}
              error={Boolean(errors.companyName)} // set error prop
              helperText={errors.companyName?.message}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_LICENSE_NUMBER}
            </InputLabel>
            <TextField
              placeholder={placeholders.license_num}
              size="small"
              id="licenseNo"
              name="licenseNo"
              fullWidth
              variant="outlined"
              {...register("licenseNo", {
                // required: {
                //   value: false,
                //   message:
                //     LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                // },
                pattern: {
                  value: /^(?!\s*$).+/,
                  message:
                    LanguageReducer?.languageType
                      ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                },
              })}
              error={Boolean(errors.licenseNo)} // set error prop
              helperText={errors.licenseNo?.message}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_CUSTOMER_SERVICE_NO}
            </InputLabel>
            <CustomRHFPhoneInput
              error={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
              name="customerServiceNo"
              selectedCountry={getValues("country")?.mapCountryCode}
              control={control}
              required
              inputAdornment={
                <Box sx={{ position: "absolute", right: 27, top: 18 }}>
                  <Tooltip title="This Number will reflect on Airway Bill">
                    <InputAdornment position="end">
                      <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "black", cursor: "pointer" }}
                      />
                    </InputAdornment>
                  </Tooltip>
                </Box>
              }
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <InputLabel sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_PHONE_NO}
            </InputLabel>
            <CustomRHFPhoneInput
              error={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
              name="phone"
              selectedCountry={getValues("country")?.mapCountryCode}
              control={control}
              isContact={true}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_EMAIL}
            </InputLabel>
            <TextField
              placeholder={placeholders.email}
              size="small"
              id="email"
              name="email"
              fullWidth
              variant="outlined"
              {...register("email", {
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // set pattern to match email format
                  message: LanguageReducer?.languageType?.INVALID_EMAIL_TOAST,
                },
              })}
              error={Boolean(errors.email)} // set error prop
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_URL}
            </InputLabel>
            <TextField
              placeholder={placeholders.url}
              size="small"
              id="Url"
              name="Url"
              fullWidth
              variant="outlined"
              {...register("Url", {
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                pattern: {
                  value: /^(?!\s*$).+/,
                  message:
                    LanguageReducer?.languageType
                      ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                },
              })}
              error={Boolean(errors.Url)} // set error prop
              helperText={errors.Url?.message}
            />
          </Grid>

          <Grid item md={schemaFieldsLength === 0 ? 12 : 4} sm={6} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.STORE_COUNTRY}
            </InputLabel>
            <SelectComponent
              name="country"
              control={control}
              options={allCountries}
              isRHF={true}
              required={true}
              optionLabel={EnumOptions.COUNTRY.LABEL}
              optionValue={EnumOptions.COUNTRY.VALUE}
              {...register("country", {
                required: {
                  value: true,
                },
              })}
              value={getValues("country")}
              onChange={(event, newValue) => {
                const resolvedId = newValue ? newValue : null;
                handleSetSchema("country", resolvedId, setValue, unregister);
              }}
              errors={errors}
            />
          </Grid>
          {[...addressSchemaSelectData, ...addressSchemaInputData].map(
            (input, index, arr) => (
              <Grid item md={4} sm={6} xs={12}>
                <SchemaTextField
                  loading={input.loading}
                  disabled={input.disabled}
                  isRHF={true}
                  type={input.type}
                  name={input.key}
                  required={input.required}
                  optionLabel={addressSchemaEnum[input.key]?.LABEL}
                  optionValue={addressSchemaEnum[input.key]?.VALUE}
                  register={register}
                  options={input.options}
                  errors={errors}
                  label={input.label}
                  value={getValues(input.key)}
                  showMoreInfoBtn={index + 1 === arr.length}
                  btnFlag={showMoreInfoBtn}
                  handleClickShowMoreInfoBtn={(val) =>
                    handleAddRemoveMoreInfoFields(val, setValue)
                  }
                  onChange={
                    getLowerCase(input.type) === inputTypesEnum.SELECT
                      ? (name, value) => {
                          handleChangeSelectAddressSchemaAndGetOptions(
                            input.key,
                            index,
                            value,
                            setValue,
                            input.key
                          );
                        }
                      : (e) => {
                          handleChangeInputAddressSchema(
                            input.key,
                            e.target.value,
                            setValue
                          );
                        }
                  }
                />
              </Grid>
            )
          )}
          <GridContainer>
            <Grid item md={6} sm={6} xs={12}>
              <InputLabel sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.STORE_LATITUDE_AND_LONGITUDE}
              </InputLabel>
              <CustomLatLongTextField
                name="lat&long"
                required={false}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              alignSelf="end"
              display="flex"
              gap={2}
            >
              <ActionButtonCustom
                onClick={splitLatAndLong}
                startIcon={<FmdGoodOutlinedIcon />}
                disabled={!getValues("lat&long")?.includes(",")}
                label={LanguageReducer?.languageType?.STORE_GET_ADDRESS}
                height={styleSheet.fromMapButton}
              />
              <ActionButtonCustom
                onClick={() => setOpenLocationModal(true)}
                startIcon={<FmdGoodOutlinedIcon />}
                label={LanguageReducer?.languageType?.STORE_FORM_MAP}
                height={styleSheet.fromMapButton}
              />
            </Grid>
          </GridContainer>
          {/* <Grid item md={3} sm={3} xs={12}>
                <Button
                  fullWidth
                  onClick={() => setOpenFromLinkLocationModal(true)}
                  sx={{
                    ...styleSheet.currentLocationButton,
                    marginTop: "15px",
                  }}
                  variant="contained"
                >
                  {" "}
                  <FmdGoodOutlinedIcon />
                  {LanguageReducer?.languageType?.FROM_LINK_TEXT}
                </Button>
              </Grid> */}
        </Grid>
        <HeightBox length={schemaFieldsLength} />
        {/* <ToastContainer /> */}
        <GoogleMapWithSearch
          open={openLocationModal}
          setOpen={setOpenLocationModal}
          setValue={setValue}
          setAutocomplete={setAutocomplete}
          splitLatAndLong={splitLatAndLong}
        />
        <FromLinkLocationModal
          open={openFromLinkLocationModal}
          setOpen={setOpenFromLinkLocationModal}
          setValue={setValue}
        />
      </ModalComponent>
    </Box>
  );
}
export default AddStoreModal;
