import {
  createCarrierReturnArabic,
  createCarrierReturnChines,
  createCarrierReturnEnglish,
  createCarrierReturnUrdu,
} from "./createCarrierReturn/createCarrierReturn";

export const CarrierReturnEnglish = {
  ...createCarrierReturnEnglish,
  CARRIER_RETURN_S_TEXT: "Carrier Return(s)",
  CARRIER_RETURNS_TEXT: "Carrier Returns",
  CARRIER_RETURNS_RETURN_REPORT_NO: "Return Report No.",
  CARRIER_RETURNS_TOTAL_ORDERS: "Total Orders",
  CARRIER_RETURNS_CARRIER_NAME: "Carrier Name",
  CARRIER_RETURNS_ACTION: "Action",
  CARRIER_RETURNS_START_DATE: "Start Date",
  CARRIER_RETURNS_END_DATE: "End Date",
  CARRIER_RETURNS_CLEAR_FILTER: "Clear Filter",
  CARRIER_RETURNS_CARRIER_RETURN_SHIPMENTS: "Carrier Return Shipments",
  CARRIER_RETURNS_ORDER: "Order",
  CARRIER_RETURNS_STORE: "Store",
  CARRIER_RETURNS_PAYMENT: "Payment",
  CARRIER_RETURNS_AMOUNT: "Amount",
  CARRIER_RETURNS_DROP_OF_ADDRESS: "Drop Of Address",
  CARRIER_RETURNS_ORDER_DATE: "Order Date",
  CARRIER_RETURNS_TRACKING_STATUS: "Tracking Status",
  CARRIER_RETURNS_ORDER_TYPE: "Order Type",
  CARRIER_RETURNS_ITEM_COUNT: "Item Count",
  CARRIER_RETURNS_DISMISS: "Dismiss",
};
export const CarrierReturnArabic = {
  ...createCarrierReturnArabic,
  CARRIER_RETURN_S_TEXT: "إرجاع الناقل",
  CARRIER_RETURNS_TEXT: "إرجاع الناقل",
  CARRIER_RETURNS_RETURN_REPORT_NO: "رقم تقرير الإرجاع",
  CARRIER_RETURNS_TOTAL_ORDERS: "إجمالي الطلبيات",
  CARRIER_RETURNS_CARRIER_NAME: "اسم الناقل",
  CARRIER_RETURNS_ACTION: "إجراء",
  CARRIER_RETURNS_START_DATE: "تاريخ البدء",
  CARRIER_RETURNS_END_DATE: "تاريخ الانتهاء",
  CARRIER_RETURNS_CLEAR_FILTER: "مسح الفلتر",
  CARRIER_RETURNS_CARRIER_RETURN_SHIPMENTS: "شحنات الإرجاع من الناقل",
  CARRIER_RETURNS_ORDER: "طلب",
  CARRIER_RETURNS_STORE: "متجر",
  CARRIER_RETURNS_PAYMENT: "الدفع",
  CARRIER_RETURNS_AMOUNT: "المبلغ",
  CARRIER_RETURNS_DROP_OF_ADDRESS: "عنوان التسليم",
  CARRIER_RETURNS_ORDER_DATE: "تاريخ الطلب",
  CARRIER_RETURNS_TRACKING_STATUS: "حالة التتبع",
  CARRIER_RETURNS_ORDER_TYPE: "نوع الطلب",
  CARRIER_RETURNS_ITEM_COUNT: "عدد العناصر",
  CARRIER_RETURNS_DISMISS: "رفض",
};
export const CarrierReturnChinese = {
  ...createCarrierReturnChines,
  CARRIER_RETURN_S_TEXT: "承运人退货",
  CARRIER_RETURNS_TEXT: " 承运人退货 ",
  CARRIER_RETURNS_RETURN_REPORT_NO: "退货报告编号",
  CARRIER_RETURNS_TOTAL_ORDERS: "总订单数",
  CARRIER_RETURNS_CARRIER_NAME: "承运人名称",
  CARRIER_RETURNS_ACTION: "操作",
  CARRIER_RETURNS_START_DATE: "开始日期",
  CARRIER_RETURNS_END_DATE: "结束日期",
  CARRIER_RETURNS_CLEAR_FILTER: "清除筛选",
  CARRIER_RETURNS_CARRIER_RETURN_SHIPMENTS: "承运人退货货件",
  CARRIER_RETURNS_ORDER: "订单",
  CARRIER_RETURNS_STORE: "店铺",
  CARRIER_RETURNS_PAYMENT: "付款",
  CARRIER_RETURNS_AMOUNT: "金额",
  CARRIER_RETURNS_DROP_OF_ADDRESS: "投递地址",
  CARRIER_RETURNS_ORDER_DATE: "订单日期",
  CARRIER_RETURNS_TRACKING_STATUS: "跟踪状态",
  CARRIER_RETURNS_ORDER_TYPE: "订单类型",
  CARRIER_RETURNS_ITEM_COUNT: "商品数量",
  CARRIER_RETURNS_DISMISS: "解除",
};
export const CarrierReturnUrdu = {
  ...createCarrierReturnUrdu,
  CARRIER_RETURN_S_TEXT: "کیریئر ریٹرن(s)",
  CARRIER_RETURNS_TEXT: "کیریئر ریٹرنز",
  CARRIER_RETURNS_RETURN_REPORT_NO: "ریٹرن رپورٹ نمبر",
  CARRIER_RETURNS_TOTAL_ORDERS: "کل آرڈرز",
  CARRIER_RETURNS_CARRIER_NAME: "کیریئر کا نام",
  CARRIER_RETURNS_ACTION: "عمل",
  CARRIER_RETURNS_START_DATE: "آغاز کی تاریخ",
  CARRIER_RETURNS_END_DATE: "اختتامی تاریخ",
  CARRIER_RETURNS_CLEAR_FILTER: "فلٹر صاف کریں",
  CARRIER_RETURNS_CARRIER_RETURN_SHIPMENTS: "کیریئر ریٹرن شپمنٹس",
  CARRIER_RETURNS_ORDER: "آرڈر",
  CARRIER_RETURNS_STORE: "اسٹور",
  CARRIER_RETURNS_PAYMENT: "ادائیگی",
  CARRIER_RETURNS_AMOUNT: "رقم",
  CARRIER_RETURNS_DROP_OF_ADDRESS: "ڈراپ آف ایڈریس",
  CARRIER_RETURNS_ORDER_DATE: "آرڈر کی تاریخ",
  CARRIER_RETURNS_TRACKING_STATUS: "ٹریکنگ کی حیثیت",
  CARRIER_RETURNS_ORDER_TYPE: "آرڈر کی قسم",
  CARRIER_RETURNS_ITEM_COUNT: "اشیاء کی تعداد",
  CARRIER_RETURNS_DISMISS: "منسوخ کریں",
};
