export const carrierSettlementEnglish = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "Carrier Settlement",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "Name",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "Amount",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "Action",
  ACCOUNTS_CARRIER_SETTLEMENT_PAID_SUCCESSFULLY: "Paid successfully",
  ACCOUNTS_CARRIER_SETTLEMENT_UNPAID_SUCCESSFULLY: "Unpaid successfully",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "Payment Ref.",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "Carrier Website",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "Payment Date",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "Payment Status",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "Receive Amount",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_FILES: "Upload Files",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "Upload All Files",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "View All Images",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "No Images",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES_AVAILABLE: "No images available",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE:
    "Are you sure you want to delete this image?",
  ACCOUNTS_CARRIER_SETTLEMENT_DELETE: "Delete",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CARRIER_SETTLEMENT:
    "Are you sure you want to delete carrier settlement",
  ACCOUNTS_CARRIER_SETTLEMENT_RECORD_WILL_BE_DELETED_IMMEDIATELY_CANNOT_UNDO:
    "Record will be deleted immediately. You can't undo this action.",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_SETTLEMENT_DELETED_SUCCESSFULLY:
    "Carrier settlement deleted successfully",
};
export const carrierSettlementArabic = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "تسوية الناقل",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "الاسم",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "المبلغ",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "الإجراء",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "مرجع الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "موقع الناقل",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "تاريخ الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "حالة الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "مبلغ الاستلام",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "تحميل جميع الملفات",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "عرض جميع الصور",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "لا توجد صور",
  ACCOUNTS_CARRIER_SETTLEMENT_PAID_SUCCESSFULLY: "تم الدفع بنجاح",
  ACCOUNTS_CARRIER_SETTLEMENT_UNPAID_SUCCESSFULLY: "تم عدم الدفع بنجاح",
  ACCOUNTS_CARRIER_SETTLEMENT_DELETE: "حذف",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_FILES: "رفع الملفات",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES_AVAILABLE: "لا توجد صور متاحة",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE:
    "هل أنت متأكد أنك تريد حذف هذه الصورة؟",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CARRIER_SETTLEMENT:
    "هل أنت متأكد أنك تريد حذف تسوية الناقل",
  ACCOUNTS_CARRIER_SETTLEMENT_RECORD_WILL_BE_DELETED_IMMEDIATELY_CANNOT_UNDO:
    "سيتم حذف السجل فورًا. لا يمكنك التراجع عن هذا الإجراء.",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_SETTLEMENT_DELETED_SUCCESSFULLY:
    "تم حذف تسوية الناقل بنجاح",
};
export const carrierSettlementChines = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "承运人结算",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "名称",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "金额",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "操作",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "付款参考",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "承运人网站",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "付款日期",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "付款状态",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "收款金额",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "上传所有文件",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "查看所有图片",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "无图片",
  ACCOUNTS_CARRIER_SETTLEMENT_PAID_SUCCESSFULLY: "付款成功",
  ACCOUNTS_CARRIER_SETTLEMENT_UNPAID_SUCCESSFULLY: "未付款成功",
  ACCOUNTS_CARRIER_SETTLEMENT_DELETE: "删除",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_FILES: "上传文件",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES_AVAILABLE: "没有可用的图像",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE:
    "您确定要删除此图像吗？",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CARRIER_SETTLEMENT:
    "您确定要删除承运人结算吗",
  ACCOUNTS_CARRIER_SETTLEMENT_RECORD_WILL_BE_DELETED_IMMEDIATELY_CANNOT_UNDO:
    "记录将立即删除。您无法撤销此操作。",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_SETTLEMENT_DELETED_SUCCESSFULLY:
    "承运人结算已成功删除",
};
export const carrierSettlementUrdu = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "کیریئر سیٹلمنٹ",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "نام",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "رقم",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "ایکشن",
  ACCOUNTS_CARRIER_SETTLEMENT_PAID_SUCCESSFULLY: "کامیابی کے ساتھ ادا کیا گیا",
  ACCOUNTS_CARRIER_SETTLEMENT_UNPAID_SUCCESSFULLY:
    "کامیابی کے ساتھ ادائیگی نہیں کی گئی",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "ادائیگی حوالہ",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "کیریئر ویب سائٹ",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "ادائیگی کی تاریخ",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "ادائیگی کی حیثیت",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "موصول رقم",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_FILES: "فائلیں اپ لوڈ کریں",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "تمام فائلیں اپ لوڈ کریں",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "تمام امیجز دیکھیں",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "کوئی امیجز نہیں",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES_AVAILABLE: "کوئی امیجز دستیاب نہیں",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE:
    "کیا آپ کو یقین ہے کہ آپ اس تصویر کو حذف کرنا چاہتے ہیں؟",
  ACCOUNTS_CARRIER_SETTLEMENT_DELETE: "حذف کریں",
  ACCOUNTS_CARRIER_SETTLEMENT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_CARRIER_SETTLEMENT:
    "کیا آپ کو یقین ہے کہ آپ کیریئر سیٹلمنٹ کو حذف کرنا چاہتے ہیں؟",
  ACCOUNTS_CARRIER_SETTLEMENT_RECORD_WILL_BE_DELETED_IMMEDIATELY_CANNOT_UNDO:
    "ریکارڈ فوراً حذف کر دیا جائے گا۔ آپ اس کارروائی کو واپس نہیں کر سکتے ہیں۔",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_SETTLEMENT_DELETED_SUCCESSFULLY:
    "کیریئر سیٹلمنٹ کامیابی کے ساتھ حذف کر دیا گیا",
};
