export const priceCalculatorEnglish = {
  ORDERS_PRICE_CALCULATOR: "Price Calculator",
  ORDERS_PRICE_CALCULATOR_ALL: "All",
  ORDERS_PRICE_CALCULATOR_Carrier: "Carrier",
  ORDERS_PRICE_CALCULATOR_SERVICE_TYPE: "Service Type",
  ORDERS_PRICE_CALCULATOR_DELIVERY_TIME: "Delivery Time",
  ORDERS_PRICE_CALCULATOR_PICKUP_DROFOFF: "Pickup/Dropoff",
  ORDERS_PRICE_CALCULATOR_DELIVERY_METHOD: "Delivery Method",
  ORDERS_PRICE_CALCULATOR_PRICE: "Price",
  ORDERS_PRICE_CALCULATOR_SELECT: "Select",
  ORDERS_PRICE_CALCULATOR_ACTION: "Action",
};
export const priceCalculatorArabic = {
  ORDERS_PRICE_CALCULATOR: "حاسبة السعر",
  ORDERS_PRICE_CALCULATOR_ALL: "الكل",
  ORDERS_PRICE_CALCULATOR_Carrier: "الناقل",
  ORDERS_PRICE_CALCULATOR_SERVICE_TYPE: "نوع الخدمة",
  ORDERS_PRICE_CALCULATOR_DELIVERY_TIME: "وقت التسليم",
  ORDERS_PRICE_CALCULATOR_PICKUP_DROFOFF: "الاستلام/التسليم",
  ORDERS_PRICE_CALCULATOR_DELIVERY_METHOD: "طريقة التسليم",
  ORDERS_PRICE_CALCULATOR_PRICE: "السعر",
  ORDERS_PRICE_CALCULATOR_SELECT: "اختيار",
  ORDERS_PRICE_CALCULATOR_ACTION: "الإجراء",
};
export const priceCalculatorChinese = {
  ORDERS_PRICE_CALCULATOR: "价格计算器",
  ORDERS_PRICE_CALCULATOR_ALL: "所有",
  ORDERS_PRICE_CALCULATOR_Carrier: "承运人",
  ORDERS_PRICE_CALCULATOR_SERVICE_TYPE: "服务类型",
  ORDERS_PRICE_CALCULATOR_DELIVERY_TIME: "交货时间",
  ORDERS_PRICE_CALCULATOR_PICKUP_DROFOFF: "取件/送件",
  ORDERS_PRICE_CALCULATOR_DELIVERY_METHOD: "交付方式",
  ORDERS_PRICE_CALCULATOR_PRICE: "价格",
  ORDERS_PRICE_CALCULATOR_SELECT: "选择",
  ORDERS_PRICE_CALCULATOR_ACTION: "操作",
};
export const priceCalculatorUrdu = {
  ORDERS_PRICE_CALCULATOR: "قیمت کیلکولیٹر",
  ORDERS_PRICE_CALCULATOR_ALL: "تمام",
  ORDERS_PRICE_CALCULATOR_Carrier: "کیریئر",
  ORDERS_PRICE_CALCULATOR_SERVICE_TYPE: "سروس کی قسم",
  ORDERS_PRICE_CALCULATOR_DELIVERY_TIME: "ڈلیوری کا وقت",
  ORDERS_PRICE_CALCULATOR_PICKUP_DROFOFF: "پک اپ / ڈراپ آف",
  ORDERS_PRICE_CALCULATOR_DELIVERY_METHOD: "ڈلیوری کا طریقہ",
  ORDERS_PRICE_CALCULATOR_PRICE: "قیمت",
  ORDERS_PRICE_CALCULATOR_SELECT: "منتخب کریں",
  ORDERS_PRICE_CALCULATOR_ACTION: "عمل",
};
