export const lowInventoryEnglish = {
  PRODUCT_LOW_INVENTORY_TEXT: "Low Inventory",
  PRODUCT_LOW_INVENTORY_TITLE: "Title",
  PRODUCT_LOW_INVENTORY_SKU: "SKU",
  PRODUCT_LOW_INVENTORY_STORE_NAME: "Store Name",
  PRODUCT_LOW_INVENTORY_PRODUCT_STATION: "Product Station",
  PRODUCT_LOW_INVENTORY_SALE_PRICE: "Sale Price",
  PRODUCT_LOW_INVENTORY_LOW_QTY: "Low Qty",
  PRODUCT_LOW_INVENTORY_AVAILABLE: "Available",
  PRODUCT_LOW_INVENTORY_STATUS: "Status",
  PRODUCT_LOW_INVENTORY_ACTION: "Action",
  PRODUCT_LOW_INVENTORY_START_DATE: "Start Date",
  PRODUCT_LOW_INVENTORY_END_DATE: "End Date",
  PRODUCT_LOW_INVENTORY_SELECT_PLEASE: "Select Please",
  PRODUCT_LOW_INVENTORY_SELECT_STATION: "Select Station",
};
export const lowInventoryArabic = {
  PRODUCT_LOW_INVENTORY_TEXT: "انخفاض المخزون",
  PRODUCT_LOW_INVENTORY_TITLE: "العنوان",
  PRODUCT_LOW_INVENTORY_SKU: "إس كيو يو",
  PRODUCT_LOW_INVENTORY_STORE_NAME: "اسم المتجر",
  PRODUCT_LOW_INVENTORY_PRODUCT_STATION: "محطة المنتجات",
  PRODUCT_LOW_INVENTORY_SALE_PRICE: "سعر البيع",
  PRODUCT_LOW_INVENTORY_LOW_QTY: "الكمية المنخفضة",
  PRODUCT_LOW_INVENTORY_AVAILABLE: "متاح",
  PRODUCT_LOW_INVENTORY_STATUS: "الحالة",
  PRODUCT_LOW_INVENTORY_ACTION: "الإجراء",
  PRODUCT_LOW_INVENTORY_START_DATE: "تاريخ البدء",
  PRODUCT_LOW_INVENTORY_END_DATE: "تاريخ الانتهاء",
  PRODUCT_LOW_INVENTORY_SELECT_PLEASE: "يرجى التحديد",
  PRODUCT_LOW_INVENTORY_SELECT_STATION: "اختر المحطة",
};
export const lowInventoryChines = {
  PRODUCT_LOW_INVENTORY_TEXT: "库存低",
  PRODUCT_LOW_INVENTORY_TITLE: "标题",
  PRODUCT_LOW_INVENTORY_SKU: "SKU(库存单位)",
  PRODUCT_LOW_INVENTORY_STORE_NAME: "店铺名称",
  PRODUCT_LOW_INVENTORY_PRODUCT_STATION: "产品站点",
  PRODUCT_LOW_INVENTORY_SALE_PRICE: "销售价格",
  PRODUCT_LOW_INVENTORY_LOW_QTY: "低库存数量",
  PRODUCT_LOW_INVENTORY_AVAILABLE: "可用的",
  PRODUCT_LOW_INVENTORY_STATUS: "状态",
  PRODUCT_LOW_INVENTORY_ACTION: "操作",
  PRODUCT_LOW_INVENTORY_START_DATE: "开始日期",
  PRODUCT_LOW_INVENTORY_END_DATE: "结束日期",
  PRODUCT_LOW_INVENTORY_SELECT_PLEASE: "请选择",
  PRODUCT_LOW_INVENTORY_SELECT_STATION: "选择站点",
};
export const lowInventoryUrdu = {
  PRODUCT_LOW_INVENTORY_TEXT: "کم انوینٹری",
  PRODUCT_LOW_INVENTORY_TITLE: "عنوان",
  PRODUCT_LOW_INVENTORY_SKU: "SKU",
  PRODUCT_LOW_INVENTORY_STORE_NAME: "اسٹور کا نام",
  PRODUCT_LOW_INVENTORY_PRODUCT_STATION: "پروڈکٹ اسٹیشن",
  PRODUCT_LOW_INVENTORY_SALE_PRICE: "فروخت کی قیمت",
  PRODUCT_LOW_INVENTORY_LOW_QTY: "کم مقدار",
  PRODUCT_LOW_INVENTORY_AVAILABLE: "دستیاب",
  PRODUCT_LOW_INVENTORY_STATUS: "اسٹیٹس",
  PRODUCT_LOW_INVENTORY_ACTION: "عمل",
  PRODUCT_LOW_INVENTORY_START_DATE: "شروع ہونے کی تاریخ",
  PRODUCT_LOW_INVENTORY_END_DATE: "ختم ہونے کی تاریخ",
  PRODUCT_LOW_INVENTORY_SELECT_PLEASE: "براہ کرم منتخب کریں",
  PRODUCT_LOW_INVENTORY_SELECT_STATION: "اسٹیشن منتخب کریں",
};
