export const BillingHistoryEnglish = {
  SETTINGS_BILLING_HISTORY_BILLING: "Billing",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE: "Subscribe",
  SETTINGS_BILLING_HISTORY_CONTACT_US: "Contact Us",
  SETTINGS_BILLING_HISTORY_VIEW_INVOICE: "View Invoice",
  SETTINGS_BILLING_HISTORY_MAKE_PAYMENT: "Make Payment",
  SETTINGS_BILLING_HISTORY_INVOICE_HISTORY: "Invoice history",
  SETTINGS_BILLING_HISTORY_ACTIVE_PLAN: "Active Plan",
  SETTINGS_BILLING_HISTORY_CANCEL_PLAN: "Cancel Plan",
  SETTINGS_BILLING_HISTORY_END_TRIAL_AND_PAY: "End Trial & Pay",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE_PLAN: "Subscribe Plan",
  SETTINGS_BILLING_HISTORY_CHANGE_PLAN: "Change Plan",
  SETTINGS_BILLING_HISTORY_PAYMENT_METHODS: "Payment Methods",
  SETTINGS_BILLING_HISTORY_INVOICE_NO: "Invoice No.",
  SETTINGS_BILLING_HISTORY_TO_NAME: "To Name",
  SETTINGS_BILLING_HISTORY_TO_MOBILE: "To Mobile",
  SETTINGS_BILLING_HISTORY_AMOUNT: "Amount",
  SETTINGS_BILLING_HISTORY_DUE_DATE: "Due Date",
  SETTINGS_BILLING_HISTORY_CREATED_ON: "Created On",
  SETTINGS_BILLING_HISTORY_STATUS: "Status",
  SETTINGS_BILLING_HISTORY_ACTION: "Action",
  SETTINGS_BILLING_HISTORY_ARE_YOU_SURE_TO_CANCEL_YOUR_TRIAL_PERIOD_AND_PAY_NOW:
    "Are you sure to cancel your trial period and pay now?",
  SETTINGS_BILLING_HISTORY_THIS_ACTION_WILL_REMOVE_YOUR_TRIAL_PERIOD_AND_CHARGE_FROM_YOUR_STRIPE_DEFAULT_PAYMENT_METHOD_AND_REFLECT_IN_INVOICE_HISTORY:
    "This action will make effect to remove your trial period and charge from your stripe default payment method and charged invoice will reflect in your invoice history.",
  SETTINGS_BILLING_HISTORY_END_TRIAL: "End Trial",
};
export const BillingHistoryArabic = {
  SETTINGS_BILLING_HISTORY_BILLING: "الفواتير",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE: "اشترك",
  SETTINGS_BILLING_HISTORY_CONTACT_US: "اتصل بنا",
  SETTINGS_BILLING_HISTORY_VIEW_INVOICE: "عرض الفاتورة",
  SETTINGS_BILLING_HISTORY_MAKE_PAYMENT: "إجراء الدفع",
  SETTINGS_BILLING_HISTORY_INVOICE_HISTORY: "سجل الفواتير",
  SETTINGS_BILLING_HISTORY_ACTIVE_PLAN: "الخطة النشطة",
  SETTINGS_BILLING_HISTORY_CANCEL_PLAN: "إلغاء الخطة",
  SETTINGS_BILLING_HISTORY_END_TRIAL_AND_PAY: "إنهاء الفترة التجريبية والدفع",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE_PLAN: "الاشتراك في الخطة",
  SETTINGS_BILLING_HISTORY_CHANGE_PLAN: "تغيير الخطة",
  SETTINGS_BILLING_HISTORY_PAYMENT_METHODS: "طرق الدفع",
  SETTINGS_BILLING_HISTORY_INVOICE_NO: "رقم الفاتورة",
  SETTINGS_BILLING_HISTORY_TO_NAME: "إلى الاسم",
  SETTINGS_BILLING_HISTORY_TO_MOBILE: "إلى الجوال",
  SETTINGS_BILLING_HISTORY_AMOUNT: "المبلغ",
  SETTINGS_BILLING_HISTORY_DUE_DATE: "تاريخ الاستحقاق",
  SETTINGS_BILLING_HISTORY_CREATED_ON: "تاريخ الإنشاء",
  SETTINGS_BILLING_HISTORY_STATUS: "الحالة",
  SETTINGS_BILLING_HISTORY_ACTION: "الإجراء",
  SETTINGS_BILLING_HISTORY_ARE_YOU_SURE_TO_CANCEL_YOUR_TRIAL_PERIOD_AND_PAY_NOW:
    "هل أنت متأكد من أنك تريد إلغاء فترة التجربة والدفع الآن؟",
  SETTINGS_BILLING_HISTORY_THIS_ACTION_WILL_REMOVE_YOUR_TRIAL_PERIOD_AND_CHARGE_FROM_YOUR_STRIPE_DEFAULT_PAYMENT_METHOD_AND_REFLECT_IN_INVOICE_HISTORY:
    "ستؤدي هذه الخطوة إلى إزالة فترة التجربة الخاصة بك وخصم المبلغ من طريقة الدفع الافتراضية في Stripe، وسيتم عكس الفاتورة المدفوعة في سجل الفواتير.",
  SETTINGS_BILLING_HISTORY_END_TRIAL: "إنهاء التجربة",
};
export const BillingHistoryChinese = {
  SETTINGS_BILLING_HISTORY_BILLING: "账单",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE: "订阅",
  SETTINGS_BILLING_HISTORY_CONTACT_US: "联系我们",
  SETTINGS_BILLING_HISTORY_VIEW_INVOICE: "查看发票",
  SETTINGS_BILLING_HISTORY_MAKE_PAYMENT: "付款",
  SETTINGS_BILLING_HISTORY_INVOICE_HISTORY: "发票历史",
  SETTINGS_BILLING_HISTORY_ACTIVE_PLAN: "激活计划",
  SETTINGS_BILLING_HISTORY_CANCEL_PLAN: "取消计划",
  SETTINGS_BILLING_HISTORY_END_TRIAL_AND_PAY: "结束试用并支付",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE_PLAN: "订阅计划",
  SETTINGS_BILLING_HISTORY_CHANGE_PLAN: "更改计划",
  SETTINGS_BILLING_HISTORY_PAYMENT_METHODS: "付款方式",
  SETTINGS_BILLING_HISTORY_INVOICE_NO: "发票号",
  SETTINGS_BILLING_HISTORY_TO_NAME: "收款人名称",
  SETTINGS_BILLING_HISTORY_TO_MOBILE: "收款人手机",
  SETTINGS_BILLING_HISTORY_AMOUNT: "金额",
  SETTINGS_BILLING_HISTORY_DUE_DATE: "到期日期",
  SETTINGS_BILLING_HISTORY_CREATED_ON: "创建日期",
  SETTINGS_BILLING_HISTORY_STATUS: "状态",
  SETTINGS_BILLING_HISTORY_ACTION: "操作",
  SETTINGS_BILLING_HISTORY_ARE_YOU_SURE_TO_CANCEL_YOUR_TRIAL_PERIOD_AND_PAY_NOW:
    "您确定要取消试用期并立即付款吗？",
  SETTINGS_BILLING_HISTORY_THIS_ACTION_WILL_REMOVE_YOUR_TRIAL_PERIOD_AND_CHARGE_FROM_YOUR_STRIPE_DEFAULT_PAYMENT_METHOD_AND_REFLECT_IN_INVOICE_HISTORY:
    "此操作将取消您的试用期并从您的Stripe默认支付方式扣款，收费的发票将反映在您的发票历史记录中。",
  SETTINGS_BILLING_HISTORY_END_TRIAL: "结束试用",
};
export const BillingHistoryUrdu = {
  SETTINGS_BILLING_HISTORY_BILLING: "بلنگ",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE: "سبسکرائب کریں",
  SETTINGS_BILLING_HISTORY_CONTACT_US: "ہم سے رابطہ کریں",
  SETTINGS_BILLING_HISTORY_VIEW_INVOICE: "انوائس دیکھیں",
  SETTINGS_BILLING_HISTORY_MAKE_PAYMENT: "ادائیگی کریں",
  SETTINGS_BILLING_HISTORY_INVOICE_HISTORY: "انوائس کی تاریخ",
  SETTINGS_BILLING_HISTORY_ACTIVE_PLAN: "فعال منصوبہ",
  SETTINGS_BILLING_HISTORY_CANCEL_PLAN: "منصوبہ منسوخ کریں",
  SETTINGS_BILLING_HISTORY_END_TRIAL_AND_PAY:
    "آزمائشی مدت ختم کریں اور ادائیگی کریں",
  SETTINGS_BILLING_HISTORY_SUBSCRIBE_PLAN: "منصوبہ سبسکرائب کریں",
  SETTINGS_BILLING_HISTORY_CHANGE_PLAN: "منصوبہ تبدیل کریں",
  SETTINGS_BILLING_HISTORY_PAYMENT_METHODS: "ادائیگی کے طریقے",
  SETTINGS_BILLING_HISTORY_INVOICE_NO: "انوائس نمبر",
  SETTINGS_BILLING_HISTORY_TO_NAME: "نام وصول کنندہ",
  SETTINGS_BILLING_HISTORY_TO_MOBILE: "موبائل نمبر وصول کنندہ",
  SETTINGS_BILLING_HISTORY_AMOUNT: "رقم",
  SETTINGS_BILLING_HISTORY_DUE_DATE: "مقررہ تاریخ",
  SETTINGS_BILLING_HISTORY_CREATED_ON: "تاریخ تخلیق",
  SETTINGS_BILLING_HISTORY_STATUS: "حیثیت",
  SETTINGS_BILLING_HISTORY_ACTION: "عمل",
  SETTINGS_BILLING_HISTORY_ARE_YOU_SURE_TO_CANCEL_YOUR_TRIAL_PERIOD_AND_PAY_NOW:
    "کیا آپ اپنے آزمائشی دورانیے کو منسوخ کرنے اور ابھی ادائیگی کرنے کے لئے پُرعزم ہیں؟",
  SETTINGS_BILLING_HISTORY_THIS_ACTION_WILL_REMOVE_YOUR_TRIAL_PERIOD_AND_CHARGE_FROM_YOUR_STRIPE_DEFAULT_PAYMENT_METHOD_AND_REFLECT_IN_INVOICE_HISTORY:
    "یہ عمل آپ کے آزمائشی دورانیے کو ختم کر دے گا اور آپ کے اسٹرائپ کے ڈیفالٹ ادائیگی کے طریقے سے چارج کر کے انوائس کی تاریخ میں دکھایا جائے گا۔",
  SETTINGS_BILLING_HISTORY_END_TRIAL: "آزمائشی مدت ختم کریں",
};
