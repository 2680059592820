import {
  paymentLinkArabic,
  paymentLinkChinese,
  paymentLinkEnglish,
  paymentLinkUrdu,
} from "./paymentLink";
import {
  priceCalculatorArabic,
  priceCalculatorChinese,
  priceCalculatorEnglish,
  priceCalculatorUrdu,
} from "./priceCalculator";
import {
  retuenOrderChines,
  returnOrderArabic,
  returnOrderEnglish,
  returnOrderUrdu,
} from "./returnOrder";
import {
  saleChannelOrderArabic,
  saleChannelOrderChines,
  saleChannelOrderEnglish,
  saleChannelOrderUrdu,
} from "./saleChannelOrder/saleChannelOrder";
import {
  walletArabic,
  walletChinese,
  walletEnglish,
  walletUrdu,
} from "./wallet";

export const ordersEnglish = {
  ...saleChannelOrderEnglish,
  ...returnOrderEnglish,
  ...priceCalculatorEnglish,
  ...paymentLinkEnglish,
  ...walletEnglish,
  ORDERS_TEXT: "Orders",
  ORDERS_ORDER_DASHBOARD: "Order Dashboard",
  ORDERS_ALL: "All",
  ORDERS_REGULAR: "Regular",
  ORDERS_FULFILLABLE: "Fulfillable",
  ORDERS_UNFULFILLED: "Unfulfilled",
  ORDERS_UNASSIGNED: "Unassigned",
  ORDERS_UNASSIGN: "Unassign",
  ORDERS_FULFILLED: "Fulfilled",
  ORDERS_UNPAID: "Unpaid",
  ORDERS_PAID: "Paid",
  ORDERS_ORDER_BOX: "Order Box",
  ORDER_ASSIGN_TO_CARRIER: "Assign to carrier",
  ORDER_ASSIGN_IN_HOUSE: "Assign in House",
  ORDER_BATCH_ASSIGN_TO_CARRIER: "Batch Assign to carrier",
  ORDER_UPDATE_STATUS: "Update Status",
  ORDER_BATCH_UPDATE_ORDER_STATUS: "Batch Update Order Status",
  ORDER_CARRIER_STATUS: "Carrier Status",
  ORDERS_CREATE_REGULAR_ORDER: "Create Regular Order",
  ORDERS_UPDATE_REGULAR_ORDER: "Update Regular Order",
  ORDERS_CREATE_FULFILLABLE_ORDER: "Create Fulfillable Order",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "Update Fulfillable Order",
  ORDERS_UPLOAD_ORDER: "Upload Order",
  ORDERS_ORDER_NO_REF_NO: "Order No./ Ref No",
  ORDERS_STORE_INFO: "Store Info.",
  ORDERS_CUSTOMER_INFO: "Customer Info.",
  ORDERS_PAYMENT_STATUS: "Payment Status",
  ORDERS_FULFILLMENT_STATUS: "Fulfillment Status",
  ORDERS_ORDER_TYPE: "Order Type",
  ORDERS_TRACKING_STATUS: "Tracking Status",
  ORDERS_ITEM_COUNT: "Item Count",
  ORDERS_VAT: "VAT",
  ORDERS_DISCOUNT: "Discount",
  ORDERS_AMOUNT: "Amount",
  ORDERS_STORE: "Store",
  ORDER_SALE_CHANNEL: "Sale Channel",
  ORDERS_STATIONS: "Stations",
  ORDERS_REF_NO: "Ref No",
  ORDERS_ORDER_DATE: "Order Date",
  ORDERS_CUSTOMER_NAME: "Customer Name",
  ORDERS_CUSTOMER: "Customer",
  ORDERS_EMAIL: "Email",
  ORDERS_PHONE_NO: "Phone No",
  ORDERS_CONTACT_NUMBER: "Contact Number",
  ORDERS_COUNTRY: "Country",
  ORDERS_REGION_CITY: "Region/City",
  ORDERS_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  ORDERS_APARTMENT_SUITE_ETC: "Apartment/ Suite/ Etc",
  ORDERS_LATITUDE_AND_LONGITUDE: "Latitude And Longitude",
  ORDERS_GET_ADDRESS: "Get Address",
  ORDERS_FORM_MAP: "Form Map",
  ORDERS_ORDER_DETAILS: "Order Details",
  ORDERS_DESCRIPTION: "Description",
  ORDERS_REMARKS: "Remarks",
  ORDERS_NUMBER_OF_PIECES: "Number Of Pieces",
  ORDERS_TOTAL_WEIGHT: "Total Weight",
  ORDERS_DESCRIPTION_OF_PIECE_1: "Description Of Piece",
  ORDERS_PAYMENT: "Payment",
  ORDERS_SUBTOTAL: "Sub total",
  ORDERS_ADD_DISCOUNT: "Add Discount",
  ORDERS_ADD_SHIPPING: "Add Shipping",
  ORDERS_TAX_VAT_5_PERCENT: "Tax VAT 5 %",
  ORDERS_TOTAL: "Total",
  ORDERS_PAYMENT_METHOD: "Payment Method",
  ORDERS_PREPAID: "Prepaid",
  ORDERS_CASH_ON_DELIVERY: "Cash On Delivery",
  ORDERS_CONFIRM: "Confirm",
  ORDERS_CONFIRM_AND_NEW: "Confirm And New",
  ORDERS_CREATE_FULFILLABLE_ORDER: "Create Fulfillable Order",
  ORDERS_NOTE: "Note",
  ORDERS_FILTER: "Filter",
  ORDERS_ACTION: "Action",
  ORDER_IMPORT: "Import",
  ORDER_SAMPLE_REGULAR: "Sample Regular",
  ORDER_SAMPLE_FULLFILLABLE: "Sample Fullfillable",
  ORDER_DOWNLOAD_EMIRATES: "Download Emirates",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "Download Area/Neighbourhood",
  ORDER_PRODUCT: "Product",
  ORDER_ADDRESS: "Address",
  ORDER_START_DATE: "Start Date",
  ORDER_END_DATE: "End Date",
  ORDER_STORE: "Store",
  ORDER_CARRIER: "Carrier",
  ORDER_SELECT_CARRIER: "Select Carrier",
  ORDER_ORDER_TYPE: "Order Type",
  ORDER_FULFILLMENT_STATUS: "Fullfillment Status",
  ORDER_PAYMENT_STATUS: "Payment Status",
  ORDER_PAYMENT_METHOD: "Payment Method",
  ORDER_STATION: "Station",
  ORDER_SALES_PERSON: "Sales Person",
  ORDER_COUNTRY: "Country",
  ORDER_CARRIER_TRACKING_STATUS: "Carrier Tracking Status",
  ORDER_CLEAR_FILTER: "Clear Filter",
  ORDER_ASSIGN: "Carrier Assign",
  ORDER_EXCEL_EXPORT: "Excel Export",
  ORDER_PRINT_LABELS: "Print Labels",
  ORDER_PRINT: "Print",
  ORDER_PLEASE_SELECT_TEMPLATES: " Please Select templates",
  ORDER_FAILED_TO_PRINT_THE_LABEL: " Failed to print the label",
  ORDER_HERE_AND_UNCHECK_ASK_EVERY_TIME: "here and uncheck Ask every time.",
  ORDER_IF_YOU_WANT_TO_USE_THE_DEFAULT_AWB_SETTING_THEN_PLEASE_CHANGE_THE_SETTING:
    " If you want to use the default AWB setting, then please change the setting",
  ORDER_PRINT_CARRIER_LABELS: "Print Carrier Labels",
  ORDER_REFRESH_TRACKING_STATUS: "Refresh Tracking Status",
  ORDER_UNASSIGN_FROM_CARRIER: "Unassign From Carrier",
  ORDER_BATCH_FULLFILLMENT: "Batch Fulfillment",
  ORDER_SELECT_FULFILLMENT_STATUS: "Select FulFillment Status",
  ORDER_DELETE_ORDER: "Delete Order",
  ORDER_DELETE: "Delete",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_UNASSIGN_SELECTED_ORDERS:
    "Are you sure you want to unassign selected orders",
  ORDER_SELECTED_ORDERS_WILL_BE_UNASSIGN_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "Selected orders will be unassign immediately. You can't undo this action.",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ORDERS:
    "Are you sure you want to Delete selected orders",
  ORDER_SELECTED_ORDERS_WILL_BE_DELETE_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "Selected orders will be Delete immediately. You can't undo this action.",
};
export const ordersArabic = {
  ...saleChannelOrderArabic,
  ...returnOrderArabic,
  ...priceCalculatorArabic,
  ...paymentLinkArabic,
  ...walletArabic,
  ORDERS_TEXT: "طلبات",
  ORDERS_ORDER_DASHBOARD: "لوحة تحكم الطلبات",
  ORDERS_FILTER: "تصفية",
  ORDERS_ACTION: "إجراء",
  ORDERS_ALL: "الكل",
  ORDERS_REGULAR: "منتظم",
  ORDERS_FULFILLABLE: "قابل للتنفيذ",
  ORDERS_UNFULFILLED: "غير مكتمل",
  ORDERS_UNASSIGNED: "غير مخصص",
  ORDERS_UNASSIGN: "إلغاء التعيين",
  ORDERS_FULFILLED: "مكتمل",
  ORDERS_UNPAID: "غير مدفوع",
  ORDERS_PAID: "مدفوع",
  ORDERS_ORDER_BOX: "صندوق الطلب",
  ORDER_ASSIGN_TO_CARRIER: "تعيين إلى الناقل",
  ORDER_ASSIGN_IN_HOUSE: "تعيين داخلي",
  ORDER_UPDATE_STATUS: "تحديث الحالة",
  ORDER_SELECT_CARRIER: "اختر الناقل",
  ORDER_BATCH_ASSIGN_TO_CARRIER: "تعيين جماعي إلى الناقل",
  ORDERS_CREATE_REGULAR_ORDER: "إنشاء طلب منتظم",
  ORDERS_CREATE_FULFILLABLE_ORDER: "إنشاء طلب قابل للتنفيذ",
  ORDERS_UPDATE_REGULAR_ORDER: "تحديث الطلب العادي",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "تحديث الطلب القابل للتنفيذ",
  ORDERS_UPLOAD_ORDER: "رفع الطلب",
  ORDERS_ORDER_NO_REF_NO: "رقم الطلب / الرقم المرجعي",
  ORDERS_STORE_INFO: "معلومات المتجر",
  ORDERS_CUSTOMER_INFO: "معلومات العميل",
  ORDERS_PAYMENT_STATUS: "حالة الدفع",
  ORDERS_FULFILLMENT_STATUS: "حالة التنفيذ",
  ORDERS_ORDER_TYPE: "نوع الطلب",
  ORDERS_TRACKING_STATUS: "حالة التتبع",
  ORDERS_ITEM_COUNT: "عدد العناصر",
  ORDERS_VAT: "ضريبة القيمة المضافة (VAT)",
  ORDERS_DISCOUNT: "خصم",
  ORDERS_AMOUNT: "المبلغ",
  ORDERS_STORE: "المتجر",
  ORDER_SALE_CHANNEL: "قناة البيع",
  ORDER_BATCH_UPDATE_ORDER_STATUS: "تحديث حالة الطلبات جماعيًا",
  ORDER_CARRIER_STATUS: "حالة الناقل",
  ORDERS_STATIONS: "المحطات",
  ORDERS_REF_NO: "الرقم المرجعي",
  ORDERS_ORDER_DATE: "تاريخ الطلب",
  ORDERS_CUSTOMER_NAME: "اسم العميل",
  ORDERS_CUSTOMER: "العميل",
  ORDERS_EMAIL: "البريد الإلكتروني",
  ORDERS_PHONE_NO: "رقم الهاتف",
  ORDERS_CONTACT_NUMBER: "رقم الاتصال",
  ORDERS_COUNTRY: "الدولة",
  ORDERS_REGION_CITY: "المنطقة/المدينة",
  ORDERS_AREA_NEIGHBOURHOOD: "المنطقة/الحي",
  ORDERS_APARTMENT_SUITE_ETC: "الشقة/الجناح/إلخ",
  ORDERS_LATITUDE_AND_LONGITUDE: "خطوط العرض والطول",
  ORDERS_GET_ADDRESS: "احصل على العنوان",
  ORDERS_FORM_MAP: "خريطة النموذج",
  ORDERS_ORDER_DETAILS: "تفاصيل الطلب",
  ORDERS_DESCRIPTION: "الوصف",
  ORDERS_REMARKS: "ملاحظات",
  ORDERS_NUMBER_OF_PIECES: "عدد القطع",
  ORDERS_TOTAL_WEIGHT: "الوزن الإجمالي",
  ORDERS_DESCRIPTION_OF_PIECE_1: "وصف القطعة ",
  ORDERS_PAYMENT: "الدفع",
  ORDERS_SUBTOTAL: "الإجمالي الفرعي",
  ORDERS_ADD_DISCOUNT: "إضافة خصم",
  ORDERS_ADD_SHIPPING: "إضافة شحن",
  ORDERS_TAX_VAT_5_PERCENT: "ضريبة القيمة المضافة 5%",
  ORDERS_TOTAL: "المجموع",
  ORDERS_PAYMENT_METHOD: "طريقة الدفع",
  ORDERS_PREPAID: "مدفوع مسبقًا",
  ORDERS_CASH_ON_DELIVERY: "الدفع عند الاستلام",
  ORDERS_CONFIRM: "تأكيد",
  ORDERS_CONFIRM_AND_NEW: "تأكيد وإنشاء جديد",
  ORDERS_CREATE_FULFILLABLE_ORDER: "إنشاء طلب قابل للتنفيذ",
  ORDERS_NOTE: "ملاحظة",
  ORDER_IMPORT: "يستورد",
  ORDER_SAMPLE_REGULAR: "عينة منتظمة",
  ORDER_SAMPLE_FULLFILLABLE: "عينة قابلة للتنفيذ",
  ORDER_DOWNLOAD_EMIRATES: "تنزيل الإمارات",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "تنزيل المنطقة/الحي",
  ORDER_PRODUCT: "منتج",
  ORDER_ADDRESS: "عنوان",
  ORDER_START_DATE: "من التاريخ",
  ORDER_END_DATE: "إلى التاريخ",
  ORDER_STORE: "المتجر",
  ORDER_CARRIER: "الناقل",
  ORDER_ORDER_TYPE: "نوع الطلب",
  ORDER_FULFILLMENT_STATUS: "حالة التنفيذ",
  ORDER_PAYMENT_STATUS: "حالة الدفع",
  ORDER_PAYMENT_METHOD: "طريقة الدفع",
  ORDER_STATION: "المحطة",
  ORDER_SALES_PERSON: "مندوب المبيعات",
  ORDER_COUNTRY: "الدولة",
  ORDER_CARRIER_TRACKING_STATUS: "حالة تتبع الناقل",
  ORDER_CLEAR_FILTER: "مسح الفلتر",
  ORDER_ASSIGN: "تعيين الناقل",
  ORDER_EXCEL_EXPORT: "تصدير إكسل",
  ORDER_PRINT_LABELS: "طباعة الملصقات",
  ORDER_PRINT_CARRIER_LABELS: "طباعة ملصقات الناقل",
  ORDER_REFRESH_TRACKING_STATUS: "تحديث حالة التتبع",
  ORDER_UNASSIGN_FROM_CARRIER: "إلغاء التعيين من الناقل",
  ORDER_BATCH_FULLFILLMENT: "تنفيذ الدفعات",
  ORDER_SELECT_FULFILLMENT_STATUS: "حدد حالة التنفيذ",
  ORDER_DELETE_ORDER: "حذف الطلبات",
  ORDER_DELETE: "حذف",
  ORDER_PRINT: "طباعة",
  ORDER_PLEASE_SELECT_TEMPLATES: "يرجى اختيار القوالب",
  ORDER_FAILED_TO_PRINT_THE_LABEL: "فشل في طباعة الملصق",
  ORDER_HERE_AND_UNCHECK_ASK_EVERY_TIME: "هنا وقم بإلغاء تحديد اسأل في كل مرة.",
  ORDER_IF_YOU_WANT_TO_USE_THE_DEFAULT_AWB_SETTING_THEN_PLEASE_CHANGE_THE_SETTING:
    "إذا كنت ترغب في استخدام إعداد AWB الافتراضي، يرجى تغيير الإعداد",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_UNASSIGN_SELECTED_ORDERS:
    "هل أنت متأكد أنك تريد إلغاء تعيين الطلبات المحددة",
  ORDER_SELECTED_ORDERS_WILL_BE_UNASSIGN_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "سيتم إلغاء تعيين الطلبات المحددة على الفور. لا يمكنك التراجع عن هذا الإجراء",

  ORDER_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ORDERS:
    "هل أنت متأكد أنك تريد حذف الطلبات المحددة",

  ORDER_SELECTED_ORDERS_WILL_BE_DELETE_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "سيتم حذف الطلبات المحددة على الفور. لا يمكنك التراجع عن هذا الإجراء.",
};
export const ordersChinese = {
  ...saleChannelOrderChines,
  ...retuenOrderChines,
  ...priceCalculatorChinese,
  ...paymentLinkChinese,
  ...walletChinese,
  ORDERS_TEXT: "命令",
  ORDERS_FILTER: "筛选",
  ORDERS_ACTION: "操作",
  ORDERS_ALL: "所有",
  ORDERS_REGULAR: "常规",
  ORDERS_FULFILLABLE: "可履行",
  ORDERS_UNFULFILLED: "未履行",
  ORDERS_UNASSIGNED: "未分配",
  ORDERS_UNASSIGN: "取消分配",
  ORDERS_FULFILLED: "已完成",
  ORDERS_UNPAID: "未支付",
  ORDERS_PAID: "已支付",
  ORDERS_ORDER_BOX: "订单框",
  ORDER_ASSIGN_TO_CARRIER: "分配给承运人",
  ORDER_ASSIGN_IN_HOUSE: "内部分配",
  ORDER_UPDATE_STATUS: "更新状态",
  ORDERS_CREATE_REGULAR_ORDER: "创建常规订单",
  ORDERS_CREATE_FULFILLABLE_ORDER: "创建可履行订单",
  ORDERS_UPDATE_REGULAR_ORDER: "更新常规订单",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "更新可履行订单",
  ORDERS_UPLOAD_ORDER: "上传订单",
  ORDERS_ORDER_NO_REF_NO: "订单号/参考号",
  ORDERS_STORE_INFO: "店铺信息",
  ORDERS_CUSTOMER_INFO: "客户信息",
  ORDERS_PAYMENT_STATUS: "付款状态",
  ORDERS_FULFILLMENT_STATUS: "履行状态",
  ORDER_SELECT_CARRIER: "选择承运人",
  ORDER_BATCH_ASSIGN_TO_CARRIER: "批量分配给承运人",
  ORDERS_ORDER_TYPE: "订单类型",
  ORDERS_TRACKING_STATUS: "跟踪状态",
  ORDERS_ITEM_COUNT: "商品数量",
  ORDERS_VAT: "增值税",
  ORDERS_DISCOUNT: "折扣",
  ORDERS_AMOUNT: "金额",
  ORDERS_STORE: "店铺",
  ORDER_SALE_CHANNEL: "销售渠道",
  ORDERS_STATIONS: "站点",
  ORDERS_REF_NO: "参考号",
  ORDERS_ORDER_DATE: "订单日期",
  ORDERS_CUSTOMER_NAME: "客户姓名",
  ORDERS_CUSTOMER: "客户",
  ORDERS_EMAIL: "电子邮件",
  ORDERS_PHONE_NO: "电话号码",
  ORDERS_CONTACT_NUMBER: "联系号码",
  ORDERS_COUNTRY: "国家",
  ORDERS_REGION_CITY: "地区/城市",
  ORDERS_AREA_NEIGHBOURHOOD: "区域/社区",
  ORDERS_APARTMENT_SUITE_ETC: "公寓/套房/等",
  ORDERS_LATITUDE_AND_LONGITUDE: "緯度和經度",
  ORDERS_GET_ADDRESS: "取得地址",
  ORDERS_FORM_MAP: "地图表单",
  ORDERS_ORDER_DETAILS: "订单详情",
  ORDERS_DESCRIPTION: "描述",
  ORDER_BATCH_UPDATE_ORDER_STATUS: "批量更新订单状态",
  ORDER_CARRIER_STATUS: "承运人状态",
  ORDERS_REMARKS: "备注",
  ORDERS_NUMBER_OF_PIECES: "件数",
  ORDERS_TOTAL_WEIGHT: "总重量",
  ORDERS_DESCRIPTION_OF_PIECE_1: "第一件商品描述",
  ORDERS_PAYMENT: "付款",
  ORDERS_SUBTOTAL: "小计",
  ORDERS_ADD_DISCOUNT: "添加折扣",
  ORDERS_ADD_SHIPPING: "添加运费",
  ORDERS_TAX_VAT_5_PERCENT: "税费增值税 5%",
  ORDERS_TOTAL: "总计",
  ORDERS_PAYMENT_METHOD: "付款方式",
  ORDERS_PREPAID: "预付款",
  ORDERS_CASH_ON_DELIVERY: "货到付款",
  ORDERS_CONFIRM: "确认",
  ORDERS_CONFIRM_AND_NEW: "确认并新建",
  ORDERS_CREATE_FULFILLABLE_ORDER: "创建可履行订单",
  ORDERS_ORDER_DASHBOARD: "订单仪表板",
  ORDERS_NOTE: "备注",
  ORDER_IMPORT: "进口",
  ORDER_SAMPLE_REGULAR: "样品常规",
  ORDER_SAMPLE_FULLFILLABLE: "样品可完成",
  ORDER_DOWNLOAD_EMIRATES: "下载阿联酋航空",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "下载区域/街区",
  ORDER_PRODUCT: "产品",
  ORDER_ADDRESS: "地址",
  ORDER_START_DATE: "开始日期",
  ORDER_END_DATE: "结束日期",
  ORDER_STORE: "店铺",
  ORDER_CARRIER: "承运人",
  ORDER_ORDER_TYPE: "订单类型",
  ORDER_FULFILLMENT_STATUS: "履行状态",
  ORDER_PAYMENT_STATUS: "付款状态",
  ORDER_PAYMENT_METHOD: "付款方式",
  ORDER_STATION: "站点",
  ORDER_SALES_PERSON: "销售人员",
  ORDER_COUNTRY: "国家",
  ORDER_CARRIER_TRACKING_STATUS: "承运人跟踪状态",
  ORDER_CLEAR_FILTER: "清除筛选",
  ORDER_ASSIGN: "承运商指定",
  ORDER_EXCEL_EXPORT: "导出Excel",
  ORDER_PRINT_LABELS: "打印标签",
  ORDER_PRINT_CARRIER_LABELS: "打印承运标签",
  ORDER_REFRESH_TRACKING_STATUS: "刷新跟踪状态",
  ORDER_UNASSIGN_FROM_CARRIER: "取消指定承运人",
  ORDER_BATCH_FULLFILLMENT: "批量履行",
  ORDER_SELECT_FULFILLMENT_STATUS: "选择履行状态",
  ORDER_DELETE_ORDER: "删除订单",
  ORDER_DELETE: "删除",
  ORDER_PRINT: "打印",
  ORDER_PLEASE_SELECT_TEMPLATES: "请选择模板",
  ORDER_FAILED_TO_PRINT_THE_LABEL: "打印标签失败",
  ORDER_HERE_AND_UNCHECK_ASK_EVERY_TIME: "点击此处并取消勾选每次询问。",
  ORDER_IF_YOU_WANT_TO_USE_THE_DEFAULT_AWB_SETTING_THEN_PLEASE_CHANGE_THE_SETTING:
    "如果您想使用默认的AWB设置，请更改设置",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_UNASSIGN_SELECTED_ORDERS:
    "您确定要取消分配所选订单吗",
  ORDER_SELECTED_ORDERS_WILL_BE_UNASSIGN_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "所选订单将立即取消分配。此操作无法撤销",

  ORDER_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ORDERS:
    "您确定要删除所选订单吗",

  ORDER_SELECTED_ORDERS_WILL_BE_DELETE_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "所选订单将立即删除。此操作无法撤销。",
};
export const ordersUrdu = {
  ...saleChannelOrderUrdu,
  ...returnOrderUrdu,
  ...priceCalculatorUrdu,
  ...walletUrdu,
  ...paymentLinkUrdu,
  ORDERS_TEXT: "آرڈرز",
  ORDERS_ORDER_DASHBOARD: "آرڈر ڈیش بورڈ",
  ORDERS_ALL: "تمام",
  ORDERS_REGULAR: "باقاعدہ",
  ORDERS_FULFILLABLE: "پوری کی جا سکتی ہیں",
  ORDERS_UNFULFILLED: "نامکمل",
  ORDERS_UNASSIGNED: "غیر تفویض شدہ",
  ORDERS_UNASSIGN: "تفویض نہ کریں",
  ORDERS_FULFILLED: "مکمل",
  ORDERS_UNPAID: "غیر ادا شدہ",
  ORDERS_PAID: "ادا شدہ",
  ORDERS_ORDER_BOX: "آرڈر باکس",
  ORDER_ASSIGN_TO_CARRIER: "کیرئیر کو تفویض کریں",
  ORDER_ASSIGN_IN_HOUSE: "ان ہاؤس تفویض کریں",
  ORDER_BATCH_ASSIGN_TO_CARRIER: "بیچ کیریئر کو تفویض کریں",
  ORDER_UPDATE_STATUS: "اسٹیٹس اپ ڈیٹ کریں",
  ORDER_BATCH_UPDATE_ORDER_STATUS: "بیچ آرڈر اسٹیٹس اپ ڈیٹ کریں",
  ORDER_CARRIER_STATUS: "کیرئیر اسٹیٹس",
  ORDERS_CREATE_REGULAR_ORDER: "ریگولر آرڈر بنائیں",
  ORDERS_UPDATE_REGULAR_ORDER: "ریگولر آرڈر اپ ڈیٹ کریں",
  ORDERS_CREATE_FULFILLABLE_ORDER: "پوری کی جا سکتی آرڈر بنائیں",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "پوری کی جا سکتی آرڈر اپ ڈیٹ کریں",
  ORDERS_UPLOAD_ORDER: "آرڈر اپ لوڈ کریں",
  ORDERS_ORDER_NO_REF_NO: "آرڈر نمبر/ ریف نمبر",
  ORDERS_STORE_INFO: "اسٹور معلومات",
  ORDERS_CUSTOMER_INFO: "کسٹمر معلومات",
  ORDERS_PAYMENT_STATUS: "ادائیگی کی حالت",
  ORDERS_FULFILLMENT_STATUS: "تکمیل کی حالت",
  ORDERS_ORDER_TYPE: "آرڈر کی قسم",
  ORDERS_TRACKING_STATUS: "ٹریکنگ کی حالت",
  ORDERS_ITEM_COUNT: "آئٹمز کی تعداد",
  ORDERS_VAT: "وی اے ٹی",
  ORDERS_DISCOUNT: "ڈسکاؤنٹ",
  ORDERS_AMOUNT: "رقم",
  ORDERS_STORE: "اسٹور",
  ORDER_SALE_CHANNEL: "فروخت چینل",
  ORDERS_STATIONS: "اسٹیشنز",
  ORDERS_REF_NO: "ریف نمبر",
  ORDERS_ORDER_DATE: "آرڈر کی تاریخ",
  ORDERS_CUSTOMER_NAME: "کسٹمر کا نام",
  ORDERS_CUSTOMER: "کسٹمر",
  ORDERS_EMAIL: "ای میل",
  ORDERS_PHONE_NO: "فون نمبر",
  ORDERS_CONTACT_NUMBER: "رابطہ نمبر",
  ORDERS_COUNTRY: "ملک",
  ORDERS_REGION_CITY: "علاقہ/شہر",
  ORDERS_AREA_NEIGHBOURHOOD: "علاقہ/پڑوس",
  ORDERS_APARTMENT_SUITE_ETC: "اپارٹمنٹ/ سویٹ/ وغیرہ",
  ORDERS_LATITUDE_AND_LONGITUDE: "عرض بلد اور طول بلد",
  ORDERS_GET_ADDRESS: "پتہ حاصل کریں",
  ORDERS_FORM_MAP: "نقشہ فارم",
  ORDERS_ORDER_DETAILS: "آرڈر کی تفصیلات",
  ORDERS_DESCRIPTION: "تفصیل",
  ORDERS_REMARKS: "ریمارکس",
  ORDERS_NUMBER_OF_PIECES: "ٹکڑوں کی تعداد",
  ORDERS_TOTAL_WEIGHT: "کل وزن",
  ORDERS_DESCRIPTION_OF_PIECE_1: "ٹکڑے کی تفصیل",
  ORDERS_PAYMENT: "ادائیگی",
  ORDERS_SUBTOTAL: "سب ٹوٹل",
  ORDERS_ADD_DISCOUNT: "ڈسکاؤنٹ شامل کریں",
  ORDERS_ADD_SHIPPING: "شپنگ شامل کریں",
  ORDERS_TAX_VAT_5_PERCENT: "ٹیکس وی اے ٹی 5%",
  ORDERS_TOTAL: "کل",
  ORDERS_PAYMENT_METHOD: "ادائیگی کا طریقہ",
  ORDERS_PREPAID: "پری پیڈ",
  ORDERS_CASH_ON_DELIVERY: "ڈلیوری پر کیش",
  ORDERS_CONFIRM: "تصدیق کریں",
  ORDERS_CONFIRM_AND_NEW: "تصدیق کریں اور نیا بنائیں",
  ORDERS_NOTE: "نوٹ",
  ORDERS_FILTER: "فلٹر",
  ORDERS_ACTION: "عمل",
  ORDER_IMPORT: "امپورٹ",
  ORDER_SAMPLE_REGULAR: "نمونہ باقاعدہ",
  ORDER_SAMPLE_FULLFILLABLE: "نمونہ قابل تکمیل",
  ORDER_DOWNLOAD_EMIRATES: "امارات ڈاؤن لوڈ کریں",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "علاقہ/پڑوس ڈاؤن لوڈ کریں",
  ORDER_PRODUCT: "پروڈکٹ",
  ORDER_ADDRESS: "پتہ",
  ORDER_START_DATE: "شروع ہونے کی تاریخ",
  ORDER_END_DATE: "اختتام کی تاریخ",
  ORDER_STORE: "اسٹور",
  ORDER_CARRIER: "کیرئیر",
  ORDER_SELECT_CARRIER: "کیرئیر منتخب کریں",
  ORDER_ORDER_TYPE: "آرڈر کی قسم",
  ORDER_FULFILLMENT_STATUS: "تکمیل کی حالت",
  ORDER_PAYMENT_STATUS: "ادائیگی کی حالت",
  ORDER_PAYMENT_METHOD: "ادائیگی کا طریقہ",
  ORDER_STATION: "اسٹیشن",
  ORDER_SALES_PERSON: "سیلز پرسن",
  ORDER_COUNTRY: "ملک",
  ORDER_CARRIER_TRACKING_STATUS: "کیرئیر ٹریکنگ اسٹیٹس",
  ORDER_CLEAR_FILTER: "فلٹر صاف کریں",
  ORDER_ASSIGN: "کیرئیر تفویض کریں",
  ORDER_EXCEL_EXPORT: "ایکسیل ایکسپورٹ",
  ORDER_PRINT_LABELS: "لیبل پرنٹ کریں",
  ORDER_PRINT: "پرنٹ",
  ORDER_PLEASE_SELECT_TEMPLATES: "براہ کرم ٹیمپلیٹس منتخب کریں",
  ORDER_FAILED_TO_PRINT_THE_LABEL: "لیبل پرنٹ کرنے میں ناکام رہا",
  ORDER_HERE_AND_UNCHECK_ASK_EVERY_TIME:
    "یہاں اور ہر بار پوچھنے کا نشان ہٹا دیں۔",
  ORDER_IF_YOU_WANT_TO_USE_THE_DEFAULT_AWB_SETTING_THEN_PLEASE_CHANGE_THE_SETTING:
    "اگر آپ ڈیفالٹ AWB سیٹنگ استعمال کرنا چاہتے ہیں، تو براہ کرم سیٹنگ تبدیل کریں",
  ORDER_PRINT_CARRIER_LABELS: "کیرئیر لیبل پرنٹ کریں",
  ORDER_REFRESH_TRACKING_STATUS: "ٹریکنگ اسٹیٹس ریفریش کریں",
  ORDER_UNASSIGN_FROM_CARRIER: "کیرئیر سے تفویض ہٹائیں",
  ORDER_BATCH_FULLFILLMENT: "بیچ فل فلمنٹ",
  ORDER_SELECT_FULFILLMENT_STATUS: "تکمیل کی حالت منتخب کریں",
  ORDER_DELETE_ORDER: "آرڈر حذف کریں",
  ORDER_DELETE: "حذف کریں",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_UNASSIGN_SELECTED_ORDERS:
    "کیا آپ واقعی منتخب کردہ آرڈرز کی تفویض ہٹانا چاہتے ہیں؟",
  ORDER_SELECTED_ORDERS_WILL_BE_UNASSIGN_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "منتخب کردہ آرڈرز کی تفویض فوری طور پر ہٹا دی جائے گی۔ آپ اس عمل کو واپس نہیں لے سکتے۔",
  ORDER_ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ORDERS:
    "کیا آپ واقعی منتخب کردہ آرڈرز حذف کرنا چاہتے ہیں؟",
  ORDER_SELECTED_ORDERS_WILL_BE_DELETE_IMMEDIATELY_YOU_CANT_UNDO_THIS_ACTION:
    "منتخب کردہ آرڈرز فوری طور پر حذف کر دیے جائیں گے۔ آپ اس عمل کو واپس نہیں لے سکتے۔",
};
